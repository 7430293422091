import { 
  GamePhase, 
  GameState, 
  ResourceState, 
  UpgradeState, 
  InvestmentState,
  SaveData,
  ResourceCost
} from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { ProducerType } from '../types/ProducerTypes';
import { upgrades } from './UpgradeManager';
import { initialSectors } from './InvestmentSystem';
import { initialProducerState, getProducerProduction, updateProducerVisibility } from './ProducerManager';
import { saveGame, loadGame } from '../utils/localStorage';

// Game constants
export const GAME_VERSION = '0.1.0';
export const TICK_RATE = 10; // Game ticks per second
export const SAVE_INTERVAL = 10000; // Auto-save every 10 seconds

// Phase progression requirements - rebalanced for better gameplay flow
export const PHASE_REQUIREMENTS = {
  SOLAR_SYSTEM: {
    minerals: 2500,  // Reduced from 3000
    energy: 1200,    // Reduced from 1500
    research: 350    // Reduced from 400
  },
  INTERSTELLAR: {
    hydrogen: 2500,  // Reduced from 3000
    helium: 1000,    // Reduced from 1200
    research: 6000   // Reduced from 8000
  },
  GALACTIC_TRANSCENDENCE: {
    antimatter: 500, // Reduced from 800
    research: 40000  // Reduced from 80000
  }
};

// Initial resource state - slightly increased starting minerals
export const initialResourceState: ResourceState = {
  minerals: 20, // Increased from 10 to give a better start
  energy: 5,    // Added small amount of starting energy
  research: 0,
  reputation: 0,
  hydrogen: 0,
  helium: 0,
  rareMetals: 0,
  antimatter: 0,
  darkMatter: 0,
  cosmicInfluence: 0
};

// Initial investment state
export const initialInvestmentState: InvestmentState = {
  sectors: initialSectors,
  cash: 0,
  portfolioValue: 0,
  marketTrends: {
    bull: 0.7, // Start in a bull market
    sectors: {},
    trendDuration: 30 // Initial trend duration of 30 seconds
  },
  lastUpdate: Date.now()
};

// Initial upgrade state
export const initialUpgradeState: UpgradeState = Object.fromEntries(
  upgrades.map(upgrade => [
    upgrade.id, 
    { 
      ...upgrade, 
      purchased: false, 
      enabled: false,
      visible: upgrade.requiredPhase === GamePhase.ASTEROID_MINING 
    }
  ])
);

// Initial game state
export const initialGameState: GameState = {
  phase: GamePhase.ASTEROID_MINING,
  tick: 0,
  lastSaved: Date.now(),
  resources: initialResourceState,
  upgrades: initialUpgradeState,
  producers: initialProducerState,
  investments: initialInvestmentState,
  eventLog: ['Welcome to Stellar Expansion! Begin your journey by mining minerals.'],
  unlocks: {
    automationUnlocked: false,
    investmentsUnlocked: false,
    solarSystemUnlocked: false,
    interstellarUnlocked: false,
    transcendenceUnlocked: false,
    // New panel unlocks
    researchLabUnlocked: false,
    explorationUnlocked: false,
    colonyManagementUnlocked: false,
    spacefleetUnlocked: false,
    diplomacyUnlocked: false,
    realityManipulationUnlocked: false
  }
};

// Initialize or load game state
export const initializeGame = (): GameState => {
  const savedGame = loadGame();
  
  if (savedGame && savedGame.version === GAME_VERSION) {
    console.log('Loaded saved game');
    // Ensure producers are properly initialized
    const gameState = {
      ...savedGame.gameState,
      producers: savedGame.gameState.producers || initialProducerState
    };
    return gameState;
  }
  
  console.log('Starting new game');
  return initialGameState;
};

// Resource production rates
export const getResourceProduction = (state: GameState): { [key: string]: number } => {
  const production: { [key: string]: number } = {
    [ResourceType.MINERALS]: 0,
    [ResourceType.ENERGY]: 0,
    [ResourceType.RESEARCH]: 0,
    [ResourceType.REPUTATION]: 0,
    [ResourceType.HYDROGEN]: 0,
    [ResourceType.HELIUM]: 0,
    [ResourceType.RARE_METALS]: 0,
    [ResourceType.ANTIMATTER]: 0,
    [ResourceType.DARK_MATTER]: 0,
    [ResourceType.COSMIC_INFLUENCE]: 0
  };

  // Apply production from producers (main source)
  const producerProduction = getProducerProduction(state.producers);
  Object.entries(producerProduction).forEach(([resource, amount]) => {
    production[resource] += amount;
  });

  // Apply producer boosts from upgrades
  const producerBoosts = calculateProducerBoosts(state);
  
  // Apply upgrade effects for resource production
  Object.values(state.upgrades).forEach(upgrade => {
    if (upgrade.purchased && upgrade.enabled && upgrade.effect.resourceProduction) {
      Object.entries(upgrade.effect.resourceProduction).forEach(([resource, amount]) => {
        production[resource] += amount;
      });
    }
  });
  
  // Apply producer boosts to the appropriate resources
  Object.entries(producerBoosts).forEach(([producerType, boost]) => {
    const producer = state.producers[producerType as ProducerType];
    if (producer && producer.count > 0) {
      Object.entries(producer.baseProduction).forEach(([resource, rate]) => {
        if (rate as number > 0) {
          // Apply the boost to positive production only
          production[resource] += (rate as number) * producer.count * boost;
        }
      });
    }
  });

  return production;
};

// Calculate producer boosts from enabled upgrades
const calculateProducerBoosts = (state: GameState): { [key: string]: number } => {
  const boosts: { [key: string]: number } = {};
  
  // Initialize boosts for all producer types to 0
  Object.values(ProducerType).forEach(type => {
    boosts[type] = 0;
  });
  
  // Apply boosts from upgrades
  Object.values(state.upgrades).forEach(upgrade => {
    if (upgrade.purchased && upgrade.enabled && upgrade.effect.producerBoost) {
      Object.entries(upgrade.effect.producerBoost).forEach(([producerType, value]) => {
        boosts[producerType] = (boosts[producerType] || 0) + value;
      });
    }
  });
  
  return boosts;
};

// Update game state for one tick
export const updateGameState = (state: GameState): GameState => {
  const newState = { ...state };
  newState.tick += 1;
  
  // Update resources
  const production = getResourceProduction(state);
  Object.entries(production).forEach(([resource, rate]) => {
    // @ts-ignore - We know these resources exist
    newState.resources[resource] += rate / TICK_RATE;
  });

  // Ensure resources don't go negative (can happen with producers that consume resources)
  Object.keys(newState.resources).forEach(resource => {
    // @ts-ignore
    if (newState.resources[resource] < 0) {
      // @ts-ignore
      newState.resources[resource] = 0;
    }
  });

  // Check for phase transitions
  checkPhaseTransition(newState);
  
  // Update producer visibility based on current phase
  const updatedState = updateProducerVisibility(newState);
  
  // Update investments
  if (state.unlocks.investmentsUnlocked) {
    updateInvestments(updatedState);
  }
  
  // Auto-save game
  if (newState.tick % (SAVE_INTERVAL / (1000 / TICK_RATE)) === 0) {
    saveGame({
      gameState: newState,
      version: GAME_VERSION,
      timestamp: Date.now()
    });
    newState.lastSaved = Date.now();
  }
  
  return updatedState;
};

// Check if the game should transition to a new phase
const checkPhaseTransition = (state: GameState): void => {
  if (state.phase === GamePhase.ASTEROID_MINING && 
      state.resources.minerals >= PHASE_REQUIREMENTS.SOLAR_SYSTEM.minerals && 
      state.resources.energy >= PHASE_REQUIREMENTS.SOLAR_SYSTEM.energy && 
      state.resources.research >= PHASE_REQUIREMENTS.SOLAR_SYSTEM.research) {
    
    state.phase = GamePhase.SOLAR_SYSTEM;
    state.unlocks.solarSystemUnlocked = true;
    state.eventLog.push('Phase 2 unlocked: Solar System Expansion!');
    
    // Make phase 2 upgrades visible
    Object.values(state.upgrades).forEach(upgrade => {
      if (upgrade.requiredPhase === GamePhase.SOLAR_SYSTEM) {
        state.upgrades[upgrade.id].visible = true;
      }
    });
  } 
  else if (state.phase === GamePhase.SOLAR_SYSTEM && 
           state.resources.hydrogen >= PHASE_REQUIREMENTS.INTERSTELLAR.hydrogen && 
           state.resources.helium >= PHASE_REQUIREMENTS.INTERSTELLAR.helium && 
           state.resources.research >= PHASE_REQUIREMENTS.INTERSTELLAR.research) {
    
    state.phase = GamePhase.INTERSTELLAR;
    state.unlocks.interstellarUnlocked = true;
    state.eventLog.push('Phase 3 unlocked: Interstellar Expansion!');
    
    // Make phase 3 upgrades visible
    Object.values(state.upgrades).forEach(upgrade => {
      if (upgrade.requiredPhase === GamePhase.INTERSTELLAR) {
        state.upgrades[upgrade.id].visible = true;
      }
    });
  } 
  else if (state.phase === GamePhase.INTERSTELLAR && 
           state.resources.antimatter >= PHASE_REQUIREMENTS.GALACTIC_TRANSCENDENCE.antimatter && 
           state.resources.research >= PHASE_REQUIREMENTS.GALACTIC_TRANSCENDENCE.research) {
    
    state.phase = GamePhase.GALACTIC_TRANSCENDENCE;
    state.unlocks.transcendenceUnlocked = true;
    state.eventLog.push('Final phase unlocked: Galactic Transcendence!');
    
    // Make phase 4 upgrades visible
    Object.values(state.upgrades).forEach(upgrade => {
      if (upgrade.requiredPhase === GamePhase.GALACTIC_TRANSCENDENCE) {
        state.upgrades[upgrade.id].visible = true;
      }
    });
  }
};

// Update investments
// Update investments
const updateInvestments = (state: GameState): void => {
  const timeElapsed = (Date.now() - state.investments.lastUpdate) / 1000;
  state.investments.lastUpdate = Date.now();
  
  // Update market trends more frequently (every 2 seconds)
  const marketUpdateInterval = 2; // Reduced from 5 seconds to 2 seconds
  if (Math.floor(state.tick / (TICK_RATE * marketUpdateInterval)) !== 
      Math.floor((state.tick - 1) / (TICK_RATE * marketUpdateInterval))) {
    
    // Initialize trend duration if not set
    if (!state.investments.marketTrends.trendDuration) {
      state.investments.marketTrends.trendDuration = 0;
    }
    
    // Decrease trend duration
    state.investments.marketTrends.trendDuration--;
    
    // When trend duration expires, potentially start a new trend
    if (state.investments.marketTrends.trendDuration <= 0) {
      // 60% chance to start a new trend (increased from 40%)
      if (Math.random() < 0.6) {
        const currentBull = state.investments.marketTrends.bull;
        const isBullish = currentBull > 0.5;
        
        // 65% chance to reverse the current trend (increased from 50%)
        const shouldReverse = Math.random() < 0.65;
        
        if (shouldReverse) {
          // Reverse the trend with a more moderate move
          state.investments.marketTrends.bull = isBullish ? 0.3 : 0.7; // Less extreme than before
          // Set new trend duration between 15-45 seconds (reduced maximum)
          state.investments.marketTrends.trendDuration = Math.floor(Math.random() * 30) + 15;
        } else {
          // Continue current trend with a more moderate move
          state.investments.marketTrends.bull = isBullish ? 
            Math.min(0.9, currentBull + 0.05) : // Smaller adjustment
            Math.max(0.1, currentBull - 0.05);  // Smaller adjustment
          // Set new trend duration between 5-20 seconds (shorter than before)
          state.investments.marketTrends.trendDuration = Math.floor(Math.random() * 15) + 5;
        }
      } else {
        // Extend current trend duration by just 5-10 seconds (shorter than before)
        state.investments.marketTrends.trendDuration += Math.floor(Math.random() * 5) + 5;
      }
    }
    
    // Update sector-specific trends more frequently
    Object.keys(state.investments.sectors).forEach(sectorId => {
      if (!state.investments.marketTrends.sectors[sectorId]) {
        state.investments.marketTrends.sectors[sectorId] = 0;
      }
      
      // 25% chance for sector trend change (increased from 15%)
      if (Math.random() < 0.25) {
        const currentTrend = state.investments.marketTrends.sectors[sectorId];
        const newTrend = Math.random() * 0.3 - 0.15; // Random trend between -0.15 and 0.15
        state.investments.marketTrends.sectors[sectorId] = newTrend;
      }
    });
  }
  
  // Update sector prices
  let portfolioValue = 0;
  Object.entries(state.investments.sectors).forEach(([id, sector]) => {
    if (sector.unlocked) {
      // Calculate market trend factor (-1 to 1)
      const marketTrendFactor = (state.investments.marketTrends.bull - 0.5) * 2;
      const sectorTrendFactor = state.investments.marketTrends.sectors[id] || 0;
      
      // Calculate random volatility factor (-0.5 to 0.5) - increase randomness
      const volatilityFactor = (Math.random() - 0.5) * sector.volatility * 1.2;
      
      // Combine all factors with reduced market impact
      const totalFactor = (marketTrendFactor * 0.8) + sectorTrendFactor + volatilityFactor;
      
      // Calculate price change with more moderate growth
      const marketCondition = (state.investments.marketTrends.bull - 0.5) * 2; // -1 to 1
      const adjustedGrowth = sector.growth * (0.5 + marketCondition * 0.3); // Reduced market influence
      const priceChange = sector.price * (
        adjustedGrowth + // Market-dependent base growth
        (totalFactor * 0.008) // Reduced market and volatility effects (from 0.015)
      ) * timeElapsed;
      
      // Update price with minimum bound
      sector.price = Math.max(0.1, sector.price + priceChange);
      
      // Update trend indicator
      if (priceChange > 0) {
        sector.trend = 'up';
      } else if (priceChange < 0) {
        sector.trend = 'down';
      } else {
        sector.trend = 'stable';
      }
      
      // Update price history (keep last 30 values)
      sector.history.push(sector.price);
      if (sector.history.length > 30) {
        sector.history.shift();
      }
      
      // Update portfolio value
      portfolioValue += sector.shares * sector.price;
    }
  });
  
  state.investments.portfolioValue = portfolioValue;
};

// Buy upgrade
export const buyUpgrade = (state: GameState, upgradeId: string): GameState => {
  const upgrade = state.upgrades[upgradeId];
  if (!upgrade || upgrade.purchased || !canAffordUpgrade(state, upgrade.cost)) {
    return state;
  }
  
  let newState = { ...state };
  
  // Deduct resources
  Object.entries(upgrade.cost).forEach(([resource, amount]) => {
    // @ts-ignore
    newState.resources[resource] -= amount;
  });
  
  // Mark as purchased and enabled
  newState.upgrades[upgradeId] = {
    ...upgrade,
    purchased: true,
    enabled: true
  };
  
  // Check for unlocks
  if (upgrade.effect.unlocks) {
    upgrade.effect.unlocks.forEach(unlockId => {
      if (unlockId === 'automation') {
        newState.unlocks.automationUnlocked = true;
        newState.eventLog.push('Automation systems unlocked!');
      } else if (unlockId === 'investments') {
        newState.unlocks.investmentsUnlocked = true;
        newState.eventLog.push('Investment system unlocked!');
        
        // Give initial investment cash - increased for better start
        newState.investments.cash = 1500; // Increased from 1000
      } else if (unlockId === 'researchLab') {
        newState.unlocks.researchLabUnlocked = true;
        newState.eventLog.push('Research Laboratory unlocked!');
      } else if (unlockId === 'exploration') {
        newState.unlocks.explorationUnlocked = true;
        newState.eventLog.push('Exploration Program unlocked!');
      } else if (unlockId === 'colonyManagement') {
        newState.unlocks.colonyManagementUnlocked = true;
        newState.eventLog.push('Colony Management System unlocked!');
      } else if (unlockId === 'spacefleet') {
        newState.unlocks.spacefleetUnlocked = true;
        newState.eventLog.push('Spacefleet Command unlocked!');
      } else if (unlockId === 'diplomacy') {
        newState.unlocks.diplomacyUnlocked = true;
        newState.eventLog.push('Diplomacy Network unlocked!');
      } else if (unlockId === 'realityManipulation') {
        newState.unlocks.realityManipulationUnlocked = true;
        newState.eventLog.push('Reality Manipulation Matrix unlocked!');
      } else if (Object.values(ProducerType).includes(unlockId as ProducerType)) {
        // Unlock the producer
        newState.producers[unlockId as ProducerType].unlocked = true;
        newState.eventLog.push(`Unlocked ${newState.producers[unlockId as ProducerType].name}!`);
      } else if (unlockId === 'researchLab') {
        newState.unlocks.researchLabUnlocked = true;
        newState.eventLog.push('Research Laboratory unlocked!');
        
        // Initialize research areas
        if (!newState.research) {
          newState.research = {
            mineralogy: 0,
            energetics: 0,
            propulsion: 0,
            astrophysics: 0,
            quantumMechanics: 0,
            cosmicManipulation: 0
          };
        }
      } 
      else if (unlockId === 'exploration') {
        newState.unlocks.explorationUnlocked = true;
        newState.eventLog.push('Exploration Program unlocked!');
        
        // Initialize exploration data
        if (!newState.exploration) {
          newState.exploration = {
            activeExpeditions: [],
            completedExpeditions: [],
            discoveredLocations: []
          };
        }
      } 
      else if (unlockId === 'colonyManagement') {
        newState.unlocks.colonyManagementUnlocked = true;
        newState.eventLog.push('Colony Management System unlocked!');
        
        // Initialize colonies data
        if (!newState.colonies) {
          newState.colonies = [];
        }
      } 
      else if (unlockId === 'spacefleet') {
        newState.unlocks.spacefleetUnlocked = true;
        newState.eventLog.push('Spacefleet Command unlocked!');
        
        // Initialize ships and fleets data
        if (!newState.ships) {
          newState.ships = {};
        }
        if (!newState.fleets) {
          newState.fleets = [];
        }
      } 
      else if (unlockId === 'diplomacy') {
        newState.unlocks.diplomacyUnlocked = true;
        newState.eventLog.push('Diplomacy Network unlocked!');
        
        // Initialize diplomacy data
        if (!newState.diplomacy) {
          newState.diplomacy = {
            civilizations: [],
            relations: {},
            tradeAgreements: []
          };
        }
      } 
      else if (unlockId === 'realityManipulation') {
        newState.unlocks.realityManipulationUnlocked = true;
        newState.eventLog.push('Reality Manipulation Matrix unlocked!');
        
        // Initialize reality manipulation data
        if (!newState.realityManipulation) {
          newState.realityManipulation = {
            constants: {},
            stability: 1.0,
            anomalies: []
          };
        }
      } else {
        // Unlock upgrades
        if (newState.upgrades[unlockId]) {
          newState.upgrades[unlockId].visible = true;
        }
      }
    });
  }
  
  // Add to event log
  newState.eventLog.push(`Purchased upgrade: ${upgrade.name}`);
  
  return newState;
};

// Check if player can afford an upgrade
const canAffordUpgrade = (state: GameState, cost: ResourceCost): boolean => {
  return Object.entries(cost).every(([resource, amount]) => {
    // @ts-ignore
    return state.resources[resource] >= amount;
  });
};

// Toggle upgrade
export const toggleUpgrade = (state: GameState, upgradeId: string): GameState => {
  const upgrade = state.upgrades[upgradeId];
  if (!upgrade || !upgrade.purchased) {
    return state;
  }
  
  let newState = { ...state };
  newState.upgrades[upgradeId] = {
    ...upgrade,
    enabled: !upgrade.enabled
  };
  
  return newState;
};

// Modify src/game/GameState.ts - collectResource function
export const collectResource = (state: GameState, resource: ResourceType, amount: number): GameState => {
  let newState = { ...state };
  
  // Resource collection should match what's displayed on the button
  // We're removing the phase multipliers since those aren't shown in the UI
  
  newState.resources[resource] += amount;
  return newState;
};

// Handle investment transactions
export const investmentTransaction = (
  state: GameState, 
  sectorId: string, 
  shares: number, 
  type: 'buy' | 'sell'
): GameState => {
  const sector = state.investments.sectors[sectorId];
  if (!sector || !sector.unlocked) {
    return state;
  }
  
  let newState = { ...state };
  const totalCost = sector.price * shares;
  
  if (type === 'buy') {
    // Check if player has enough cash
    if (state.investments.cash < totalCost) {
      return state;
    }
    
    // Buy shares
    newState.investments.cash -= totalCost;
    newState.investments.sectors[sectorId].shares += shares;
    newState.eventLog.push(`Bought ${shares} shares of ${sector.name} for ${totalCost.toFixed(2)} credits`);
  } 
  else if (type === 'sell') {
    // Check if player has enough shares
    if (sector.shares < shares) {
      return state;
    }
    
    // Sell shares
    newState.investments.cash += totalCost;
    newState.investments.sectors[sectorId].shares -= shares;
    newState.eventLog.push(`Sold ${shares} shares of ${sector.name} for ${totalCost.toFixed(2)} credits`);
  }
  
  return newState;
};

// Convert investment cash to reputation - improved conversion rate
export const cashToReputation = (state: GameState, amount: number): GameState => {
  if (state.investments.cash < amount) {
    return state;
  }
  
  let newState = { ...state };
  newState.investments.cash -= amount;
  // Increased conversion rate from 10% to 12%
  newState.resources.reputation += amount * 0.12;
  newState.eventLog.push(`Converted ${amount} credits to ${(amount * 0.12).toFixed(1)} reputation`);
  
  return newState;
};

// Export buy producer function
export { buyProducer } from './ProducerManager';