import { GameState, SaveData } from '../types/GameTypes';

const SAVE_KEY = 'stellar-expansion-save';

/**
 * Save game state to localStorage
 */
export const saveGame = (saveData: SaveData): void => {
  try {
    const saveString = JSON.stringify(saveData);
    localStorage.setItem(SAVE_KEY, saveString);
    console.log('Game saved', saveData.timestamp);
  } catch (error) {
    console.error('Failed to save game', error);
  }
};

/**
 * Load game state from localStorage
 */
export const loadGame = (): SaveData | null => {
  try {
    const saveString = localStorage.getItem(SAVE_KEY);
    if (!saveString) {
      return null;
    }
    
    const saveData = JSON.parse(saveString) as SaveData;
    return saveData;
  } catch (error) {
    console.error('Failed to load game', error);
    return null;
  }
};

/**
 * Delete saved game from localStorage
 */
export const deleteSave = (): void => {
  try {
    localStorage.removeItem(SAVE_KEY);
    console.log('Save deleted');
  } catch (error) {
    console.error('Failed to delete save', error);
  }
};

/**
 * Export save to a file
 */
export const exportSave = (): void => {
  try {
    const saveString = localStorage.getItem(SAVE_KEY);
    if (!saveString) {
      throw new Error('No save data found');
    }
    
    const blob = new Blob([saveString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = `stellar-expansion-save-${Date.now()}.json`;
    document.body.appendChild(a);
    a.click();
    
    // Cleanup
    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 100);
  } catch (error) {
    console.error('Failed to export save', error);
  }
};

/**
 * Import save from a file
 */
export const importSave = (file: File): Promise<SaveData | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (event) => {
      try {
        if (!event.target || typeof event.target.result !== 'string') {
          throw new Error('Failed to read file');
        }
        
        const saveData = JSON.parse(event.target.result) as SaveData;
        saveGame(saveData);
        resolve(saveData);
      } catch (error) {
        reject(error);
      }
    };
    
    reader.onerror = (error) => {
      reject(error);
    };
    
    reader.readAsText(file);
  });
};