/**
 * Format a number with unit prefixes (K, M, B, T, etc.)
 * Enhanced with better precision control
 */
export const formatNumber = (num: number, precision: number = 2): string => {
  if (isNaN(num)) return '0';
  if (num === 0) return '0';
  
  const absNum = Math.abs(num);
  
  // Use floating point display for small numbers (<1000) with decimals
  if (absNum < 1000) {
    const decimalPlaces = Math.min(precision, countDecimals(num));
    return num.toFixed(decimalPlaces);
  }
  
  const suffixes = ['', 'K', 'M', 'B', 'T', 'Qa', 'Qi', 'Sx', 'Sp', 'Oc', 'No', 'Dc'];
  const exponent = Math.min(Math.floor(Math.log10(absNum) / 3), suffixes.length - 1);
  const scaledNum = num / Math.pow(10, exponent * 3);
  
  // Adjust precision based on scale
  const adjustedPrecision = exponent > 2 ? 1 : precision;
  
  return scaledNum.toFixed(adjustedPrecision) + suffixes[exponent];
};

/**
 * Count number of decimal places in a number
 */
const countDecimals = (num: number): number => {
  if (Math.floor(num) === num) return 0;
  return num.toString().split('.')[1]?.length || 0;
};

/**
 * Format a number as a currency (with $ sign)
 */
export const formatCurrency = (num: number, precision: number = 2): string => {
  return '$' + formatNumber(num, precision);
};

/**
 * Format time in seconds to a human-readable string (e.g. "2h 30m 15s")
 * Enhanced with better time display
 */
export const formatTime = (seconds: number): string => {
  // Handle small times
  if (seconds < 10) {
    return `${seconds.toFixed(1)}s`;
  }
  
  if (seconds < 60) {
    return `${Math.floor(seconds)}s`;
  }
  
  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  }
  
  if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  }
  
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  return `${days}d ${hours}h`;
};

/**
 * Format a percentage with better precision handling
 */
export const formatPercent = (num: number, precision: number = 2): string => {
  // For very small values, show more precision
  if (Math.abs(num) < 0.01) {
    return `${(num * 100).toFixed(4)}%`;
  }
  
  // For values under 1%, show 3 decimal places
  if (Math.abs(num) < 0.1) {
    return `${(num * 100).toFixed(3)}%`;
  }
  
  // Otherwise use the requested precision
  return `${(num * 100).toFixed(precision)}%`;
};

/**
 * Format a rate (per second)
 * Enhanced with better display for small values
 */
export const formatRate = (num: number, precision: number = 2): string => {
  // For very small values, show more precision
  if (Math.abs(num) < 0.01) {
    return `${num.toFixed(4)}/s`;
  }
  
  // For values under 0.1, show 3 decimal places
  if (Math.abs(num) < 0.1) {
    return `${num.toFixed(3)}/s`;
  }
  
  return `${formatNumber(num, precision)}/s`;
};

/**
 * Get CSS color class based on a value compared to a target
 * Enhanced with more granular color ranges
 */
export const getComparisonColorClass = (value: number, target: number): string => {
  const percentage = value / target;
  
  if (percentage >= 1) {
    return 'text-green-400'; // At or above target
  } else if (percentage >= 0.9) {
    return 'text-green-300'; // Very close to target
  } else if (percentage >= 0.75) {
    return 'text-yellow-300'; // Approaching target
  } else if (percentage >= 0.5) {
    return 'text-yellow-500'; // Halfway to target
  } else if (percentage >= 0.25) {
    return 'text-orange-400'; // Quarter way to target
  } else {
    return 'text-red-400'; // Far from target
  }
};

/**
 * Get CSS color class for positive/negative values
 * Enhanced with magnitude indicators
 */
export const getValueColorClass = (value: number): string => {
  if (value > 0) {
    // Positive values: green with brightness based on magnitude
    if (value >= 10) return 'text-green-300';
    if (value >= 1) return 'text-green-400';
    return 'text-green-500';
  } else if (value < 0) {
    // Negative values: red with brightness based on magnitude
    if (value <= -10) return 'text-red-300';
    if (value <= -1) return 'text-red-400';
    return 'text-red-500';
  } else {
    return 'text-gray-400'; // Zero values
  }
};

/**
 * New utility: Format time remaining to target
 * Calculates and formats time to reach a target based on current rate
 */
export const formatTimeToTarget = (current: number, target: number, rate: number): string => {
  if (rate <= 0) return 'Never';
  
  const remaining = target - current;
  if (remaining <= 0) return 'Complete';
  
  const seconds = remaining / rate;
  return formatTime(seconds);
};

/**
 * New utility: Format a value with sign prefix
 */
export const formatWithSign = (num: number, precision: number = 2): string => {
  const formatted = formatNumber(Math.abs(num), precision);
  return num >= 0 ? '+' + formatted : '-' + formatted;
};

/**
 * New utility: Get a color gradient based on percentage
 */
export const getGradientColor = (percentage: number): string => {
  if (percentage >= 90) return 'from-green-500 to-green-300';
  if (percentage >= 75) return 'from-green-600 to-cyan-400';
  if (percentage >= 50) return 'from-cyan-600 to-blue-400';
  if (percentage >= 25) return 'from-blue-600 to-purple-400';
  if (percentage >= 10) return 'from-purple-600 to-pink-400';
  return 'from-pink-600 to-red-400';
};