// src/components/ExplorationPanel.tsx
import React, { useState, useEffect } from 'react';
import { GameState, GamePhase } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber, formatTime } from '../utils/formatters';
import CollapsibleSection from './CollapsibleSection';

interface ExplorationPanelProps {
  gameState: GameState;
  onStartExpedition: (destinationId: string) => void;
  onCollectRewards: (rewards: Record<string, number>) => void;
  isCompact?: boolean;
}

// Interfaces for expedition-related data
interface ExplorationDestination {
  id: string;
  name: string;
  description: string;
  requiredPhase: GamePhase;
  expeditionDuration: number; // in seconds
  resourceCosts: Record<string, number>;
  rewards: {
    guaranteed: Record<string, number>;
    chance: {
      resource: string;
      amount: number;
      probability: number;
    }[];
  };
  discoveryChance: number; // Chance to discover new locations
}

interface ActiveExpedition {
  destinationId: string;
  startTime: number;
  endTime: number;
}

const ExplorationPanel: React.FC<ExplorationPanelProps> = ({ 
  gameState, 
  onStartExpedition,
  onCollectRewards,
  isCompact = false
}) => {
  const [selectedDestination, setSelectedDestination] = useState<string>('asteroid-belt');
  const [activeExpeditions, setActiveExpeditions] = useState<ActiveExpedition[]>([]);
  const [completedExpeditions, setCompletedExpeditions] = useState<{
    destinationId: string;
    rewards: Record<string, number>;
  }[]>([]);
  // Define exploration destinations based on game phases
  const explorationDestinations: ExplorationDestination[] = [
    {
      id: 'asteroid-belt',
      name: 'Asteroid Belt Survey',
      description: 'Explore the asteroid belt for valuable minerals and research opportunities.',
      requiredPhase: GamePhase.ASTEROID_MINING,
      expeditionDuration: 60, // 1 minute
      resourceCosts: {
        [ResourceType.ENERGY]: 50,
      },
      rewards: {
        guaranteed: {
          [ResourceType.MINERALS]: 150,
          [ResourceType.RESEARCH]: 20,
        },
        chance: [
          {
            resource: ResourceType.RARE_METALS,
            amount: 5,
            probability: 0.3
          }
        ]
      },
      discoveryChance: 0.1
    },
    {
      id: 'inner-planets',
      name: 'Inner Planets Expedition',
      description: 'Send a scientific expedition to the inner planets of the solar system.',
      requiredPhase: GamePhase.SOLAR_SYSTEM,
      expeditionDuration: 180, // 3 minutes
      resourceCosts: {
        [ResourceType.ENERGY]: 200,
        [ResourceType.MINERALS]: 100,
      },
      rewards: {
        guaranteed: {
          [ResourceType.RESEARCH]: 120,
          [ResourceType.RARE_METALS]: 30,
        },
        chance: [
          {
            resource: ResourceType.REPUTATION,
            amount: 10,
            probability: 0.4
          }
        ]
      },
      discoveryChance: 0.2
    },
    {
      id: 'gas-giants',
      name: 'Gas Giant Survey',
      description: 'Explore the massive gas giants of the outer solar system.',
      requiredPhase: GamePhase.SOLAR_SYSTEM,
      expeditionDuration: 300, // 5 minutes
      resourceCosts: {
        [ResourceType.ENERGY]: 350,
        [ResourceType.MINERALS]: 150,
      },
      rewards: {
        guaranteed: {
          [ResourceType.HYDROGEN]: 200,
          [ResourceType.HELIUM]: 80,
          [ResourceType.RESEARCH]: 150,
        },
        chance: [
          {
            resource: ResourceType.RARE_METALS,
            amount: 50,
            probability: 0.25
          }
        ]
      },
      discoveryChance: 0.3
    },
    {
      id: 'nearby-star',
      name: 'Nearby Star Exploration',
      description: 'Send an expedition to the nearest star system outside our own.',
      requiredPhase: GamePhase.INTERSTELLAR,
      expeditionDuration: 600, // 10 minutes
      resourceCosts: {
        [ResourceType.ENERGY]: 1000,
        [ResourceType.HYDROGEN]: 300,
        [ResourceType.RARE_METALS]: 100,
      },
      rewards: {
        guaranteed: {
          [ResourceType.RESEARCH]: 500,
          [ResourceType.REPUTATION]: 50,
        },
        chance: [
          {
            resource: ResourceType.ANTIMATTER,
            amount: 15,
            probability: 0.5
          }
        ]
      },
      discoveryChance: 0.6
    },
    {
      id: 'cosmic-anomaly',
      name: 'Cosmic Anomaly Investigation',
      description: 'Investigate a strange cosmic anomaly detected at the edge of known space.',
      requiredPhase: GamePhase.GALACTIC_TRANSCENDENCE,
      expeditionDuration: 900, // 15 minutes
      resourceCosts: {
        [ResourceType.ENERGY]: 5000,
        [ResourceType.ANTIMATTER]: 50,
      },
      rewards: {
        guaranteed: {
          [ResourceType.RESEARCH]: 2000,
          [ResourceType.DARK_MATTER]: 30,
        },
        chance: [
          {
            resource: ResourceType.COSMIC_INFLUENCE,
            amount: 5,
            probability: 0.3
          }
        ]
      },
      discoveryChance: 0.8
    }
  ];
  // Update timer every second
  useEffect(() => {
    const timer = setInterval(() => {
      // Check if any expeditions are complete
      const now = Date.now();
      const newCompleted = activeExpeditions.filter(exp => exp.endTime <= now);
      
      if (newCompleted.length > 0) {
        // Add completed expeditions to the list
        const newCompletedList = newCompleted.map(exp => {
          // Get the destination data
          const destination = explorationDestinations.find(d => d.id === exp.destinationId);
          
          // Calculate rewards
          const rewards: Record<string, number> = {
            ...destination?.rewards.guaranteed || {}
          };
          
          // Calculate random rewards
          destination?.rewards.chance.forEach(chance => {
            if (Math.random() < chance.probability) {
              rewards[chance.resource] = (rewards[chance.resource] || 0) + chance.amount;
            }
          });
          
          return {
            destinationId: exp.destinationId,
            rewards
          };
        });
        
        setCompletedExpeditions(prev => [...prev, ...newCompletedList]);
        
        // Remove completed expeditions from active list
        setActiveExpeditions(prev => prev.filter(exp => exp.endTime > now));
      }
    }, 1000);
    
    return () => clearInterval(timer);
  }, [activeExpeditions, explorationDestinations]);
  
  
  
  // Filter destinations based on game phase
  const availableDestinations = explorationDestinations.filter(
    dest => {
      const requiredPhaseIndex = Object.values(GamePhase).indexOf(dest.requiredPhase);
      const currentPhaseIndex = Object.values(GamePhase).indexOf(gameState.phase);
      return requiredPhaseIndex <= currentPhaseIndex;
    }
  );
  
  // Find selected destination
  const selectedDestinationData = explorationDestinations.find(
    dest => dest.id === selectedDestination
  );
  
  // Check if player can afford to send expedition
  const canAffordExpedition = (destination: ExplorationDestination): boolean => {
    return Object.entries(destination.resourceCosts).every(
      ([resource, amount]) => (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
    );
  };
  
  // Handle starting a new expedition
  const handleStartExpedition = () => {
    if (selectedDestinationData) {
      // Create new active expedition
      const expeditionDuration = selectedDestinationData.expeditionDuration * 1000; // convert to ms
      const newExpedition: ActiveExpedition = {
        destinationId: selectedDestinationData.id,
        startTime: Date.now(),
        endTime: Date.now() + expeditionDuration
      };
      
      setActiveExpeditions(prev => [...prev, newExpedition]);
      
      // Call parent handler to deduct resources
      onStartExpedition(selectedDestinationData.id);
    }
  };
  
  // Handle collecting rewards from a completed expedition
  const handleCollectRewards = (index: number) => {
    const expedition = completedExpeditions[index];
    
    // Call parent handler with rewards
    onCollectRewards(expedition.rewards);
    
    // Remove from completed list
    setCompletedExpeditions(prev => prev.filter((_, i) => i !== index));
  };
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 border-b border-cyan-800 pb-1">Exploration Command</h2>
      
      {/* Destinations - grid layout that's more compact on mobile */}
      <div className="mb-3">
        <h3 className="font-bold mb-2">Destinations</h3>
        <div className={`grid ${isCompact ? 'grid-cols-2 gap-1' : 'grid-cols-3 gap-2'}`}>
          {availableDestinations.map(destination => (
            <button
              key={destination.id}
              className={`p-2 rounded text-sm text-left ${
                selectedDestination === destination.id 
                  ? 'bg-green-800 border border-green-600' 
                  : 'bg-gray-700 hover:bg-gray-600'
              }`}
              onClick={() => setSelectedDestination(destination.id)}
            >
              <div className="font-bold truncate">{destination.name}</div>
              <div className="text-xs text-gray-400">{formatTime(destination.expeditionDuration)}</div>
            </button>
          ))}
        </div>
      </div>
      
      {/* Selected destination details */}
      {selectedDestinationData && (
        <div className="mb-3 p-3 bg-gray-700 rounded">
          <h3 className="font-bold">{selectedDestinationData.name}</h3>
          
          {/* Only show description in non-compact mode */}
          {!isCompact && (
            <p className="text-sm text-gray-300 mb-2">{selectedDestinationData.description}</p>
          )}
          
          <div className="grid grid-cols-2 gap-2 mb-2">
            <div>
              <div className="text-sm font-bold">Duration:</div>
              <div className="text-blue-300">{formatTime(selectedDestinationData.expeditionDuration)}</div>
            </div>
            <div>
              <div className="text-sm font-bold">Discovery Chance:</div>
              <div className="text-blue-300">{Math.round(selectedDestinationData.discoveryChance * 100)}%</div>
            </div>
          </div>
          
          <div className="mb-2">
            <div className="text-sm font-bold">Cost:</div>
            <div className="flex flex-wrap gap-2">
              {Object.entries(selectedDestinationData.resourceCosts).map(([resource, amount]) => (
                <div 
                  key={resource} 
                  className={`text-sm ${
                    (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
                      ? 'text-green-400'
                      : 'text-red-400'
                  }`}>
                  {resource}: {formatNumber(amount)}
                </div>
              ))}
            </div>
          </div>
          
          <div className="mb-2">
            <div className="text-sm font-bold">Guaranteed Rewards:</div>
            <div className="flex flex-wrap gap-2">
              {Object.entries(selectedDestinationData.rewards.guaranteed).map(([resource, amount]) => (
                <div key={resource} className="text-sm text-green-400">
                  {resource}: {formatNumber(amount)}
                </div>
              ))}
            </div>
          </div>
          
          {selectedDestinationData.rewards.chance.length > 0 && (
            <div className="mb-2">
              <div className="text-sm font-bold">Possible Discoveries:</div>
              <div className="flex flex-wrap gap-2">
                {selectedDestinationData.rewards.chance.map((chance, index) => (
                  <div key={index} className="text-sm text-yellow-400">
                    {chance.resource}: {formatNumber(chance.amount)} ({Math.round(chance.probability * 100)}%)
                  </div>
                ))}
              </div>
            </div>
          )}
          
          <button
            className="w-full mt-2 px-3 py-2 bg-green-700 hover:bg-green-600 disabled:bg-gray-600 disabled:opacity-50 rounded"
            onClick={handleStartExpedition}
            disabled={!canAffordExpedition(selectedDestinationData) || activeExpeditions.length >= 3}
          >
            Launch Expedition
          </button>
        </div>
      )}
      
      {/* Active expeditions */}
      {activeExpeditions.length > 0 && (
        <div className="mb-3">
          <h3 className="font-bold mb-2">Active Expeditions ({activeExpeditions.length}/3)</h3>
          <div className="space-y-2">
            {activeExpeditions.map((expedition, index) => {
              const destination = explorationDestinations.find(d => d.id === expedition.destinationId);
              const now = Date.now();
              const timeRemaining = Math.max(0, expedition.endTime - now);
              const progress = 100 - (timeRemaining / (destination?.expeditionDuration || 1) / 10);
              
              return (
                <div key={index} className="p-2 bg-gray-700 rounded">
                  <div className="flex justify-between mb-1">
                    <div className="font-bold">{destination?.name}</div>
                    <div className="text-sm">{formatTime(timeRemaining / 1000)}</div>
                  </div>
                  <div className="w-full h-2 bg-gray-800 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-green-600"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      
      {/* Completed expeditions - collapsible in compact mode */}
      {completedExpeditions.length > 0 && (
        <div className="mb-3">
          {isCompact ? (
            <CollapsibleSection title={`Completed Expeditions (${completedExpeditions.length})`} isOpen={false}>
              <div className="space-y-2">
                {completedExpeditions.map((expedition, index) => {
                  const destination = explorationDestinations.find(d => d.id === expedition.destinationId);
                  
                  return (
                    <div key={index} className="p-2 bg-gray-700 rounded">
                      <div className="mb-1 font-bold">{destination?.name}</div>
                      <div className="text-xs mb-2">
                        {Object.entries(expedition.rewards).map(([resource, amount]) => (
                          <span key={resource} className="mr-2 text-green-400">
                            {resource}: +{formatNumber(amount)}
                          </span>
                        ))}
                      </div>
                      <button
                        className="w-full px-2 py-1 bg-green-700 hover:bg-green-600 rounded text-sm"
                        onClick={() => handleCollectRewards(index)}
                      >
                        Collect Rewards
                      </button>
                    </div>
                  );
                })}
              </div>
            </CollapsibleSection>
          ) : (
            <>
              <h3 className="font-bold mb-2">Completed Expeditions ({completedExpeditions.length})</h3>
              <div className="space-y-2 max-h-60 overflow-y-auto">
                {completedExpeditions.map((expedition, index) => {
                  const destination = explorationDestinations.find(d => d.id === expedition.destinationId);
                  
                  return (
                    <div key={index} className="p-2 bg-gray-700 rounded">
                      <div className="mb-1 font-bold">{destination?.name}</div>
                      <div className="text-sm mb-2">
                        {Object.entries(expedition.rewards).map(([resource, amount]) => (
                          <span key={resource} className="mr-2 text-green-400">
                            {resource}: +{formatNumber(amount)}
                          </span>
                        ))}
                      </div>
                      <button
                        className="w-full px-2 py-1 bg-green-700 hover:bg-green-600 rounded"
                        onClick={() => handleCollectRewards(index)}
                      >
                        Collect Rewards
                      </button>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
      
      {/* Exploration benefits section */}
      {!isCompact && (
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-bold mb-1">Exploration Benefits</h3>
          <div className="text-sm text-gray-300">
            <p>Expeditions provide several benefits to your space empire:</p>
            <ul className="list-disc list-inside mt-1 text-xs">
              <li>Gather rare resources from distant locations</li>
              <li>Research opportunities from scientific discoveries</li>
              <li>Chance to discover new locations and technologies</li>
              <li>Increase reputation through notable achievements</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExplorationPanel;