import { GamePhase, ResourceState, GameState } from '../types/GameTypes';
import { Producer, ProducerState, ProducerType } from '../types/ProducerTypes';
import { ResourceType } from '../types/ResourceTypes';

// Initialize the producer state with rebalanced values
export const initialProducerState: ProducerState = {
  // Phase 1: Asteroid Mining
  [ProducerType.MINERAL_MINER]: {
    type: ProducerType.MINERAL_MINER,
    name: 'Mineral Miner',
    description: 'Extracts minerals from asteroids.',
    baseCost: {
      minerals: 12 // Reduced from 15 for better early game
    },
    baseProduction: {
      [ResourceType.MINERALS]: 0.5, // Increased from 0.4
      [ResourceType.ENERGY]: 0,
      [ResourceType.RESEARCH]: 0,
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: 0,
      [ResourceType.HELIUM]: 0,
      [ResourceType.RARE_METALS]: 0,
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.10, // Reduced from 1.12 for smoother scaling
    count: 0,
    unlocked: false, // Starting as locked, unlocked by upgrades
    visible: true,
    requiredPhase: GamePhase.ASTEROID_MINING
  },
  [ProducerType.SOLAR_PANEL]: {
    type: ProducerType.SOLAR_PANEL,
    name: 'Solar Panel',
    description: 'Generates energy from the distant sun.',
    baseCost: {
      minerals: 20 // Reduced from 25
    },
    baseProduction: {
      [ResourceType.MINERALS]: 0,
      [ResourceType.ENERGY]: 0.3, // Increased from 0.25
      [ResourceType.RESEARCH]: 0,
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: 0,
      [ResourceType.HELIUM]: 0,
      [ResourceType.RARE_METALS]: 0,
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.11, // Reduced from 1.15
    count: 0,
    unlocked: false, // Starting as locked, unlocked by upgrades
    visible: true,
    requiredPhase: GamePhase.ASTEROID_MINING
  },
  [ProducerType.RESEARCH_STATION]: {
    type: ProducerType.RESEARCH_STATION,
    name: 'Research Station',
    description: 'Studies minerals and generates research.',
    baseCost: {
      minerals: 35, // Reduced from 40
      energy: 12 // Reduced from 15
    },
    baseProduction: {
      [ResourceType.MINERALS]: 0,
      [ResourceType.ENERGY]: 0,
      [ResourceType.RESEARCH]: 0.2, // Increased from 0.15
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: 0,
      [ResourceType.HELIUM]: 0,
      [ResourceType.RARE_METALS]: 0,
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.15, // Reduced from 1.2
    count: 0,
    unlocked: false, // Starting as locked, unlocked by upgrades
    visible: true,
    requiredPhase: GamePhase.ASTEROID_MINING
  },
  
  // Phase 2: Solar System
  [ProducerType.ORBITAL_MINER]: {
    type: ProducerType.ORBITAL_MINER,
    name: 'Orbital Miner',
    description: 'Advanced mining rig in orbit around asteroids.',
    baseCost: {
      minerals: 350, // Reduced from 400
      energy: 180 // Reduced from 200
    },
    baseProduction: {
      [ResourceType.MINERALS]: 3.0, // Increased from 2.5
      [ResourceType.ENERGY]: 0,
      [ResourceType.RESEARCH]: 0,
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: 0,
      [ResourceType.HELIUM]: 0,
      [ResourceType.RARE_METALS]: 0,
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.13, // Reduced from 1.15
    count: 0,
    unlocked: false,
    visible: false,
    requiredPhase: GamePhase.SOLAR_SYSTEM
  },
  [ProducerType.GAS_HARVESTER]: {
    type: ProducerType.GAS_HARVESTER,
    name: 'Gas Harvester',
    description: 'Collects hydrogen and helium from gas giants.',
    baseCost: {
      minerals: 550, // Reduced from 600
      energy: 275 // Reduced from 300
    },
    baseProduction: {
      [ResourceType.MINERALS]: 0,
      [ResourceType.ENERGY]: 0,
      [ResourceType.RESEARCH]: 0,
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: 0.5, // Increased from 0.4
      [ResourceType.HELIUM]: 0.2, // Increased from 0.15
      [ResourceType.RARE_METALS]: 0,
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.16, // Reduced from 1.18
    count: 0,
    unlocked: false,
    visible: false,
    requiredPhase: GamePhase.SOLAR_SYSTEM
  },
  [ProducerType.FUSION_PLANT]: {
    type: ProducerType.FUSION_PLANT,
    name: 'Fusion Plant',
    description: 'Generates energy from hydrogen fusion.',
    baseCost: {
      minerals: 900, // Reduced from 1000
      hydrogen: 70 // Reduced from 80
    },
    baseProduction: {
      [ResourceType.MINERALS]: 0,
      [ResourceType.ENERGY]: 3.0, // Increased from 2.5
      [ResourceType.RESEARCH]: 0,
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: -0.08, // Reduced consumption from -0.1
      [ResourceType.HELIUM]: 0.1, // Increased from 0.08
      [ResourceType.RARE_METALS]: 0,
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.18, // Reduced from 1.2
    count: 0,
    unlocked: false,
    visible: false,
    requiredPhase: GamePhase.SOLAR_SYSTEM
  },
  [ProducerType.RARE_METAL_EXTRACTOR]: {
    type: ProducerType.RARE_METAL_EXTRACTOR,
    name: 'Rare Metal Extractor',
    description: 'Extracts valuable rare metals from asteroid cores.',
    baseCost: {
      minerals: 1000, // Reduced from 1200
      energy: 500 // Reduced from 600
    },
    baseProduction: {
      [ResourceType.MINERALS]: 0,
      [ResourceType.ENERGY]: 0,
      [ResourceType.RESEARCH]: 0,
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: 0,
      [ResourceType.HELIUM]: 0,
      [ResourceType.RARE_METALS]: 0.3, // Increased from 0.25
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.20, // Reduced from 1.22
    count: 0,
    unlocked: false,
    visible: false,
    requiredPhase: GamePhase.SOLAR_SYSTEM
  },
  
  // Phase 3: Interstellar
  [ProducerType.STELLAR_EXTRACTOR]: {
    type: ProducerType.STELLAR_EXTRACTOR,
    name: 'Stellar Extractor',
    description: 'Extracts resources from nearby star systems.',
    baseCost: {
      minerals: 3500, // Reduced from 4000
      energy: 1800, // Reduced from 2000
      rareMetals: 70 // Reduced from 80
    },
    baseProduction: {
      [ResourceType.MINERALS]: 10, // Increased from 8
      [ResourceType.ENERGY]: 5, // Increased from 4
      [ResourceType.RESEARCH]: 1.0, // Increased from 0.8
      [ResourceType.REPUTATION]: 0.1, // Increased from 0.08
      [ResourceType.HYDROGEN]: 1.0, // Increased from 0.8
      [ResourceType.HELIUM]: 0.5, // Increased from 0.4
      [ResourceType.RARE_METALS]: 0.5, // Increased from 0.4
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.16, // Reduced from 1.18
    count: 0,
    unlocked: false,
    visible: false,
    requiredPhase: GamePhase.INTERSTELLAR
  },
  [ProducerType.DYSON_NODE]: {
    type: ProducerType.DYSON_NODE,
    name: 'Dyson Node',
    description: 'Collects energy directly from a star.',
    baseCost: {
      minerals: 5500, // Reduced from 6000
      energy: 2700, // Reduced from 3000
      rareMetals: 130 // Reduced from 150
    },
    baseProduction: {
      [ResourceType.MINERALS]: 0,
      [ResourceType.ENERGY]: 15, // Increased from 12
      [ResourceType.RESEARCH]: 1.0, // Increased from 0.8
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: 0,
      [ResourceType.HELIUM]: 0,
      [ResourceType.RARE_METALS]: 0,
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.14, // Reduced from 1.15
    count: 0,
    unlocked: false,
    visible: false,
    requiredPhase: GamePhase.INTERSTELLAR
  },
  [ProducerType.ANTIMATTER_COLLECTOR]: {
    type: ProducerType.ANTIMATTER_COLLECTOR,
    name: 'Antimatter Collector',
    description: 'Collects trace amounts of antimatter from space.',
    baseCost: {
      minerals: 7000, // Reduced from 8000
      energy: 3500, // Reduced from 4000
      rareMetals: 200, // Reduced from 250
      research: 1200 // Reduced from 1500
    },
    baseProduction: {
      [ResourceType.MINERALS]: 0,
      [ResourceType.ENERGY]: 0,
      [ResourceType.RESEARCH]: 0,
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: 0,
      [ResourceType.HELIUM]: 0,
      [ResourceType.RARE_METALS]: 0,
      [ResourceType.ANTIMATTER]: 0.12, // Increased from 0.08
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.22, // Reduced from 1.25
    count: 0,
    unlocked: false,
    visible: false,
    requiredPhase: GamePhase.INTERSTELLAR
  },
  
  // Phase 4: Galactic Transcendence
  [ProducerType.DARK_MATTER_HARVESTER]: {
    type: ProducerType.DARK_MATTER_HARVESTER,
    name: 'Dark Matter Harvester',
    description: 'Extracts dark matter from the fabric of spacetime.',
    baseCost: {
      energy: 12000, // Reduced from 15000
      antimatter: 60 // Reduced from 80
    },
    baseProduction: {
      [ResourceType.MINERALS]: 0,
      [ResourceType.ENERGY]: 0,
      [ResourceType.RESEARCH]: 0,
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: 0,
      [ResourceType.HELIUM]: 0,
      [ResourceType.RARE_METALS]: 0,
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0.2, // Increased from 0.15
      [ResourceType.COSMIC_INFLUENCE]: 0
    },
    costMultiplier: 1.22, // Reduced from 1.25
    count: 0,
    unlocked: false,
    visible: false,
    requiredPhase: GamePhase.GALACTIC_TRANSCENDENCE
  },
  [ProducerType.COSMIC_MANIPULATOR]: {
    type: ProducerType.COSMIC_MANIPULATOR,
    name: 'Cosmic Manipulator',
    description: 'Manipulates the universe to generate cosmic influence.',
    baseCost: {
      energy: 35000, // Reduced from 40000
      antimatter: 300, // Reduced from 400
      darkMatter: 60 // Reduced from 80
    },
    baseProduction: {
      [ResourceType.MINERALS]: 0,
      [ResourceType.ENERGY]: 0,
      [ResourceType.RESEARCH]: 0,
      [ResourceType.REPUTATION]: 0,
      [ResourceType.HYDROGEN]: 0,
      [ResourceType.HELIUM]: 0,
      [ResourceType.RARE_METALS]: 0,
      [ResourceType.ANTIMATTER]: 0,
      [ResourceType.DARK_MATTER]: 0,
      [ResourceType.COSMIC_INFLUENCE]: 0.05 // Increased from 0.03
    },
    costMultiplier: 1.25, // Reduced from 1.3
    count: 0,
    unlocked: false,
    visible: false,
    requiredPhase: GamePhase.GALACTIC_TRANSCENDENCE,
    requiredProducers: {
      [ProducerType.DARK_MATTER_HARVESTER]: 2 // Reduced from 3
    }
  }
};

// Calculate production from all producers
export const getProducerProduction = (producers: ProducerState): Record<ResourceType, number> => {
  const production: Record<ResourceType, number> = {
    [ResourceType.MINERALS]: 0,
    [ResourceType.ENERGY]: 0,
    [ResourceType.RESEARCH]: 0,
    [ResourceType.REPUTATION]: 0,
    [ResourceType.HYDROGEN]: 0,
    [ResourceType.HELIUM]: 0,
    [ResourceType.RARE_METALS]: 0,
    [ResourceType.ANTIMATTER]: 0,
    [ResourceType.DARK_MATTER]: 0,
    [ResourceType.COSMIC_INFLUENCE]: 0
  };

  // Add production from each producer
  Object.values(producers).forEach(producer => {
    if (producer.count > 0) {
      Object.entries(producer.baseProduction).forEach(([resource, rate]) => {
        production[resource as ResourceType] += (rate as number) * producer.count;
      });
    }
  });

  return production;
};

// Calculate the cost of the next producer based on how many you already have
export const getNextProducerCost = (producer: Producer): Record<string, number> => {
  const nextCost: Record<string, number> = {};
  
  // Apply cost scaling formula: baseCost * (costMultiplier ^ count)
  Object.entries(producer.baseCost).forEach(([resource, baseCost]) => {
    nextCost[resource] = Math.ceil(baseCost * Math.pow(producer.costMultiplier, producer.count));
  });
  
  return nextCost;
};

// Check if player can afford a producer
export const canAffordProducer = (resources: ResourceState, producer: Producer): boolean => {
  const cost = getNextProducerCost(producer);
  
  return Object.entries(cost).every(([resource, amount]) => {
    // @ts-ignore
    return resources[resource] >= amount;
  });
};

// Buy a new producer instance
export const buyProducer = (
  gameState: GameState, 
  producerType: ProducerType
): GameState => {
  const producer = gameState.producers[producerType];
  
  // Check if producer exists, is unlocked, and player can afford it
  if (!producer || !producer.unlocked || !canAffordProducer(gameState.resources, producer)) {
    return gameState;
  }
  
  // Calculate cost
  const cost = getNextProducerCost(producer);
  
  // Create a new game state
  const newState = { ...gameState };
  
  // Deduct resources
  Object.entries(cost).forEach(([resource, amount]) => {
    // @ts-ignore
    newState.resources[resource] -= amount;
  });
  
  // Increment producer count
  newState.producers[producerType] = {
    ...producer,
    count: producer.count + 1
  };
  
  // Add to event log
  newState.eventLog.push(`Purchased ${producer.name}`);
  
  // Check if this purchase unlocks any other producers
  Object.values(newState.producers).forEach(p => {
    if (!p.unlocked && p.visible && p.requiredProducers) {
      const requirementsMet = Object.entries(p.requiredProducers).every(
        ([reqType, reqCount]) => newState.producers[reqType as ProducerType].count >= (reqCount as number)
      );
      
      if (requirementsMet) {
        newState.producers[p.type as keyof ProducerState].unlocked = true;
        newState.eventLog.push(`Unlocked ${p.name}`);
      }
    }
  });
  
  return newState;
};

// Update visibility of producers based on game phase
export const updateProducerVisibility = (gameState: GameState): GameState => {
  const newState = { ...gameState };
  const phaseNum = Object.values(GamePhase).indexOf(gameState.phase) + 1;
  
  Object.values(newState.producers).forEach(producer => {
    const producerPhaseNum = Object.values(GamePhase).indexOf(producer.requiredPhase) + 1;
    
    // Make producer visible if its phase requirement is met
    if (producerPhaseNum <= phaseNum) {
      newState.producers[producer.type as keyof ProducerState].visible = true;
      
      // Don't automatically unlock producers, they'll be unlocked by upgrades
      if (producer.requiredProducers) {
        // Check if required producers are met
        const requirementsMet = Object.entries(producer.requiredProducers).every(
          ([reqType, reqCount]) => newState.producers[reqType as ProducerType].count >= (reqCount as number)
        );
        
        if (requirementsMet && producer.unlocked) {
          newState.producers[producer.type as keyof ProducerState].unlocked = true;
        }
      }
    }
  });
  
  return newState;
};

// Get visible producers based on game phase
export const getVisibleProducers = (producerState: ProducerState): Producer[] => {
  return Object.values(producerState)
    .filter(producer => producer.visible)
    .sort((a, b) => {
      // Sort by phase first
      const phaseA = Object.values(GamePhase).indexOf(a.requiredPhase);
      const phaseB = Object.values(GamePhase).indexOf(b.requiredPhase);
      
      if (phaseA !== phaseB) return phaseA - phaseB;
      
      // Then by unlocked status
      if (a.unlocked !== b.unlocked) return a.unlocked ? -1 : 1;
      
      // Then by name
      return a.name.localeCompare(b.name);
    });
};