// src/components/ResearchLabPanel.tsx
import React, { useState } from 'react';
import { GameState } from '../types/GameTypes';
import { formatNumber } from '../utils/formatters';
import CollapsibleSection from './CollapsibleSection';

interface ResearchLabPanelProps {
  gameState: GameState;
  onAllocateResearch: (areaId: string, amount: number) => void;
  isCompact?: boolean;
}

// Interface for research areas
interface ResearchArea {
  id: string;
  name: string;
  description: string;
  currentLevel: number;
  maxLevel: number;
  costPerLevel: number;
  effect: string;
  unlockPhase: number;
}

const ResearchLabPanel: React.FC<ResearchLabPanelProps> = ({ 
  gameState, 
  onAllocateResearch,
  isCompact = false
}) => {
  const [selectedArea, setSelectedArea] = useState<string>('mineralogy');
  const [researchAmount, setResearchAmount] = useState<number>(10);
  
  // Define research areas
  const researchAreas: ResearchArea[] = [
    {
      id: 'mineralogy',
      name: 'Mineralogy',
      description: 'Improve mineral extraction and processing techniques.',
      currentLevel: gameState.research?.mineralogy || 0,
      maxLevel: 10,
      costPerLevel: 50,
      effect: '+10% mineral production per level',
      unlockPhase: 1
    },
    {
      id: 'energetics',
      name: 'Energetics',
      description: 'Enhance energy generation and storage capabilities.',
      currentLevel: gameState.research?.energetics || 0,
      maxLevel: 10,
      costPerLevel: 60,
      effect: '+10% energy production per level',
      unlockPhase: 1
    },
    {
      id: 'propulsion',
      name: 'Propulsion',
      description: 'Develop advanced propulsion systems for space travel.',
      currentLevel: gameState.research?.propulsion || 0,
      maxLevel: 8,
      costPerLevel: 100,
      effect: 'Unlocks new exploration capabilities',
      unlockPhase: 2
    },
    {
      id: 'astrophysics',
      name: 'Astrophysics',
      description: 'Study the physics of stars and celestial bodies.',
      currentLevel: gameState.research?.astrophysics || 0,
      maxLevel: 8,
      costPerLevel: 120,
      effect: 'Improves resource discovery in star systems',
      unlockPhase: 2
    },
    {
      id: 'quantum-mechanics',
      name: 'Quantum Mechanics',
      description: 'Explore the fundamental nature of matter and energy.',
      currentLevel: gameState.research?.quantumMechanics || 0,
      maxLevel: 6,
      costPerLevel: 200,
      effect: 'Required for antimatter technology',
      unlockPhase: 3
    },
    {
      id: 'cosmic-manipulation',
      name: 'Cosmic Manipulation',
      description: 'Master the ability to manipulate space, time, and reality.',
      currentLevel: gameState.research?.cosmicManipulation || 0,
      maxLevel: 5,
      costPerLevel: 500,
      effect: 'Opens pathways to transcendence',
      unlockPhase: 4
    }
  ];
  
  // Get current phase number for filtering
  const phaseNumber = ['ASTEROID_MINING', 'SOLAR_SYSTEM', 'INTERSTELLAR', 'GALACTIC_TRANSCENDENCE']
    .indexOf(gameState.phase) + 1;
  
  // Filter research areas based on current phase
  const availableResearchAreas = researchAreas.filter(area => area.unlockPhase <= phaseNumber);
  
  // Find selected research area
  const selectedResearchArea = researchAreas.find(area => area.id === selectedArea);
  
  // Calculate research cost
  const researchCost = selectedResearchArea ? 
    selectedResearchArea.costPerLevel * (selectedResearchArea.currentLevel + 1) : 0;
  
  // Calculate max research that can be allocated with current points
  const maxResearch = Math.floor(gameState.resources.research / researchCost);
  
  // Handle research allocation
  const handleAllocateResearch = () => {
    if (selectedResearchArea && gameState.resources.research >= researchCost) {
      onAllocateResearch(selectedResearchArea.id, researchAmount);
    }
  };
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 border-b border-cyan-800 pb-1">Research Laboratory</h2>
      
      {/* Research points display */}
      <div className="mb-3">
        <div className="flex justify-between">
          <span>Available Research:</span>
          <span className="font-bold text-blue-300">{formatNumber(gameState.resources.research)}</span>
        </div>
      </div>
      
      {/* Research areas - grid layout for desktop, list for mobile */}
      <div className={`grid ${isCompact ? 'grid-cols-2 gap-1' : 'grid-cols-3 gap-2'} mb-3`}>
        {availableResearchAreas.map(area => (
          <button
            key={area.id}
            className={`p-2 rounded text-sm text-left ${
              selectedArea === area.id 
                ? 'bg-blue-800 border border-blue-600' 
                : 'bg-gray-700 hover:bg-gray-600'
            }`}
            onClick={() => setSelectedArea(area.id)}
          >
            <div className="font-bold truncate">{area.name}</div>
            <div className="text-xs">Level: {area.currentLevel}/{area.maxLevel}</div>
          </button>
        ))}
      </div>
      
      {/* Selected research area details */}
      {selectedResearchArea && (
        <div className="p-3 bg-gray-700 rounded mb-3">
          <h3 className="font-bold">{selectedResearchArea.name}</h3>
          
          {/* Only show description in non-compact mode */}
          {!isCompact && (
            <p className="text-sm text-gray-300 mb-2">{selectedResearchArea.description}</p>
          )}
          
          <div className="grid grid-cols-2 gap-2 mb-2">
            <div>Level: <span className="text-blue-300">{selectedResearchArea.currentLevel}/{selectedResearchArea.maxLevel}</span></div>
            <div>Cost: <span className="text-blue-300">{formatNumber(researchCost)}</span></div>
          </div>
          
          <div className="text-sm mb-2">
            <span className="font-bold">Effect: </span>
            <span className="text-blue-300">{selectedResearchArea.effect}</span>
          </div>
          
          {selectedResearchArea.currentLevel < selectedResearchArea.maxLevel ? (
            <div className="flex mt-2">
              <input
                type="number"
                min="1"
                max={maxResearch}
                value={researchAmount}
                onChange={(e) => setResearchAmount(Math.min(parseInt(e.target.value) || 1, maxResearch))}
                className="w-20 px-2 py-1 bg-gray-800 border border-gray-600 rounded mr-2 text-white"
              />
              
              <button
                className="flex-1 bg-blue-700 hover:bg-blue-600 py-1 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handleAllocateResearch}
                disabled={gameState.resources.research < researchCost || selectedResearchArea.currentLevel >= selectedResearchArea.maxLevel}
              >
                Allocate Research
              </button>
            </div>
          ) : (
            <div className="text-green-400 mt-2">Research Maxed Out!</div>
          )}
        </div>
      )}
      
      {/* Research benefits - collapsible in compact mode */}
      {isCompact ? (
        <CollapsibleSection title="Research Benefits" isOpen={false}>
          <div className="text-sm text-gray-300">
            <p>Research improves your operations and unlocks new capabilities:</p>
            <ul className="list-disc list-inside mt-1 text-xs">
              <li>Mineralogy boosts all mineral production</li>
              <li>Energetics increases energy generation efficiency</li>
              <li>Advanced fields unlock new technologies and mechanics</li>
            </ul>
          </div>
        </CollapsibleSection>
      ) : (
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-bold mb-1">Research Benefits</h3>
          <div className="text-sm text-gray-300">
            <p>Allocating research points to different areas improves your operations and unlocks new capabilities:</p>
            <ul className="list-disc list-inside mt-1 text-xs">
              <li>Mineralogy boosts all mineral production by 10% per level</li>
              <li>Energetics increases energy generation efficiency by 10% per level</li>
              <li>Propulsion research unlocks new exploration capabilities</li>
              <li>Astrophysics improves resource discovery in star systems</li>
              <li>Quantum Mechanics is required for antimatter technology</li>
              <li>Cosmic Manipulation opens pathways to transcendence</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResearchLabPanel;