import { GameState } from '../types/GameTypes';
import { updateGameState, TICK_RATE } from '../game/GameState';

// Type for the game loop callback function
type GameLoopCallback = (state: GameState) => void;

// Game loop class
export class GameLoop {
  private intervalId: number | null = null;
  private lastUpdateTime: number = 0;
  private updateCallback: GameLoopCallback;
  private gameState: GameState;
  private running: boolean = false;
  private targetFrameTime: number;
  private speedFactor: number = 1; // Added speed factor for debug purposes

  constructor(initialState: GameState, updateCallback: GameLoopCallback) {
    this.gameState = initialState;
    this.updateCallback = updateCallback;
    this.targetFrameTime = 1000 / TICK_RATE; // ms per frame
    
    // Listen for speed change events from debug tools
    window.addEventListener('game-speed-change', (event: Event) => {
      const customEvent = event as CustomEvent;
      if (customEvent.detail && typeof customEvent.detail.factor === 'number') {
        this.setSpeedFactor(customEvent.detail.factor);
      }
    });
  }

  /**
   * Start the game loop
   */
  public start(): void {
    if (this.running) {
      return;
    }

    this.running = true;
    this.lastUpdateTime = performance.now();
    this.tick();
  }

  /**
   * Stop the game loop
   */
  public stop(): void {
    this.running = false;
    if (this.intervalId !== null) {
      window.clearTimeout(this.intervalId);
      this.intervalId = null;
    }
  }

  /**
   * Update the game state
   */
  public updateState(state: GameState): void {
    this.gameState = state;
  }

  /**
   * Get the current game state
   */
  public getState(): GameState {
    return this.gameState;
  }

  /**
   * Set the speed factor for debugging
   * This will make the game run faster (factor > 1) or slower (factor < 1)
   */
  public setSpeedFactor(factor: number): void {
    if (factor <= 0) {
      console.warn('Speed factor must be positive, using 1 instead');
      factor = 1;
    }
    
    console.log(`Game speed set to ${factor}x`);
    this.speedFactor = factor;
  }

  /**
   * Get the current speed factor
   */
  public getSpeedFactor(): number {
    return this.speedFactor;
  }

  /**
   * Main game loop tick
   */
  private tick(): void {
    if (!this.running) {
      return;
    }

    const currentTime = performance.now();
    let deltaTime = currentTime - this.lastUpdateTime;
    
    // Apply speed factor for debugging
    deltaTime *= this.speedFactor;

    if (deltaTime >= this.targetFrameTime) {
      // Calculate how many updates to perform based on accumulated time and speed factor
      const updateCount = Math.floor(deltaTime / this.targetFrameTime);
      
      // Limit max updates to prevent potential lag spirals
      const maxUpdatesPerFrame = Math.min(10 * this.speedFactor, 30);
      const updates = Math.min(updateCount, maxUpdatesPerFrame);
      
      // Perform multiple updates if needed (for higher speed factors)
      for (let i = 0; i < updates; i++) {
        // Update game state
        this.gameState = updateGameState(this.gameState);
      }
      
      // Call the update callback with the new state
      this.updateCallback(this.gameState);
      
      // Adjust lastUpdateTime for the actual number of updates performed
      this.lastUpdateTime = currentTime - (deltaTime % this.targetFrameTime) / this.speedFactor;
    }

    // Schedule next tick
    this.intervalId = window.setTimeout(() => this.tick(), 1);
  }
}