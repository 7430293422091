import { 
  Upgrade, 
  GamePhase, 
  UpgradeCategory 
} from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { ProducerType } from '../types/ProducerTypes';

// Define all game upgrades with rebalanced values and new panel unlocks
export const upgrades: Upgrade[] = [
  // Phase 1: Asteroid Mining - TIER 1
  {
    id: 'basic-drill',
    name: 'Basic Mineral Drill',
    description: 'A simple drill to extract minerals from asteroids.',
    cost: {
      minerals: 8 // Reduced from 10 for easier start
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.MINERALS]: 0.6 // Increased from 0.5
      },
      unlocks: [ProducerType.MINERAL_MINER]
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: []
  },
  {
    id: 'solar-collection',
    name: 'Solar Collection',
    description: 'Harness energy from the distant sun with simple panels.',
    cost: {
      minerals: 25 // Reduced from 30
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.ENERGY]: 0.25 // Increased from 0.2
      },
      unlocks: [ProducerType.SOLAR_PANEL]
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: []
  },
  {
    id: 'scientific-method',
    name: 'Scientific Method',
    description: 'Implement basic scientific techniques to generate research.',
    cost: {
      minerals: 40, // Reduced from 50
      energy: 10 // Reduced from 15
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.RESEARCH,
    effect: {
      resourceProduction: {
        [ResourceType.RESEARCH]: 0.15 // Increased from 0.1
      },
      unlocks: [ProducerType.RESEARCH_STATION]
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['solar-collection']
  },
  
  // NEW: Research Lab panel unlock - early in Phase 1
  {
    id: 'basic-research-lab',
    name: 'Basic Research Laboratory',
    description: 'Establish a dedicated research facility for specialized scientific studies.',
    cost: {
      minerals: 75,
      energy: 25,
      research: 15
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.RESEARCH,
    effect: {
      resourceProduction: {
        [ResourceType.RESEARCH]: 0.3
      },
      unlocks: ['researchLab'] // This unlocks the Research Lab panel
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['scientific-method']
  },
  
  // Phase 1: Asteroid Mining - TIER 2
  {
    id: 'improved-drill',
    name: 'Improved Drill',
    description: 'An upgraded drill with higher efficiency.',
    cost: {
      minerals: 85, // Reduced from 100
      energy: 20 // Reduced from 25
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.MINERALS]: 1.5 // Increased from 1.2
      },
      producerBoost: {
        [ProducerType.MINERAL_MINER]: 0.3 // Increased from 0.25
      }
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['basic-drill']
  },
  {
    id: 'solar-array',
    name: 'Solar Array',
    description: 'A collection of solar panels for increased energy generation.',
    cost: {
      minerals: 125, // Reduced from 150
      energy: 35 // Reduced from 40
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.ENERGY]: 1.0 // Increased from 0.8
      },
      producerBoost: {
        [ProducerType.SOLAR_PANEL]: 0.3 // Increased from 0.25
      }
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['solar-collection']
  },
  {
    id: 'advanced-research-tools',
    name: 'Advanced Research Tools',
    description: 'Better equipment for your scientists to accelerate research.',
    cost: {
      minerals: 175, // Reduced from 200
      energy: 50, // Reduced from 60
      research: 20 // Reduced from 25
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.RESEARCH,
    effect: {
      resourceProduction: {
        [ResourceType.RESEARCH]: 0.4 // Increased from 0.3
      },
      producerBoost: {
        [ProducerType.RESEARCH_STATION]: 0.35 // Increased from 0.3
      }
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['scientific-method']
  },
  
  // Phase 1: Asteroid Mining - TIER 3
  {
    id: 'mining-team',
    name: 'Mining Team',
    description: 'A full team of miners operating multiple drills simultaneously.',
    cost: {
      minerals: 300, // Reduced from 350
      energy: 80, // Reduced from 100
      research: 40 // Reduced from 50
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.MINERALS]: 2.5 // Increased from 2.0
      },
      producerBoost: {
        [ProducerType.MINERAL_MINER]: 0.6 // Increased from 0.5
      }
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['improved-drill']
  },
  {
    id: 'energy-storage',
    name: 'Energy Storage System',
    description: 'Batteries to store excess energy for later use.',
    cost: {
      minerals: 250, // Reduced from 300
      energy: 125, // Reduced from 150
      research: 60 // Reduced from 75
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.EFFICIENCY,
    effect: {
      resourceProduction: {
        [ResourceType.ENERGY]: 1.5 // Increased from 1.0
      },
      producerBoost: {
        [ProducerType.SOLAR_PANEL]: 0.6 // Increased from 0.5
      }
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['solar-array']
  },
  {
    id: 'research-network',
    name: 'Research Network',
    description: 'Connect your research stations to share data and discoveries.',
    cost: {
      minerals: 350, // Reduced from 400
      energy: 175, // Reduced from 200
      research: 85 // Reduced from 100
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.RESEARCH,
    effect: {
      resourceProduction: {
        [ResourceType.RESEARCH]: 1.0 // Increased from 0.8
      },
      producerBoost: {
        [ProducerType.RESEARCH_STATION]: 0.7 // Increased from 0.6
      }
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['advanced-research-tools']
  },
  
  // Phase 1: Asteroid Mining - TIER 4 (Automation & Final Phase 1)
  {
    id: 'auto-miner',
    name: 'Automated Mining System',
    description: 'Robotic systems that mine minerals without manual intervention.',
    cost: {
      minerals: 650, // Reduced from 750
      energy: 250, // Reduced from 300
      research: 125 // Reduced from 150
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.AUTOMATION,
    effect: {
      resourceProduction: {
        [ResourceType.MINERALS]: 4.0 // Increased from 3.0
      },
      unlocks: ['automation']
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['mining-team', 'research-network']
  },
  {
    id: 'comms-array',
    name: 'Communications Array',
    description: 'Establish communications with other mining operations.',
    cost: {
      minerals: 800, // Reduced from 1000
      energy: 400, // Reduced from 500
      research: 150 // Reduced from 200
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.REPUTATION]: 0.03 // Increased from 0.02
      }
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['research-network']
  },
  {
    id: 'market-access',
    name: 'Market Access',
    description: 'Connect to the solar system resource market for investments.',
    cost: {
      minerals: 1200, // Reduced from 1500
      energy: 600, // Reduced from 800
      research: 250, // Reduced from 300
      reputation: 4 // Reduced from 5
    },
    purchased: false,
    enabled: false,
    visible: true,
    category: UpgradeCategory.EXPANSION,
    effect: {
      unlocks: ['investments']
    },
    requiredPhase: GamePhase.ASTEROID_MINING,
    requiredUpgrades: ['comms-array']
  },
  
  // Phase 2: Solar System Expansion - TIER 1
  {
    id: 'orbital-station',
    name: 'Orbital Station',
    description: 'A station in orbit around the asteroid for expanded operations.',
    cost: {
      minerals: 2500, // Reduced from 3000
      energy: 1200, // Reduced from 1500
      research: 400 // Reduced from 500
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.MINERALS]: 3, // Increased from 2
        [ResourceType.ENERGY]: 1.5, // Increased from 1
        [ResourceType.RESEARCH]: 0.3 // Increased from 0.2
      },
      unlocks: [ProducerType.ORBITAL_MINER]
    },
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    requiredUpgrades: []
  },
  
  // NEW: Exploration panel unlock - early Phase 2
  {
    id: 'exploration-program',
    name: 'Exploration Program',
    description: 'Launch a systematic program to explore the solar system.',
    cost: {
      minerals: 3000,
      energy: 1500,
      research: 600,
      reputation: 10
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.RESEARCH]: 1.0,
        [ResourceType.REPUTATION]: 0.05
      },
      unlocks: ['exploration'] // This unlocks the Exploration panel
    },
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    requiredUpgrades: ['orbital-station']
  },
  
  {
    id: 'gas-harvesting',
    name: 'Gas Giant Harvesting',
    description: 'Develop technology to harvest gases from gas giants.',
    cost: {
      minerals: 3500, // Reduced from 4000
      energy: 1700, // Reduced from 2000
      research: 650 // Reduced from 800
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.HYDROGEN]: 0.15, // Increased from 0.1
        [ResourceType.HELIUM]: 0.07 // Increased from 0.05
      },
      unlocks: [ProducerType.GAS_HARVESTER]
    },
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    requiredUpgrades: ['orbital-station']
  },
  
  // NEW: Colony Management panel unlock - mid Phase 2
  {
    id: 'planetary-outpost',
    name: 'Planetary Outpost',
    description: 'Establish your first permanent settlement on a planetary body.',
    cost: {
      minerals: 5000,
      energy: 2500,
      research: 1000,
      hydrogen: 100
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.MINERALS]: 2.0,
        [ResourceType.ENERGY]: 1.0,
        [ResourceType.RESEARCH]: 0.5
      },
      unlocks: ['colonyManagement'] // This unlocks the Colony Management panel
    },
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    requiredUpgrades: ['gas-harvesting']
  },
  
  {
    id: 'fusion-technology',
    name: 'Fusion Technology',
    description: 'Harness the power of nuclear fusion for energy generation.',
    cost: {
      minerals: 4200, // Reduced from 5000
      energy: 2000, // Reduced from 2500
      research: 800, // Reduced from 1000
      hydrogen: 80 // Reduced from 100
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.ENERGY]: 4, // Increased from 3
        [ResourceType.HELIUM]: 0.15 // Increased from 0.1
      },
      unlocks: [ProducerType.FUSION_PLANT]
    },
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    requiredUpgrades: ['gas-harvesting']
  },
  {
    id: 'rare-metal-extraction',
    name: 'Rare Metal Extraction',
    description: 'Methods to extract valuable rare metals from asteroid cores.',
    cost: {
      minerals: 5000, // Reduced from 6000
      energy: 2500, // Reduced from 3000
      research: 1000 // Reduced from 1200
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.RARE_METALS]: 0.08 // Increased from 0.05
      },
      unlocks: [ProducerType.RARE_METAL_EXTRACTOR]
    },
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    requiredUpgrades: ['orbital-station']
  },
  
  // Phase 2: Solar System - TIER 2
  {
    id: 'advanced-orbital-mining',
    name: 'Advanced Orbital Mining',
    description: 'Improved asteroid mining from orbit with advanced equipment.',
    cost: {
      minerals: 6500, // Reduced from 8000
      energy: 3200, // Reduced from 4000
      research: 1200, // Reduced from 1500
      rareMetals: 80 // Reduced from 100
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.MINERALS]: 6 // Increased from 4
      },
      producerBoost: {
        [ProducerType.ORBITAL_MINER]: 0.5 // Increased from 0.4
      }
    },
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    requiredUpgrades: ['orbital-station']
  },
  {
    id: 'gas-giant-probe',
    name: 'Gas Giant Probe Network',
    description: 'A network of automated probes to optimize gas harvesting.',
    cost: {
      minerals: 8000, // Reduced from 10000
      energy: 4000, // Reduced from 5000
      research: 1600, // Reduced from 2000
      hydrogen: 150, // Reduced from 200
      helium: 80 // Reduced from 100
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EFFICIENCY,
    effect: {
      resourceProduction: {
        [ResourceType.HYDROGEN]: 0.5, // Increased from 0.3
        [ResourceType.HELIUM]: 0.25 // Increased from 0.15
      },
      producerBoost: {
        [ProducerType.GAS_HARVESTER]: 0.6 // Increased from 0.5
      }
    },
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    requiredUpgrades: ['gas-harvesting']
  },
  {
    id: 'fusion-catalysts',
    name: 'Fusion Catalysts',
    description: 'Special materials that improve fusion efficiency.',
    cost: {
      minerals: 10000, // Reduced from 12000
      energy: 5000, // Reduced from 6000
      research: 2000, // Reduced from 2500
      hydrogen: 250, // Reduced from 300
      rareMetals: 120 // Reduced from 150
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EFFICIENCY,
    effect: {
      resourceProduction: {
        [ResourceType.ENERGY]: 8 // Increased from 6
      },
      producerBoost: {
        [ProducerType.FUSION_PLANT]: 0.7 // Increased from 0.6
      }
    },
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    requiredUpgrades: ['fusion-technology']
  },
  
  // Phase 2: Solar System - TIER 3 (Final Phase 2)
  {
    id: 'deep-space-propulsion',
    name: 'Deep Space Propulsion',
    description: 'Advanced engines capable of interstellar travel.',
    cost: {
      minerals: 12000, // Reduced from 15000
      energy: 6000, // Reduced from 8000
      research: 3000, // Reduced from 4000
      rareMetals: 250, // Reduced from 300
      hydrogen: 400, // Reduced from 500
      helium: 250 // Reduced from 300
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.RESEARCH]: 3, // Increased from 2
        [ResourceType.REPUTATION]: 0.15 // Increased from 0.1
      }
    },
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    requiredUpgrades: ['fusion-catalysts', 'gas-giant-probe', 'advanced-orbital-mining']
  },
  
  // Phase 3: Interstellar - TIER 1
  {
    id: 'interstellar-ship',
    name: 'Interstellar Vessel',
    description: 'A ship capable of traveling to nearby star systems.',
    cost: {
      minerals: 20000, // Reduced from 25000
      energy: 12000, // Reduced from 15000
      research: 5000, // Reduced from 6000
      hydrogen: 800, // Reduced from 1000
      rareMetals: 400 // Reduced from 500
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.MINERALS]: 8, // Increased from 5
        [ResourceType.ENERGY]: 5, // Increased from 3
        [ResourceType.RESEARCH]: 2, // Increased from 1
        [ResourceType.REPUTATION]: 0.15 // Increased from 0.1
      },
      unlocks: [ProducerType.STELLAR_EXTRACTOR]
    },
    requiredPhase: GamePhase.INTERSTELLAR,
    requiredUpgrades: []
  },
  
  // NEW: Spacefleet panel unlock - early Phase 3
  {
    id: 'fleet-command',
    name: 'Fleet Command Center',
    description: 'Establish a command center to manage your growing fleet of interstellar vessels.',
    cost: {
      minerals: 25000,
      energy: 15000,
      research: 6000,
      rareMetals: 500,
      reputation: 50
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.RESEARCH]: 2.0,
        [ResourceType.REPUTATION]: 0.2
      },
      unlocks: ['spacefleet'] // This unlocks the Spacefleet panel
    },
    requiredPhase: GamePhase.INTERSTELLAR,
    requiredUpgrades: ['interstellar-ship']
  },
  
  {
    id: 'star-harvesting',
    name: 'Star Energy Harvesting',
    description: 'Technology to collect energy directly from stars.',
    cost: {
      minerals: 25000, // Reduced from 30000
      energy: 15000, // Reduced from 20000
      research: 6000, // Reduced from 8000
      rareMetals: 600 // Reduced from 800
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.ENERGY]: 12 // Increased from 8
      },
      unlocks: [ProducerType.DYSON_NODE]
    },
    requiredPhase: GamePhase.INTERSTELLAR,
    requiredUpgrades: ['interstellar-ship']
  },
  {
    id: 'antimatter-detection',
    name: 'Antimatter Detection',
    description: 'Technology to detect and collect trace amounts of antimatter.',
    cost: {
      minerals: 30000, // Reduced from 40000
      energy: 18000, // Reduced from 25000
      research: 7000, // Reduced from 10000
      rareMetals: 750 // Reduced from 1000
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.ANTIMATTER]: 0.03 // Increased from 0.02
      },
      unlocks: [ProducerType.ANTIMATTER_COLLECTOR]
    },
    requiredPhase: GamePhase.INTERSTELLAR,
    requiredUpgrades: ['interstellar-ship']
  },
  
  // Phase 3: Interstellar - TIER 2
  {
    id: 'interstellar-mining-network',
    name: 'Interstellar Mining Network',
    description: 'A network of mining operations across multiple star systems.',
    cost: {
      minerals: 40000, // Reduced from 50000
      energy: 25000, // Reduced from 30000
      research: 12000, // Reduced from 15000
      rareMetals: 1200, // Reduced from 1500
      antimatter: 40 // Reduced from 50
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.MINERALS]: 20, // Increased from 15
        [ResourceType.RARE_METALS]: 1.5 // Increased from 1
      },
      producerBoost: {
        [ProducerType.STELLAR_EXTRACTOR]: 0.6 // Increased from 0.5
      }
    },
    requiredPhase: GamePhase.INTERSTELLAR,
    requiredUpgrades: ['interstellar-ship']
  },
  {
    id: 'dyson-swarm',
    name: 'Dyson Swarm',
    description: 'A collection of solar panels orbiting a star for enormous energy collection.',
    cost: {
      minerals: 50000, // Reduced from 60000
      energy: 30000, // Reduced from 40000
      research: 15000, // Reduced from 20000
      rareMetals: 1600, // Reduced from 2000
      antimatter: 75 // Reduced from 100
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.ENERGY]: 30, // Increased from 20
        [ResourceType.RESEARCH]: 3 // Increased from 2
      },
      producerBoost: {
        [ProducerType.DYSON_NODE]: 0.7 // Increased from 0.6
      }
    },
    requiredPhase: GamePhase.INTERSTELLAR,
    requiredUpgrades: ['star-harvesting']
  },
  {
    id: 'antimatter-containment',
    name: 'Antimatter Containment',
    description: 'Advanced containment fields to safely store larger amounts of antimatter.',
    cost: {
      minerals: 60000, // Reduced from 70000
      energy: 40000, // Reduced from 50000
      research: 20000, // Reduced from 25000
      rareMetals: 2000, // Reduced from 2500
      antimatter: 150 // Reduced from 200
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.ANTIMATTER]: 0.15 // Increased from 0.1
      },
      producerBoost: {
        [ProducerType.ANTIMATTER_COLLECTOR]: 0.8 // Increased from 0.7
      }
    },
    requiredPhase: GamePhase.INTERSTELLAR,
    requiredUpgrades: ['antimatter-detection']
  },
  
  // NEW: Diplomacy panel unlock - late Phase 3
  {
    id: 'first-contact-protocol',
    name: 'First Contact Protocol',
    description: 'Establish protocols for communicating with other intelligent civilizations.',
    cost: {
      minerals: 80000,
      energy: 50000,
      research: 25000,
      antimatter: 200,
      reputation: 150
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.REPUTATION]: 0.5
      },
      unlocks: ['diplomacy'] // This unlocks the Diplomacy panel
    },
    requiredPhase: GamePhase.INTERSTELLAR,
    requiredUpgrades: ['dyson-swarm', 'antimatter-containment']
  },
  
  // Phase 3: Interstellar - TIER 3 (Final Phase 3)
  {
    id: 'spacetime-bending',
    name: 'Spacetime Manipulation',
    description: 'Technology to bend the fabric of spacetime, opening new horizons.',
    cost: {
      minerals: 80000, // Reduced from 100000
      energy: 60000, // Reduced from 80000
      research: 30000, // Reduced from 40000
      rareMetals: 4000, // Reduced from 5000
      antimatter: 300 // Reduced from 400
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.RESEARCH]: 8, // Increased from 5
        [ResourceType.ANTIMATTER]: 0.3, // Increased from 0.2
        [ResourceType.REPUTATION]: 0.8 // Increased from 0.5
      }
    },
    requiredPhase: GamePhase.INTERSTELLAR,
    requiredUpgrades: ['interstellar-mining-network', 'first-contact-protocol', 'antimatter-containment']
  },
  
  // Phase 4: Galactic Transcendence - TIER 1
  {
    id: 'dark-matter-detector',
    name: 'Dark Matter Detector',
    description: 'Equipment to detect and harvest dark matter.',
    cost: {
      minerals: 150000, // Reduced from 200000
      energy: 100000, // Reduced from 150000
      research: 45000, // Reduced from 60000
      antimatter: 450 // Reduced from 600
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.DARK_MATTER]: 0.08 // Increased from 0.05
      },
      unlocks: [ProducerType.DARK_MATTER_HARVESTER]
    },
    requiredPhase: GamePhase.GALACTIC_TRANSCENDENCE,
    requiredUpgrades: []
  },
  {
    id: 'cosmic-influence',
    name: 'Cosmic Influence Generator',
    description: 'Technology that manipulates the fundamental forces of the universe.',
    cost: {
      minerals: 250000, // Reduced from 300000
      energy: 150000, // Reduced from 200000
      research: 75000, // Reduced from 100000
      antimatter: 600, // Reduced from 800
      darkMatter: 75 // Reduced from 100
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.COSMIC_INFLUENCE]: 0.015 // Increased from 0.01
      },
      unlocks: [ProducerType.COSMIC_MANIPULATOR]
    },
    requiredPhase: GamePhase.GALACTIC_TRANSCENDENCE,
    requiredUpgrades: ['dark-matter-detector']
  },
  
  // NEW: Reality Manipulation panel unlock - mid Phase 4
  {
    id: 'reality-manipulation-matrix',
    name: 'Reality Manipulation Matrix',
    description: 'Create a framework for altering the fundamental constants of reality itself.',
    cost: {
      minerals: 300000,
      energy: 200000,
      research: 100000,
      antimatter: 800,
      darkMatter: 120,
      cosmicInfluence: 10
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.COSMIC_INFLUENCE]: 0.05
      },
      unlocks: ['realityManipulation'] // This unlocks the Reality Manipulation panel
    },
    requiredPhase: GamePhase.GALACTIC_TRANSCENDENCE,
    requiredUpgrades: ['cosmic-influence']
  },
  
  // Phase 4: Galactic Transcendence - TIER 2
  {
    id: 'dark-matter-manipulation',
    name: 'Dark Matter Manipulation',
    description: 'Advanced techniques to manipulate and concentrate dark matter.',
    cost: {
      minerals: 300000, // Reduced from 400000
      energy: 200000, // Reduced from 300000
      research: 100000, // Reduced from 150000
      antimatter: 750, // Reduced from 1000
      darkMatter: 150 // Reduced from 200
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.DARK_MATTER]: 0.3 // Increased from 0.2
      },
      producerBoost: {
        [ProducerType.DARK_MATTER_HARVESTER]: 0.6 // Increased from 0.5
      }
    },
    requiredPhase: GamePhase.GALACTIC_TRANSCENDENCE,
    requiredUpgrades: ['dark-matter-detector']
  },
  {
    id: 'cosmic-harmonics',
    name: 'Cosmic Harmonics',
    description: 'Manipulate cosmic influence through harmonic resonance.',
    cost: {
      minerals: 350000, // Reduced from 500000
      energy: 250000, // Reduced from 400000
      research: 150000, // Reduced from 200000
      antimatter: 1000, // Reduced from 1500
      darkMatter: 200, // Reduced from 300
      cosmicInfluence: 7 // Reduced from 10
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.PRODUCTION,
    effect: {
      resourceProduction: {
        [ResourceType.COSMIC_INFLUENCE]: 0.08 // Increased from 0.05
      },
      producerBoost: {
        [ProducerType.COSMIC_MANIPULATOR]: 0.7 // Increased from 0.6
      }
    },
    requiredPhase: GamePhase.GALACTIC_TRANSCENDENCE,
    requiredUpgrades: ['cosmic-influence']
  },
  
  // Phase 4: Galactic Transcendence - TIER 3 (Final)
  {
    id: 'universal-consciousness',
    name: 'Universal Consciousness',
    description: 'The final step towards ascending beyond physical form.',
    cost: {
      research: 350000, // Reduced from 500000
      antimatter: 1500, // Reduced from 2000
      darkMatter: 350, // Reduced from 500
      cosmicInfluence: 35 // Reduced from 50
    },
    purchased: false,
    enabled: false,
    visible: false,
    category: UpgradeCategory.EXPANSION,
    effect: {
      resourceProduction: {
        [ResourceType.COSMIC_INFLUENCE]: 0.8 // Increased from 0.5
      }
    },
    requiredPhase: GamePhase.GALACTIC_TRANSCENDENCE,
    requiredUpgrades: ['dark-matter-manipulation', 'cosmic-harmonics', 'reality-manipulation-matrix']
  }
];