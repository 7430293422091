import React, { useState } from 'react';
import { GameState } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber } from '../utils/formatters';
import { PHASE_REQUIREMENTS } from '../game/GameState';
import CollectButton from '../components/CollectButton';
import CollapsibleSection from '../components/CollapsibleSection';

interface SolarSystemProps {
  gameState: GameState;
  onCollectResource: (resource: string, amount: number, event?: React.MouseEvent | React.TouchEvent) => void;
  isCompact?: boolean;
}

interface CelestialBody {
  id: string;
  name: string;
  description: string;
  resources: { resource: ResourceType; amountPerClick: number }[];
  image: string;
  efficiency: number;
}

const SolarSystem: React.FC<SolarSystemProps> = ({ 
  gameState, 
  onCollectResource,
  isCompact = false
}) => {
  const [selectedBody, setSelectedBody] = useState<string>('asteroid-belt');
  
  // Define celestial bodies in the solar system with rebalanced values
  const celestialBodies: CelestialBody[] = [
    {
      id: 'asteroid-belt',
      name: 'Asteroid Belt',
      description: 'A dense region of rocky bodies between inner and outer planets. Rich in minerals.',
      resources: [
        { resource: ResourceType.MINERALS, amountPerClick: 15 }
      ],
      image: 'asteroid-belt',
      efficiency: 1.0
    },
    {
      id: 'inner-planets',
      name: 'Inner Planets',
      description: 'Rocky terrestrial planets close to the sun. Good sources of energy and rare metals.',
      resources: [
        { resource: ResourceType.ENERGY, amountPerClick: 8 },
        { resource: ResourceType.RARE_METALS, amountPerClick: 1.5 }
      ],
      image: 'inner-planets',
      efficiency: 1.2
    },
    {
      id: 'gas-giants',
      name: 'Gas Giants',
      description: 'Massive planets composed primarily of hydrogen and helium.',
      resources: [
        { resource: ResourceType.HYDROGEN, amountPerClick: 8 },
        { resource: ResourceType.HELIUM, amountPerClick: 3 }
      ],
      image: 'gas-giants',
      efficiency: 1.5
    },
    {
      id: 'outer-system',
      name: 'Outer System',
      description: 'Distant, icy bodies at the edge of the solar system. Valuable for research.',
      resources: [
        { resource: ResourceType.RESEARCH, amountPerClick: 3 },
        { resource: ResourceType.REPUTATION, amountPerClick: 0.8 }
      ],
      image: 'outer-system',
      efficiency: 1.8
    }
  ];
  
  // Find the selected celestial body
  const selectedBodyData = celestialBodies.find(body => body.id === selectedBody);
  
  // Requirements to progress to next phase
  const nextPhaseRequirements = PHASE_REQUIREMENTS.INTERSTELLAR;
  
  // Calculate progress percentages
  const hydrogenProgress = Math.min(100, (gameState.resources.hydrogen / nextPhaseRequirements.hydrogen) * 100);
  const heliumProgress = Math.min(100, (gameState.resources.helium / nextPhaseRequirements.helium) * 100);
  const researchProgress = Math.min(100, (gameState.resources.research / nextPhaseRequirements.research) * 100);
  
  // Calculate the combined progress percentage
  const overallProgress = (hydrogenProgress + heliumProgress + researchProgress) / 3;
  
  // Get recommended body based on current needs
  const getRecommendedBody = (): string => {
    const needsHydrogen = hydrogenProgress < 80;
    const needsHelium = heliumProgress < 80;
    const needsResearch = researchProgress < 80;
    
    if (needsHydrogen || needsHelium) return 'gas-giants';
    if (needsResearch) return 'outer-system';
    return 'inner-planets'; // Default to inner planets if no specific need
  };
  
  const recommendedBody = getRecommendedBody();
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2">Solar System Operations</h2>
      
      {/* Description - collapsible in compact mode */}
      {isCompact ? (
        <CollapsibleSection title="About This Phase" isOpen={false}>
          <p className="text-gray-300 mb-2">
            Your operations now span multiple celestial bodies across the solar system. 
            Collect specialized resources from each location to fuel your interstellar ambitions.
          </p>
        </CollapsibleSection>
      ) : (
        <p className="text-gray-300 mb-4">
          Your operations now span multiple celestial bodies across the solar system. 
          Collect specialized resources from each location to fuel your interstellar ambitions.
        </p>
      )}
      
      <div className="grid grid-cols-4 gap-2 mb-4">
        {celestialBodies.map(body => (
          <button
            key={body.id}
            className={`p-2 rounded text-sm text-center ${
              body.id === selectedBody 
                ? 'bg-cyan-800 border border-cyan-600' 
                : body.id === recommendedBody
                  ? 'bg-cyan-900 hover:bg-cyan-800 border border-gray-600' // Highlight recommended
                  : 'bg-gray-700 hover:bg-gray-600'
            }`}
            onClick={() => setSelectedBody(body.id)}
          >
            {body.name}
            {body.id === recommendedBody && (
              <div className="text-xs text-cyan-300 mt-1">Recommended</div>
            )}
          </button>
        ))}
      </div>
      
      {selectedBodyData && (
        <div className="p-3 bg-gray-700 rounded">
          <h3 className="font-bold text-lg">{selectedBodyData.name}</h3>
          
          {/* Description - Hide in compact mode or make very brief */}
          {!isCompact && (
            <p className="text-sm text-gray-300 mb-3">{selectedBodyData.description}</p>
          )}
          
          <div className="flex flex-wrap gap-2">
            {selectedBodyData.resources.map(({ resource, amountPerClick }) => {
              // Apply the body's efficiency factor to the resource collection
              const adjustedAmount = Math.floor(amountPerClick * selectedBodyData.efficiency);
              
              return (
                <CollectButton
                  key={resource}
                  resource={resource}
                  amount={adjustedAmount}
                  onClick={onCollectResource}
                  className="px-4 py-2 bg-cyan-800 hover:bg-cyan-700 rounded transition-colors flex-1 min-h-[44px]"
                >
                  Collect {resource} (+{adjustedAmount})
                </CollectButton>
              );
            })}
          </div>
          
          {/* Resource tips - only show in non-compact mode */}
          {!isCompact && (
            <div className="mt-3 text-xs text-gray-300">
              {hydrogenProgress < 60 && selectedBody === 'gas-giants' && (
                <div className="text-cyan-300">Tip: Focus on collecting Hydrogen to progress faster.</div>
              )}
              {heliumProgress < 60 && selectedBody === 'gas-giants' && (
                <div className="text-cyan-300">Tip: Helium is essential for advanced fusion technology.</div>
              )}
              {researchProgress < 60 && selectedBody === 'outer-system' && (
                <div className="text-cyan-300">Tip: More research will unlock interstellar travel capabilities.</div>
              )}
            </div>
          )}
        </div>
      )}
      
      <div className="mt-4">
        <h3 className="font-bold text-cyan-300 mb-2">Progress to Interstellar Expansion</h3>
        
        {/* Overall progress bar */}
        <div className="mb-3">
          <div className="flex justify-between text-sm mb-1">
            <span>Overall Progress</span>
            <span>{Math.floor(overallProgress)}%</span>
          </div>
          <div className="w-full h-3 bg-gray-700 rounded-full overflow-hidden">
            <div 
              className="h-full bg-gradient-to-r from-cyan-600 to-green-500" 
              style={{ width: `${overallProgress}%` }}
            />
          </div>
        </div>
        
        <div className="space-y-2">
          <div>
            <div className="flex justify-between text-sm mb-1">
              <span>Hydrogen</span>
              <span>
                {formatNumber(gameState.resources.hydrogen)} / {formatNumber(nextPhaseRequirements.hydrogen)}
              </span>
            </div>
            <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
              <div 
                className="h-full bg-green-600" 
                style={{ width: `${hydrogenProgress}%` }}
              />
            </div>
          </div>
          
          <div>
            <div className="flex justify-between text-sm mb-1">
              <span>Helium</span>
              <span>
                {formatNumber(gameState.resources.helium)} / {formatNumber(nextPhaseRequirements.helium)}
              </span>
            </div>
            <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
              <div 
                className="h-full bg-green-700" 
                style={{ width: `${heliumProgress}%` }}
              />
            </div>
          </div>
          
          <div>
            <div className="flex justify-between text-sm mb-1">
              <span>Research</span>
              <span>
                {formatNumber(gameState.resources.research)} / {formatNumber(nextPhaseRequirements.research)}
              </span>
            </div>
            <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
              <div 
                className="h-full bg-blue-600" 
                style={{ width: `${researchProgress}%` }}
              />
            </div>
          </div>
        </div>
      </div>
      
      {/* Strategy guide - collapsible in compact mode */}
      {isCompact ? (
        <div className="mt-3">
          <CollapsibleSection title="Strategy Guide" isOpen={false}>
            <div className="text-sm">
              <span className="font-bold">Tip:</span> Invest in fusion technology to convert hydrogen into energy and helium.
            </div>
            <ul className="list-disc list-inside text-xs mt-1">
              <li>Build Gas Harvesters for hydrogen and helium</li>
              <li>Use Fusion Plants to generate energy while producing helium</li>
              <li>Extract Rare Metals to upgrade your technology</li>
            </ul>
          </CollapsibleSection>
        </div>
      ) : (
        <div className="mt-4 text-sm text-gray-400">
          <div>
            <span className="font-bold">Tip:</span> Invest in fusion technology to efficiently convert hydrogen into energy and helium.
          </div>
          
          {/* Production strategy tips */}
          <div className="mt-2 p-2 bg-gray-700 rounded">
            <h4 className="font-bold text-xs text-cyan-400">Optimization Strategy:</h4>
            <ul className="list-disc list-inside text-xs mt-1">
              <li>Build Gas Harvesters to collect hydrogen and helium</li>
              <li>Use Fusion Plants to generate energy while producing helium</li>
              <li>Extract Rare Metals to upgrade your technology</li>
              <li>Invest profits from the market in research stations</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SolarSystem;