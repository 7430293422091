import React, { useState } from 'react';
import { GameState } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber } from '../utils/formatters';
import CollectButton from '../components/CollectButton';
import CollapsibleSection from '../components/CollapsibleSection';

interface GalacticTranscendenceProps {
  gameState: GameState;
  onCollectResource: (resource: string, amount: number, event?: React.MouseEvent | React.TouchEvent) => void;
  isCompact?: boolean;
}

interface CosmicPhenomenon {
  id: string;
  name: string;
  description: string;
  resources: { resource: ResourceType; amountPerClick: number }[];
  requiredCosmicInfluence: number;
  unlocked: boolean;
  tier: number;
}

const GalacticTranscendence: React.FC<GalacticTranscendenceProps> = ({ 
  gameState, 
  onCollectResource,
  isCompact = false
}) => {
  const [selectedPhenomenon, setSelectedPhenomenon] = useState<string>('black-hole');
  
  // Define cosmic phenomena that can be manipulated
  const cosmicPhenomena: CosmicPhenomenon[] = [
    {
      id: 'black-hole',
      name: 'Supermassive Black Hole',
      description: 'The gravitational heart of a galaxy. A source of immense energy and dark matter.',
      resources: [
        { resource: ResourceType.ENERGY, amountPerClick: 800 },
        { resource: ResourceType.DARK_MATTER, amountPerClick: 8 }
      ],
      requiredCosmicInfluence: 0,
      unlocked: true,
      tier: 1
    },
    {
      id: 'neutron-star-merger',
      name: 'Neutron Star Merger',
      description: 'Colliding neutron stars that produce gravitational waves and exotic matter.',
      resources: [
        { resource: ResourceType.ANTIMATTER, amountPerClick: 75 },
        { resource: ResourceType.RESEARCH, amountPerClick: 300 }
      ],
      requiredCosmicInfluence: 8,
      unlocked: gameState.resources.cosmicInfluence >= 8,
      tier: 1
    },
    {
      id: 'galactic-core',
      name: 'Galactic Core',
      description: 'The central region of the galaxy, dense with stars, energy, and exotic materials.',
      resources: [
        { resource: ResourceType.ANTIMATTER, amountPerClick: 150 },
        { resource: ResourceType.DARK_MATTER, amountPerClick: 30 },
        { resource: ResourceType.COSMIC_INFLUENCE, amountPerClick: 1.5 }
      ],
      requiredCosmicInfluence: 20,
      unlocked: gameState.resources.cosmicInfluence >= 20,
      tier: 2
    },
    {
      id: 'vacuum-energy',
      name: 'Vacuum Energy Harvesting',
      description: 'Extracting energy from the quantum vacuum itself.',
      resources: [
        { resource: ResourceType.ENERGY, amountPerClick: 1500 },
        { resource: ResourceType.DARK_MATTER, amountPerClick: 75 },
        { resource: ResourceType.COSMIC_INFLUENCE, amountPerClick: 3 }
      ],
      requiredCosmicInfluence: 35,
      unlocked: gameState.resources.cosmicInfluence >= 35,
      tier: 2
    },
    {
      id: 'cosmic-strings',
      name: 'Cosmic String Manipulation',
      description: 'Harnessing one-dimensional topological defects in spacetime.',
      resources: [
        { resource: ResourceType.ANTIMATTER, amountPerClick: 300 },
        { resource: ResourceType.DARK_MATTER, amountPerClick: 150 },
        { resource: ResourceType.COSMIC_INFLUENCE, amountPerClick: 7.5 }
      ],
      requiredCosmicInfluence: 70,
      unlocked: gameState.resources.cosmicInfluence >= 70,
      tier: 3
    },
    {
      id: 'quantum-foam',
      name: 'Quantum Foam Distillation',
      description: 'Extracting pure quantum fluctuations from spacetime itself.',
      resources: [
        { resource: ResourceType.DARK_MATTER, amountPerClick: 250 },
        { resource: ResourceType.COSMIC_INFLUENCE, amountPerClick: 12 }
      ],
      requiredCosmicInfluence: 120,
      unlocked: gameState.resources.cosmicInfluence >= 120,
      tier: 3
    },
    {
      id: 'multiverse-bridge',
      name: 'Multiverse Bridge',
      description: 'Creating connections between parallel universes to harvest boundless energy.',
      resources: [
        { resource: ResourceType.ANTIMATTER, amountPerClick: 500 },
        { resource: ResourceType.DARK_MATTER, amountPerClick: 400 },
        { resource: ResourceType.COSMIC_INFLUENCE, amountPerClick: 20 }
      ],
      requiredCosmicInfluence: 200,
      unlocked: gameState.resources.cosmicInfluence >= 200,
      tier: 4
    }
  ];
  
  // Find the selected phenomenon
  const selectedPhenomenonData = cosmicPhenomena.find(p => p.id === selectedPhenomenon);
  
  // Victory conditions
  const victoryRequirement = 600;
  const victoryProgress = Math.min(100, (gameState.resources.cosmicInfluence / victoryRequirement) * 100);
  
  // Calculate next unlock
  const getNextUnlock = () => {
    const unlocked = cosmicPhenomena.filter(p => p.unlocked);
    const notUnlocked = cosmicPhenomena.filter(p => !p.unlocked);
    
    if (notUnlocked.length === 0) return null;
    
    return notUnlocked.reduce((prev, current) => 
      prev.requiredCosmicInfluence < current.requiredCosmicInfluence ? prev : current
    );
  };
  
  const nextUnlock = getNextUnlock();
  
  // Group phenomena by tier
  const phenomenaByTier = cosmicPhenomena.reduce((acc, phenomenon) => {
    if (!acc[phenomenon.tier]) {
      acc[phenomenon.tier] = [];
    }
    acc[phenomenon.tier].push(phenomenon);
    return acc;
  }, {} as Record<number, CosmicPhenomenon[]>);
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 text-cyan-300">Galactic Transcendence</h2>
      
      {/* Description - collapsible in compact mode */}
      {isCompact ? (
        <CollapsibleSection title="About This Phase" isOpen={false}>
          <p className="text-gray-300 mb-2">
            You've reached the final phase of expansion. Your civilization now harnesses 
            the fundamental forces of the cosmos, manipulating the fabric of reality itself.
          </p>
        </CollapsibleSection>
      ) : (
        <p className="text-gray-300 mb-4">
          You've reached the final phase of expansion. Your civilization now harnesses 
          the fundamental forces of the cosmos, manipulating the fabric of reality itself.
        </p>
      )}
      
      {/* Progress and next unlock - simplified in compact mode */}
      <div className="mb-4 bg-gray-700 p-3 rounded">
        <div className="flex justify-between mb-2">
          <span className="text-sm">Cosmic Influence</span>
          <span className="text-sm font-bold text-cyan-300">{formatNumber(gameState.resources.cosmicInfluence)}</span>
        </div>
        
        {nextUnlock && (
          <div className="text-xs text-gray-300 mb-2">
            Next unlock: <span className="text-purple-300">{nextUnlock.name}</span> {!isCompact && `(requires ${nextUnlock.requiredCosmicInfluence} Cosmic Influence)`}
            <div className="w-full h-1 bg-gray-800 rounded-full mt-1">
              <div 
                className="h-full bg-purple-600"
                style={{ width: `${Math.min(100, (gameState.resources.cosmicInfluence / nextUnlock.requiredCosmicInfluence) * 100)}%` }}
              />
            </div>
          </div>
        )}
      </div>
      
      {/* Display phenomena grouped by tier - more compact grid on mobile */}
      {Object.entries(phenomenaByTier).map(([tier, phenomena]) => (
        <div key={tier} className="mb-3">
          <h3 className="text-sm font-bold text-cyan-300 mb-2">Tier {tier} Phenomena</h3>
          <div className={`grid ${isCompact ? 'grid-cols-2' : 'grid-cols-2 md:grid-cols-3'} gap-2`}>
            {phenomena.map(phenomenon => (
              <button
                key={phenomenon.id}
                className={`p-2 rounded text-sm text-center ${
                  !phenomenon.unlocked 
                    ? 'bg-gray-900 text-gray-600 cursor-not-allowed'
                    : phenomenon.id === selectedPhenomenon 
                      ? 'bg-cyan-800 border border-cyan-600' 
                      : 'bg-gray-700 hover:bg-gray-600'
                }`}
                onClick={() => phenomenon.unlocked && setSelectedPhenomenon(phenomenon.id)}
                disabled={!phenomenon.unlocked}
              >
                {phenomenon.name}
                {!phenomenon.unlocked && (
                  <div className="text-xs">
                    Requires {phenomenon.requiredCosmicInfluence} CI
                  </div>
                )}
              </button>
            ))}
          </div>
        </div>
      ))}
      
      {/* Selected phenomenon details - simplified in compact mode */}
      {selectedPhenomenonData && (
        <div className="p-3 bg-gray-700 rounded">
          <h3 className="font-bold text-lg">{selectedPhenomenonData.name}</h3>
          
          {/* Description - hide in compact mode */}
          {!isCompact && (
            <p className="text-sm text-gray-300 mb-3">{selectedPhenomenonData.description}</p>
          )}
          
          <div className="space-y-2">
            {selectedPhenomenonData.resources.map(({ resource, amountPerClick }) => (
                <CollectButton
                  key={resource}
                  resource={resource}
                  amount={amountPerClick}
                  onClick={onCollectResource}
                  className={`px-3 py-2 rounded transition-colors w-full ${
                    resource === ResourceType.COSMIC_INFLUENCE 
                      ? 'bg-purple-800 hover:bg-purple-700' 
                      : 'bg-cyan-800 hover:bg-cyan-700'
                  }`}
                >
                  Harvest {resource} (+{amountPerClick})
                </CollectButton>
            ))}
          </div>
          
          {/* Phenomenon-specific tips - only in non-compact mode */}
          {!isCompact && (
            <>
              {selectedPhenomenonData.id === 'black-hole' && gameState.resources.cosmicInfluence < 10 && (
                <div className="mt-2 text-xs text-cyan-300">
                  Tip: Focus on building Dark Matter Harvesters first, then move to Cosmic Manipulators.
                </div>
              )}
              {selectedPhenomenonData.id === 'vacuum-energy' && (
                <div className="mt-2 text-xs text-cyan-300">
                  Tip: This phenomenon is excellent for rapidly increasing your energy reserves for expensive upgrades.
                </div>
              )}
              {selectedPhenomenonData.id === 'cosmic-strings' && (
                <div className="mt-2 text-xs text-cyan-300">
                  Tip: The most efficient source of Cosmic Influence - ideal for accelerating toward transcendence.
                </div>
              )}
            </>
          )}
        </div>
      )}
      
      <div className="mt-4">
        <h3 className="font-bold text-cyan-300 mb-2">Progress to Universal Consciousness</h3>
        
        <div>
          <div className="flex justify-between text-sm mb-1">
            <span>Cosmic Influence</span>
            <span>
              {formatNumber(gameState.resources.cosmicInfluence)} / {formatNumber(victoryRequirement)}
            </span>
          </div>
          <div className="w-full h-4 bg-gray-700 rounded-full overflow-hidden">
            <div 
              className="h-full bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500" 
              style={{ width: `${victoryProgress}%` }}
            />
          </div>
        </div>
      </div>
      
      {/* Victory message - keep this always visible */}
      {gameState.resources.cosmicInfluence >= victoryRequirement && (
        <div className="mt-4 p-4 bg-gradient-to-r from-cyan-900 to-purple-900 rounded text-center">
          <h3 className="text-xl font-bold text-white mb-2">Congratulations!</h3>
          <p className="text-gray-200">
            Your civilization has transcended physical limitations and merged with the fabric of the universe itself.
            You have completed Stellar Expansion!
          </p>
          <CollectButton
            resource={ResourceType.COSMIC_INFLUENCE}
            amount={1000}
            onClick={onCollectResource}
            className="px-4 py-2 bg-cyan-800 hover:bg-cyan-700 rounded transition-colors w-full min-h-[44px] mt-2"
          >
            Ascend Beyond (+1,000 Cosmic Influence)
          </CollectButton>
        </div>
      )}
      
      {/* Strategy guide - collapsible in compact mode */}
      {isCompact ? (
        <div className="mt-3">
          <CollapsibleSection title="Strategy Guide" isOpen={false}>
            <ul className="list-disc list-inside text-xs text-gray-300 mt-1">
              <li>Build at least 5 Dark Matter Harvesters for base production</li>
              <li>Unlock Cosmic Manipulators as quickly as possible</li>
              <li>Focus on higher tier phenomena for greater rewards</li>
              <li>Manual collection becomes increasingly valuable in this phase</li>
            </ul>
          </CollapsibleSection>
        </div>
      ) : (
        <div className="mt-4">
          <div className="bg-gray-700 p-3 rounded">
            <h4 className="text-sm font-bold text-cyan-300">Transcendence Strategy Guide</h4>
            <ul className="list-disc list-inside text-xs text-gray-300 mt-1">
              <li>Build at least 5 Dark Matter Harvesters to establish a base production</li>
              <li>Unlock Cosmic Manipulators as quickly as possible</li>
              <li>Invest heavily in Cosmic Harmonics to boost Cosmic Influence generation</li>
              <li>Focus on unlocking higher tier phenomena for more efficient resource collection</li>
              <li>Remember that higher tier phenomena provide exponentially greater rewards</li>
              <li>Manual collection becomes increasingly valuable in this phase - harvest regularly!</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default GalacticTranscendence;