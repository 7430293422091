import React, { useState } from 'react';
import { GameState, Upgrade, UpgradeCategory, ResourceCost } from '../types/GameTypes';
import { formatNumber } from '../utils/formatters';
import { ProducerType } from '../types/ProducerTypes';

interface UpgradePanelProps {
  gameState: GameState;
  onBuyUpgrade: (upgradeId: string) => void;
  onToggleUpgrade: (upgradeId: string) => void;
  isCompact?: boolean;
}

const UpgradePanel: React.FC<UpgradePanelProps> = ({ 
  gameState, 
  onBuyUpgrade, 
  onToggleUpgrade,
  isCompact = false
}) => {
  const [activeTab, setActiveTab] = useState<UpgradeCategory | 'ALL'>(UpgradeCategory.PRODUCTION);
  const [showPurchased, setShowPurchased] = useState(!isCompact); // Default to hiding purchased in compact mode
  
  // Get visible upgrades directly from gameState
  const visibleUpgrades = Object.values(gameState.upgrades).filter(upgrade => upgrade.visible);
  
  // Filter upgrades based on active tab and showPurchased setting
  const filteredUpgrades = visibleUpgrades.filter((upgrade: Upgrade) => {
    if (!showPurchased && upgrade.purchased) {
      return false;
    }
    
    if (activeTab === 'ALL') {
      return true;
    }
    
    return upgrade.category === activeTab;
  });
  
  // Check if player can afford an upgrade
  const canAffordUpgrade = (cost: ResourceCost): boolean => {
    return Object.entries(cost).every(([resource, amount]) => {
      // @ts-ignore
      return gameState.resources[resource] >= amount;
    });
  };
  
  // Format the cost of an upgrade - simplified in compact mode
  const formatCost = (cost: ResourceCost): React.ReactNode => {
    return (
      <div className="text-xs">
        {Object.entries(cost).map(([resource, amount]) => {
          // @ts-ignore
          const canAfford = gameState.resources[resource] >= amount;
          
          return (
            <div key={resource} className={canAfford ? 'text-green-400' : 'text-red-400'}>
              {isCompact ? resource.substring(0, 3) : resource}: {formatNumber(amount)}
            </div>
          );
        })}
      </div>
    );
  };

  // Format unlock effects - simplified in compact mode
  const formatUnlocks = (upgrade: Upgrade): React.ReactNode => {
    if (!upgrade.effect.unlocks || upgrade.effect.unlocks.length === 0) {
      return null;
    }

    // In compact mode, just show "Unlocks: X"
    if (isCompact) {
      return (
        <div className="mt-1 text-xs">
          <span className="text-cyan-400">Unlocks:</span> {upgrade.effect.unlocks.length} items
        </div>
      );
    }

    // Detailed mode shows the actual unlocks
    const unlockNodes: React.ReactNode[] = [];
    upgrade.effect.unlocks.forEach((unlock: string, index: number) => {
      // Check if it's a producer unlock
      let node;
      
      if (Object.values(ProducerType).includes(unlock as ProducerType)) {
        const producerName = gameState.producers[unlock as ProducerType]?.name || unlock;
        node = <span key={unlock} className="text-green-300">{producerName}</span>;
      } else if (unlock === 'automation') {
        node = <span key={unlock} className="text-green-300">Automation</span>;
      } else if (unlock === 'investments') {
        node = <span key={unlock} className="text-green-300">Investment System</span>;
      } else {
        // Default case (another upgrade)
        const unlockName = gameState.upgrades[unlock]?.name || unlock;
        node = <span key={unlock} className="text-green-300">{unlockName}</span>;
      }
      
      // Add node and comma if not the last item
      unlockNodes.push(node);
      if (upgrade.effect.unlocks && index < upgrade.effect.unlocks.length - 1) {
        unlockNodes.push(<span key={`sep-${unlock}`}>, </span>);
      }
    });

    return (
      <div className="mt-1 text-xs">
        <span className="text-cyan-400">Unlocks: </span>
        {unlockNodes}
      </div>
    );
  };
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 border-b border-cyan-800 pb-1">Upgrades</h2>
      
      <div className="flex flex-wrap mb-2">
        {/* Show fewer tabs in compact mode */}
        {(!isCompact || activeTab === 'ALL') && (
          <button
            className={`mr-1 mb-1 px-2 py-1 text-xs rounded ${activeTab === 'ALL' ? 'bg-cyan-700' : 'bg-gray-700'}`}
            onClick={() => setActiveTab('ALL')}
          >
            All
          </button>
        )}
        
        {Object.values(UpgradeCategory).map(category => (
          <button
            key={category}
            className={`mr-1 mb-1 px-2 py-1 text-xs rounded ${activeTab === category ? 'bg-cyan-700' : 'bg-gray-700'}`}
            onClick={() => setActiveTab(category)}
          >
            {/* Shorten category names in compact mode */}
            {isCompact 
              ? category.substring(0, 4) 
              : category}
          </button>
        ))}
        
        <button
          className="ml-auto px-2 py-1 text-xs rounded bg-gray-700"
          onClick={() => setShowPurchased(!showPurchased)}
        >
          {showPurchased ? 'Hide Purchased' : 'Show All'}
        </button>
      </div>
      
      <div className="space-y-1 max-h-96 overflow-y-auto pr-1">
        {filteredUpgrades.length === 0 ? (
          <div className="text-gray-400 italic text-sm">No upgrades available</div>
        ) : (
          filteredUpgrades.map((upgrade: Upgrade) => (
            <div 
              key={upgrade.id} 
              className={`p-2 rounded border ${
                upgrade.purchased 
                  ? 'border-green-800 bg-gray-800' 
                  : canAffordUpgrade(upgrade.cost)
                    ? 'border-cyan-800 bg-gray-800 hover:bg-gray-700 cursor-pointer'
                    : 'border-gray-800 bg-gray-800 opacity-75'
              }`}
              onClick={() => {
                if (!upgrade.purchased && canAffordUpgrade(upgrade.cost)) {
                  onBuyUpgrade(upgrade.id);
                }
              }}
            >
              <div className="flex justify-between items-start">
                <div className="w-3/5">
                  <div className="font-bold text-sm">{upgrade.name}</div>
                  
                  {/* Hide description in compact mode */}
                  {!isCompact && (
                    <div className="text-xs text-gray-400 h-8 overflow-hidden">{upgrade.description}</div>
                  )}
                  
                  {/* Show effects more compactly in compact mode */}
                  {isCompact ? (
                    <div className="text-xs flex flex-wrap mt-1">
                      {upgrade.effect.resourceProduction && (
                        <span className="mr-2 text-green-300">+Production</span>
                      )}
                      {upgrade.effect.producerBoost && (
                        <span className="mr-2 text-yellow-300">+Boost</span>
                      )}
                      {upgrade.effect.unlocks && upgrade.effect.unlocks.length > 0 && (
                        <span className="text-cyan-300">+Unlock</span>
                      )}
                    </div>
                  ) : (
                    <>
                      {upgrade.effect.resourceProduction && (
                        <div className="mt-1 text-xs">
                          <span className="text-cyan-400">Production: </span>
                          {Object.entries(upgrade.effect.resourceProduction).map(([resource, amount], index, arr) => (
                            <span key={resource}>
                              <span className="text-green-300">
                                {resource}: +{formatNumber(Number(amount))}/s
                              </span>
                              {index < arr.length - 1 ? ', ' : ''}
                            </span>
                          ))}
                        </div>
                      )}
                      {upgrade.effect.producerBoost && (
                        <div className="mt-1 text-xs">
                          <span className="text-cyan-400">Boosts: </span>
                          {Object.entries(upgrade.effect.producerBoost).map(([producer, amount], index, arr) => {
                            const producerName = gameState.producers[producer as ProducerType]?.name || producer;
                            return (
                              <span key={producer}>
                                <span className="text-yellow-300">
                                  {producerName}: +{(Number(amount) * 100).toFixed(0)}%
                                </span>
                                {index < arr.length - 1 ? ', ' : ''}
                              </span>
                            );
                          })}
                        </div>
                      )}
                      {formatUnlocks(upgrade)}
                    </>
                  )}
                </div>
                
                <div className="w-2/5 text-right">
                  {upgrade.purchased ? (
                    <div className="flex items-center justify-end">
                      <div className="text-green-400 text-xs mr-2">
                        {isCompact ? '✓' : 'Purchased'}
                      </div>
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4"
                          checked={upgrade.enabled}
                          onChange={() => onToggleUpgrade(upgrade.id)}
                        />
                      </label>
                    </div>
                  ) : (
                    formatCost(upgrade.cost)
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UpgradePanel;