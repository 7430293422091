import React, { useState } from 'react';
import { GamePhase } from '../types/GameTypes';

interface PhaseDisplayProps {
  phase: GamePhase;
  isCompact?: boolean; // New prop to control compact mode
}

const PhaseDisplay: React.FC<PhaseDisplayProps> = ({ phase, isCompact = false }) => {
  const [showDetails, setShowDetails] = useState(false);

  // Define descriptions for each phase
  const phaseDescriptions: Record<GamePhase, { title: string; description: string }> = {
    [GamePhase.ASTEROID_MINING]: {
      title: 'Asteroid Mining',
      description: 'Your journey begins on a small asteroid. Mine minerals and develop your outpost.'
    },
    [GamePhase.SOLAR_SYSTEM]: {
      title: 'Solar System Expansion',
      description: 'Expand throughout your solar system, harvesting resources from multiple celestial bodies.'
    },
    [GamePhase.INTERSTELLAR]: {
      title: 'Interstellar Expansion',
      description: 'Venture beyond your solar system to explore and exploit resources from distant stars.'
    },
    [GamePhase.GALACTIC_TRANSCENDENCE]: {
      title: 'Galactic Transcendence',
      description: 'Harness the power of the universe itself and transcend physical limitations.'
    }
  };

  // Get current phase info
  const phaseInfo = phaseDescriptions[phase];
  
  // Get phase number (1-4)
  const phaseNumber = Object.values(GamePhase).indexOf(phase) + 1;

  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      {/* Phase title - always visible but more compact on mobile */}
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-bold text-cyan-300">
          Phase {phaseNumber}: {phaseInfo.title}
        </h2>
        
        {/* Toggle button for mobile */}
        {isCompact && (
          <button 
            className="text-xs px-2 py-1 bg-gray-700 rounded"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? 'Hide' : 'Info'}
          </button>
        )}
      </div>
      
      {/* Description - hidden on mobile unless toggled */}
      {(!isCompact || showDetails) && (
        <p className="text-gray-300 mt-2 mb-3">{phaseInfo.description}</p>
      )}
      
      {/* Progress bar - always visible but more compact on mobile */}
      <div className={`${isCompact ? 'mt-2' : 'mt-3'} relative h-2 bg-gray-700 rounded overflow-hidden`}>
        <div 
          className="absolute top-0 left-0 h-full bg-cyan-500"
          style={{ width: `${phaseNumber * 25}%` }}
        />
      </div>
      
      {/* Progress markers - simplified on mobile */}
      {isCompact ? (
        <div className="flex justify-between text-xs text-gray-400 mt-1">
          <span>P1</span>
          <span>P2</span>
          <span>P3</span>
          <span>P4</span>
        </div>
      ) : (
        <div className="flex justify-between text-xs text-gray-400 mt-1">
          <span>Beginning</span>
          <span>Phase 1</span>
          <span>Phase 2</span>
          <span>Phase 3</span>
          <span>Phase 4</span>
        </div>
      )}
    </div>
  );
};

export default PhaseDisplay;