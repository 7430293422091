import { ProducerState } from "./ProducerTypes";

export enum GamePhase {
  ASTEROID_MINING = 'ASTEROID_MINING',
  SOLAR_SYSTEM = 'SOLAR_SYSTEM',
  INTERSTELLAR = 'INTERSTELLAR',
  GALACTIC_TRANSCENDENCE = 'GALACTIC_TRANSCENDENCE'
}

export interface GameState {
  phase: GamePhase;
  tick: number;
  lastSaved: number;
  resources: ResourceState;
  upgrades: UpgradeState;
  producers: ProducerState;
  investments: InvestmentState;
  eventLog: string[];
  unlocks: UnlockState;
  
  // New properties for the additional panels
  research?: {
    [areaId: string]: number;
  };
  exploration?: {
    activeExpeditions: {
      destinationId: string;
      startTime: number;
      endTime: number;
    }[];
    completedExpeditions: {
      destinationId: string;
      rewards: Record<string, number>;
    }[];
    discoveredLocations: string[];
  };
  colonies?: {
    id: string;
    name: string;
    location: string;
    population: number;
    populationCap: number;
    populationGrowth: number;
    habitability: number;
    specialResource: string;
    buildings: Record<string, number>;
    resources: Record<string, number>;
    resourceProduction: Record<string, number>;
    resourceConsumption: Record<string, number>;
    environment: string;
  }[];
  ships?: {
    [shipType: string]: number;
  };
  fleets?: {
    id: string;
    name: string;
    ships: Record<string, number>;
    currentAssignment?: string;
    powerRating: number;
    location: string;
  }[];
  diplomacy?: {
    civilizations: {
      id: string;
      name: string;
      attitude: number;
      relationshipStatus: string;
      tradeStatus: string;
      lastContact: number;
    }[];
    relations: {
      [civilizationId: string]: number;
    };
    tradeAgreements: {
      civilizationId: string;
      give: Record<string, number>;
      receive: Record<string, number>;
      startTime: number;
      duration: number;
    }[];
  };
  realityManipulation?: {
    constants: {
      [constantId: string]: number;
    };
    stability: number;
    anomalies: {
      id: string;
      active: boolean;
      startTime: number;
      duration: number;
    }[];
  };
}


export interface UnlockState {
  automationUnlocked: boolean;
  investmentsUnlocked: boolean;
  solarSystemUnlocked: boolean;
  interstellarUnlocked: boolean;
  transcendenceUnlocked: boolean;
  
  // New panel unlocks
  researchLabUnlocked: boolean;
  explorationUnlocked: boolean;
  colonyManagementUnlocked: boolean;
  spacefleetUnlocked: boolean;
  diplomacyUnlocked: boolean;
  realityManipulationUnlocked: boolean;
}

export interface ResourceState {
  minerals: number;
  energy: number;
  research: number;
  reputation: number;
  // Phase 2 resources
  hydrogen: number;
  helium: number;
  rareMetals: number;
  // Phase 3 resources
  antimatter: number;
  darkMatter: number;
  // Phase 4 resources
  cosmicInfluence: number;
}

export interface UpgradeState {
  [key: string]: Upgrade;
}

export interface Upgrade {
  id: string;
  name: string;
  description: string;
  cost: ResourceCost;
  purchased: boolean;
  enabled: boolean;
  visible: boolean;
  category: UpgradeCategory;
  effect: UpgradeEffect;
  requiredPhase: GamePhase;
  requiredUpgrades: string[];
}

export interface ResourceCost {
  minerals?: number;
  energy?: number;
  research?: number;
  reputation?: number;
  hydrogen?: number;
  helium?: number;
  rareMetals?: number;
  antimatter?: number;
  darkMatter?: number;
  cosmicInfluence?: number;
}

export enum UpgradeCategory {
  PRODUCTION = 'PRODUCTION',
  AUTOMATION = 'AUTOMATION',
  EFFICIENCY = 'EFFICIENCY',
  EXPANSION = 'EXPANSION',
  RESEARCH = 'RESEARCH'
}

// Extended UpgradeEffect interface in GameTypes.ts
export interface UpgradeEffect {
  resourceMultipliers?: {
    [key: string]: number;
  };
  resourceProduction?: {
    [key: string]: number;
  };
  producerBoost?: {
    [key: string]: number; // ProducerType -> boost percentage (0.25 = 25% boost)
  };
  unlocks?: string[]; // List of upgrades, features (automation, investments), or producers to unlock
}

export interface InvestmentState {
  sectors: { [key: string]: InvestmentSector };
  cash: number;
  portfolioValue: number;
  marketTrends: MarketTrends;
  lastUpdate: number;
}

export interface InvestmentSector {
  id: string;
  name: string;
  description: string;
  price: number;
  shares: number;
  volatility: number;
  growth: number;
  visible: boolean;
  unlocked: boolean;
  trend: 'up' | 'down' | 'stable';
  history: number[];
}

export interface MarketTrends {
  bull: number;  // 0-1 value indicating bull market strength
  sectors: {
    [key: string]: number;  // Sector-specific trends
  };
  trendDuration: number;  // Duration of current trend in ticks
}

export interface SaveData {
  gameState: GameState;
  version: string;
  timestamp: number;
}
