import React, { useEffect, useRef, useState } from 'react';

interface EventLogProps {
  events: string[];
}

const EventLog: React.FC<EventLogProps> = ({ events }) => {
  const logEndRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  // Auto-scroll to bottom when new events are added and expanded
  useEffect(() => {
    if (logEndRef.current && expanded) {
      logEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [events, expanded]);

  const eventCount = events.length;
  const latestEvent = events.length > 0 ? events[events.length - 1] : "No events yet";

  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <div 
        className="flex justify-between items-center mb-2 border-b border-cyan-800 pb-2 cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <h2 className="text-lg font-bold">Event Log {eventCount > 0 && `(${eventCount})`}</h2>
        <span>{expanded ? '▼' : '►'}</span>
      </div>
      
      {!expanded ? (
        <div className="text-gray-300 text-sm">
          <span className="text-cyan-400">Latest: </span>
          {latestEvent}
        </div>
      ) : (
        <div className="h-40 overflow-y-auto text-sm">
          {events.length === 0 ? (
            <div className="text-gray-400 italic">No events yet</div>
          ) : (
            <div className="space-y-1">
              {events.slice().reverse().map((event, index) => (
                <div key={index} className="text-gray-300">
                  <span className="text-cyan-400">&#9654; </span>
                  {event}
                </div>
              ))}
              <div ref={logEndRef} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventLog;