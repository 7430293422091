// src/components/ColonyManagementPanel.tsx
import React, { useState } from 'react';
import { GameState } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber } from '../utils/formatters';
import CollapsibleSection from './CollapsibleSection';

interface ColonyManagementPanelProps {
  gameState: GameState;
  onManageColony: (colonyId: string, action: string, parameter?: any) => void;
  isCompact?: boolean;
}

// Interfaces for colony-related data
interface ColonyBuilding {
  id: string;
  name: string;
  description: string;
  cost: Record<string, number>;
  maintenance: Record<string, number>;
  output: Record<string, number>;
  maxCount: number;
  requiredPopulation: number;
}

interface Colony {
  id: string;
  name: string;
  location: string;
  population: number;
  populationCap: number;
  populationGrowth: number;
  habitability: number;
  specialResource: string;
  buildings: Record<string, number>;
  resources: Record<string, number>;
  resourceProduction: Record<string, number>;
  resourceConsumption: Record<string, number>;
  environment: 'rocky' | 'gas' | 'oceanic' | 'toxic' | 'earthlike';
}

const ColonyManagementPanel: React.FC<ColonyManagementPanelProps> = ({
  gameState,
  onManageColony,
  isCompact = false
}) => {
  const [selectedColony, setSelectedColony] = useState<string | null>(null);
  const [selectedBuilding, setSelectedBuilding] = useState<string | null>(null);
  const [populationAction, setPopulationAction] = useState<'grow' | 'stabilize' | 'reduce'>('grow');
  const [expandColonyOpen, setExpandColonyOpen] = useState<boolean>(false);

  // Define available colony buildings
  const colonyBuildings: ColonyBuilding[] = [
    {
      id: 'habitat-dome',
      name: 'Habitat Dome',
      description: 'Basic living quarters for colonists. Increases population capacity.',
      cost: {
        [ResourceType.MINERALS]: 500,
        [ResourceType.ENERGY]: 200
      },
      maintenance: {
        [ResourceType.ENERGY]: 2
      },
      output: {
        populationCap: 10
      },
      maxCount: 10,
      requiredPopulation: 0
    },
    {
      id: 'mineral-extractor',
      name: 'Mineral Extractor',
      description: 'Automated equipment for extracting minerals from the colony\'s surface.',
      cost: {
        [ResourceType.MINERALS]: 800,
        [ResourceType.ENERGY]: 300,
        [ResourceType.RARE_METALS]: 20
      },
      maintenance: {
        [ResourceType.ENERGY]: 3
      },
      output: {
        [ResourceType.MINERALS]: 5
      },
      maxCount: 5,
      requiredPopulation: 5
    },
    {
      id: 'research-facility',
      name: 'Research Facility',
      description: 'Scientific facilities for conducting research in specialized environments.',
      cost: {
        [ResourceType.MINERALS]: 1200,
        [ResourceType.ENERGY]: 500,
        [ResourceType.RARE_METALS]: 50
      },
      maintenance: {
        [ResourceType.ENERGY]: 5,
        [ResourceType.MINERALS]: 1
      },
      output: {
        [ResourceType.RESEARCH]: 3
      },
      maxCount: 3,
      requiredPopulation: 10
    },
    {
      id: 'power-plant',
      name: 'Power Plant',
      description: 'Generates energy for the colony using local resources.',
      cost: {
        [ResourceType.MINERALS]: 1000,
        [ResourceType.RARE_METALS]: 30
      },
      maintenance: {
        [ResourceType.HYDROGEN]: 1
      },
      output: {
        [ResourceType.ENERGY]: 8
      },
      maxCount: 3,
      requiredPopulation: 5
    },
    {
      id: 'trading-hub',
      name: 'Trading Hub',
      description: 'Facilitates trade with other colonies and generates reputation.',
      cost: {
        [ResourceType.MINERALS]: 1500,
        [ResourceType.ENERGY]: 600,
        [ResourceType.RARE_METALS]: 60
      },
      maintenance: {
        [ResourceType.ENERGY]: 4
      },
      output: {
        [ResourceType.REPUTATION]: 0.2
      },
      maxCount: 2,
      requiredPopulation: 15
    }
  ];

  // Mock colonies data (would normally come from game state)
  const colonies: Colony[] = [
    {
      id: 'lunar-outpost',
      name: 'Lunar Outpost',
      location: 'Earth\'s Moon',
      population: 25,
      populationCap: 50,
      populationGrowth: 0.1,
      habitability: 0.4,
      specialResource: ResourceType.RARE_METALS,
      buildings: {
        'habitat-dome': 3,
        'mineral-extractor': 2,
        'power-plant': 1
      },
      resources: {
        [ResourceType.MINERALS]: 500,
        [ResourceType.ENERGY]: 200,
        [ResourceType.RARE_METALS]: 50
      },
      resourceProduction: {
        [ResourceType.MINERALS]: 10,
        [ResourceType.ENERGY]: 8,
        [ResourceType.RARE_METALS]: 1
      },
      resourceConsumption: {
        [ResourceType.ENERGY]: 6,
        [ResourceType.HYDROGEN]: 1
      },
      environment: 'rocky'
    },
    {
      id: 'mars-settlement',
      name: 'Mars Settlement',
      location: 'Mars',
      population: 15,
      populationCap: 30,
      populationGrowth: 0.05,
      habitability: 0.3,
      specialResource: ResourceType.HYDROGEN,
      buildings: {
        'habitat-dome': 2,
        'mineral-extractor': 1
      },
      resources: {
        [ResourceType.MINERALS]: 300,
        [ResourceType.ENERGY]: 150,
        [ResourceType.HYDROGEN]: 50
      },
      resourceProduction: {
        [ResourceType.MINERALS]: 5,
        [ResourceType.HYDROGEN]: 2
      },
      resourceConsumption: {
        [ResourceType.ENERGY]: 3
      },
      environment: 'rocky'
    }
  ];

  // Available colony locations to establish new colonies
  const availableLocations = [
    {
      id: 'venus-cloud',
      name: 'Venus Cloud City',
      description: 'A floating habitat in the upper atmosphere of Venus.',
      cost: {
        [ResourceType.MINERALS]: 5000,
        [ResourceType.ENERGY]: 3000,
        [ResourceType.RARE_METALS]: 200,
        [ResourceType.HYDROGEN]: 100
      },
      environment: 'toxic',
      specialResource: ResourceType.HELIUM,
      habitability: 0.2
    },
    {
      id: 'europa-underwater',
      name: 'Europa Underwater Base',
      description: 'A research facility beneath the ice of Jupiter\'s moon Europa.',
      cost: {
        [ResourceType.MINERALS]: 8000,
        [ResourceType.ENERGY]: 4000,
        [ResourceType.RARE_METALS]: 300,
        [ResourceType.HYDROGEN]: 200
      },
      environment: 'oceanic',
      specialResource: ResourceType.RESEARCH,
      habitability: 0.3
    },
    {
      id: 'titan-methane',
      name: 'Titan Methane Extraction',
      description: 'A resource extraction facility on Saturn\'s largest moon.',
      cost: {
        [ResourceType.MINERALS]: 10000,
        [ResourceType.ENERGY]: 5000,
        [ResourceType.RARE_METALS]: 400,
        [ResourceType.HYDROGEN]: 300
      },
      environment: 'toxic',
      specialResource: ResourceType.HYDROGEN,
      habitability: 0.25
    }
  ];

  // Find the selected colony
  const selectedColonyData = colonies.find(colony => colony.id === selectedColony);

  // Find the selected building
  const selectedBuildingData = colonyBuildings.find(building => building.id === selectedBuilding);

  // Calculate if player can afford to construct a building
  const canAffordBuilding = (building: ColonyBuilding): boolean => {
    return Object.entries(building.cost).every(
      ([resource, amount]) => (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
    );
  };

  // Calculate if a colony has room for a building
  const canBuildBuilding = (colony: Colony, buildingId: string): boolean => {
    const building = colonyBuildings.find(b => b.id === buildingId);
    if (!building) return false;

    const currentCount = colony.buildings[buildingId] || 0;
    return currentCount < building.maxCount && colony.population >= building.requiredPopulation;
  };

  // Calculate if player can afford to establish a new colony
  const canAffordColony = (locationIndex: number): boolean => {
    const location = availableLocations[locationIndex];
    return Object.entries(location.cost).every(
      ([resource, amount]) => (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
    );
  };

  // Handle constructing a building
  const handleConstructBuilding = () => {
    if (selectedColony && selectedBuilding && selectedColonyData) {
      const building = colonyBuildings.find(b => b.id === selectedBuilding);
      if (building && canAffordBuilding(building) && canBuildBuilding(selectedColonyData, selectedBuilding)) {
        onManageColony(selectedColony, 'constructBuilding', selectedBuilding);
      }
    }
  };

  // Handle sending colonists
  const handleSendColonists = (amount: number) => {
    if (selectedColony) {
      onManageColony(selectedColony, 'sendColonists', amount);
    }
  };

  // Handle setting population policy
  const handleSetPopulationPolicy = () => {
    if (selectedColony) {
      onManageColony(selectedColony, 'setPopulationPolicy', populationAction);
    }
  };

  // Handle establishing a new colony
  const handleEstablishColony = (locationIndex: number) => {
    const location = availableLocations[locationIndex];
    if (canAffordColony(locationIndex)) {
      onManageColony('new', 'establishColony', location.id);
      setExpandColonyOpen(false);
    }
  };

  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 border-b border-cyan-800 pb-1">Colony Management</h2>

      {/* Colony selection and expansion */}
      <div className="mb-3">
        <div className="flex justify-between items-center mb-2">
          <h3 className="font-bold">Your Colonies ({colonies.length})</h3>
          <button
            className="px-2 py-1 bg-green-700 hover:bg-green-600 rounded text-sm"
            onClick={() => setExpandColonyOpen(!expandColonyOpen)}
          >
            {expandColonyOpen ? 'Hide' : 'Establish New Colony'}
          </button>
        </div>

        {/* Colony expansion interface */}
        {expandColonyOpen && (
          <div className="mb-3 p-3 bg-gray-700 rounded">
            <h4 className="font-bold mb-2">Establish New Colony</h4>
            <div className="space-y-2">
              {availableLocations.map((location, index) => (
                <div key={location.id} className="p-2 bg-gray-800 rounded">
                  <div className="flex justify-between items-start">
                    <div>
                      <div className="font-bold">{location.name}</div>
                      {!isCompact && (
                        <div className="text-xs text-gray-300 mb-1">{location.description}</div>
                      )}
                      <div className="text-xs">
                        <span className="text-green-300">Type: {location.environment}</span> | 
                        <span className="text-blue-300"> Habitability: {(location.habitability * 100).toFixed(0)}%</span>
                      </div>
                    </div>
                    <button
                      className="px-2 py-1 bg-green-700 hover:bg-green-600 disabled:bg-gray-600 disabled:opacity-50 rounded text-xs"
                      onClick={() => handleEstablishColony(index)}
                      disabled={!canAffordColony(index)}
                    >
                      Establish
                    </button>
                  </div>
                  <div className="mt-1 text-xs">
                    <div className="font-bold">Cost:</div>
                    <div className="flex flex-wrap gap-1">
                      {Object.entries(location.cost).map(([resource, amount]) => (
                        <div 
                          key={resource} 
                          className={`${
                            (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
                              ? 'text-green-400'
                              : 'text-red-400'
                          }`}
                        >
                          {resource}: {formatNumber(amount)}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Colony selection grid */}
        <div className={`grid ${isCompact ? 'grid-cols-1 gap-1' : 'grid-cols-2 gap-2'}`}>
          {colonies.map(colony => (
            <button
              key={colony.id}
              className={`p-2 rounded text-sm text-left ${
                selectedColony === colony.id 
                  ? 'bg-green-800 border border-green-600' 
                  : 'bg-gray-700 hover:bg-gray-600'
              }`}
              onClick={() => setSelectedColony(colony.id)}
            >
              <div className="font-bold">{colony.name}</div>
              <div className="text-xs text-gray-300">Location: {colony.location}</div>
              <div className="text-xs">
                <span className="text-blue-300">Population: {colony.population}/{colony.populationCap}</span>
              </div>
              <div className="text-xs">
                <span className={`${
                  Object.values(colony.resourceProduction).reduce((a, b) => a + b, 0) > 
                  Object.values(colony.resourceConsumption).reduce((a, b) => a + b, 0)
                    ? 'text-green-400'
                    : 'text-yellow-400'
                }`}>
                  Buildings: {Object.values(colony.buildings).reduce((a, b) => a + b, 0)}
                </span>
              </div>
            </button>
          ))}
        </div>
      </div>

      {/* Selected colony details */}
      {selectedColonyData && (
        <div className="p-3 bg-gray-700 rounded mb-3">
          <h3 className="font-bold">{selectedColonyData.name}</h3>

          {/* Colony overview stats - simplified in compact mode */}
          <div className="grid grid-cols-2 gap-2 mb-2">
            <div>
              <div className="text-xs font-bold">Population:</div>
              <div className="text-blue-300">
                {selectedColonyData.population}/{selectedColonyData.populationCap}
              </div>
            </div>
            <div>
              <div className="text-xs font-bold">Growth:</div>
              <div className={`${
                selectedColonyData.populationGrowth > 0 
                  ? 'text-green-400' 
                  : selectedColonyData.populationGrowth < 0 
                    ? 'text-red-400' 
                    : 'text-yellow-400'
              }`}>
                {(selectedColonyData.populationGrowth * 100).toFixed(1)}%/cycle
              </div>
            </div>
            <div>
              <div className="text-xs font-bold">Environment:</div>
              <div className="text-green-300">{selectedColonyData.environment}</div>
            </div>
            <div>
              <div className="text-xs font-bold">Habitability:</div>
              <div className="text-blue-300">{(selectedColonyData.habitability * 100).toFixed(0)}%</div>
            </div>
          </div>

          {/* Colony resources and production/consumption - in collapsible in compact mode */}
          {isCompact ? (
            <CollapsibleSection title="Resource Balance" isOpen={false}>
              <div className="grid grid-cols-2 gap-1 text-xs">
                {Object.entries(selectedColonyData.resourceProduction).map(([resource, amount]) => (
                  <div key={`prod-${resource}`}>
                    {resource}: <span className="text-green-400">+{formatNumber(amount)}</span>
                  </div>
                ))}
                {Object.entries(selectedColonyData.resourceConsumption).map(([resource, amount]) => (
                  <div key={`cons-${resource}`}>
                    {resource}: <span className="text-red-400">-{formatNumber(amount)}</span>
                  </div>
                ))}
              </div>
            </CollapsibleSection>
          ) : (
            <div className="mb-3">
              <div className="text-xs font-bold mb-1">Resource Balance:</div>
              <div className="grid grid-cols-3 gap-2 text-xs">
                {Object.entries(selectedColonyData.resourceProduction).map(([resource, amount]) => (
                  <div key={`prod-${resource}`}>
                    {resource}: <span className="text-green-400">+{formatNumber(amount)}</span>
                  </div>
                ))}
                {Object.entries(selectedColonyData.resourceConsumption).map(([resource, amount]) => (
                  <div key={`cons-${resource}`}>
                    {resource}: <span className="text-red-400">-{formatNumber(amount)}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Population management controls */}
          <div className="mb-3">
            <div className="text-xs font-bold mb-1">Population Management:</div>
            <div className="flex gap-2 mb-2">
              <button
                className="px-2 py-1 bg-blue-700 hover:bg-blue-600 rounded text-xs"
                onClick={() => handleSendColonists(5)}
              >
                Send 5 Colonists
              </button>
              <button
                className="px-2 py-1 bg-blue-700 hover:bg-blue-600 rounded text-xs"
                onClick={() => handleSendColonists(10)}
              >
                Send 10 Colonists
              </button>
            </div>
            <div className="flex gap-2">
              <select
                className="bg-gray-800 border border-gray-600 rounded px-2 py-1 text-xs"
                value={populationAction}
                onChange={(e) => setPopulationAction(e.target.value as 'grow' | 'stabilize' | 'reduce')}
              >
                <option value="grow">Growing (Faster)</option>
                <option value="stabilize">Stable (Balanced)</option>
                <option value="reduce">Reducing (Resources)</option>
              </select>
              <button
                className="px-2 py-1 bg-blue-700 hover:bg-blue-600 rounded text-xs"
                onClick={handleSetPopulationPolicy}
              >
                Set Policy
              </button>
            </div>
          </div>

          {/* Existing buildings */}
          <div className="mb-3">
            <div className="text-xs font-bold mb-1">Buildings:</div>
            <div className="grid grid-cols-2 gap-1">
              {Object.entries(selectedColonyData.buildings).map(([buildingId, count]) => {
                const building = colonyBuildings.find(b => b.id === buildingId);
                if (!building) return null;
                return (
                  <div key={buildingId} className="text-xs">
                    {building.name}: <span className="text-blue-300">{count}/{building.maxCount}</span>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Building construction */}
          <div>
            <div className="text-xs font-bold mb-1">Construct New Building:</div>
            <div className="grid grid-cols-1 gap-1 mb-2">
              {colonyBuildings.map(building => (
                <button
                  key={building.id}
                  className={`p-1 rounded text-xs text-left ${
                    selectedBuilding === building.id 
                      ? 'bg-blue-800 border border-blue-600' 
                      : !canBuildBuilding(selectedColonyData, building.id)
                        ? 'bg-gray-800 opacity-50 cursor-not-allowed'
                        : 'bg-gray-800 hover:bg-gray-700'
                  }`}
                  onClick={() => canBuildBuilding(selectedColonyData, building.id) && setSelectedBuilding(building.id)}
                  disabled={!canBuildBuilding(selectedColonyData, building.id)}
                >
                  <div className="font-bold">{building.name}</div>
                  <div className="flex justify-between">
                    <span>
                      {selectedColonyData.buildings[building.id] || 0}/{building.maxCount}
                    </span>
                    <span>
                      Requires: {building.requiredPopulation} pop
                    </span>
                  </div>
                </button>
              ))}
            </div>

            {selectedBuildingData && (
              <div className="p-2 bg-gray-800 rounded">
                <div className="text-xs">
                  <div className="font-bold">{selectedBuildingData.name}</div>
                  {!isCompact && (
                    <div className="text-gray-300 mb-1">{selectedBuildingData.description}</div>
                  )}
                  <div className="grid grid-cols-2 gap-1">
                    <div>
                      <div className="font-bold">Cost:</div>
                      {Object.entries(selectedBuildingData.cost).map(([resource, amount]) => (
                        <div 
                          key={resource}
                          className={`${
                            (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
                              ? 'text-green-400'
                              : 'text-red-400'
                          }`}
                        >
                          {resource}: {formatNumber(amount)}
                        </div>
                      ))}
                    </div>
                    <div>
                      <div className="font-bold">Output:</div>
                      {Object.entries(selectedBuildingData.output).map(([resource, amount]) => (
                        <div key={resource} className="text-green-400">
                          {resource}: +{formatNumber(amount)}
                        </div>
                      ))}
                      {Object.keys(selectedBuildingData.maintenance).length > 0 && (
                        <>
                          <div className="font-bold mt-1">Maintenance:</div>
                          {Object.entries(selectedBuildingData.maintenance).map(([resource, amount]) => (
                            <div key={resource} className="text-red-400">
                              {resource}: -{formatNumber(amount)}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="w-full mt-2 px-2 py-1 bg-blue-700 hover:bg-blue-600 disabled:bg-gray-600 disabled:opacity-50 rounded text-xs"
                  onClick={handleConstructBuilding}
                  disabled={!canAffordBuilding(selectedBuildingData)}
                >
                  Construct Building
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Colony management tips - only in non-compact mode */}
      {!isCompact && (
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-bold mb-1">Colony Management Tips</h3>
          <div className="text-sm text-gray-300">
            <p>Efficient colony management is critical for your space empire:</p>
            <ul className="list-disc list-inside mt-1 text-xs">
              <li>Balance population growth with available resources</li>
              <li>Prioritize buildings based on colony's special resources</li>
              <li>Different environments offer different challenges and opportunities</li>
              <li>Trading hubs can significantly boost your reputation</li>
              <li>Keep energy production higher than consumption</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColonyManagementPanel;