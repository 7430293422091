import { InvestmentSector } from '../types/GameTypes';
import { SectorType, SectorData } from '../types/InvestmentTypes';

// Define sector data with rebalanced values
export const sectorData: SectorData[] = [
  {
    id: SectorType.ASTEROID_MINING,
    name: 'Asteroid Mining Corp',
    description: 'Leading asteroid mining operations across the belt',
    basePrice: 10,
    baseVolatility: 0.04, // Reduced from 0.05 for more stability
    baseGrowth: 0.025, // Increased from 0.02 for better returns
    phaseRequired: 1,
    unlockCost: {
      research: 0,
      reputation: 0
    }
  },
  {
    id: SectorType.ORBITAL_CONSTRUCTION,
    name: 'Orbital Fabrication',
    description: 'Space-based construction and manufacturing',
    basePrice: 15,
    baseVolatility: 0.06, // Reduced from 0.07
    baseGrowth: 0.035, // Increased from 0.03
    phaseRequired: 1,
    unlockCost: {
      research: 250, // Reduced from 300
      reputation: 4 // Reduced from 5
    }
  },
  {
    id: SectorType.PROPULSION,
    name: 'Advanced Propulsion',
    description: 'Developing next-gen propulsion systems',
    basePrice: 20,
    baseVolatility: 0.09, // Reduced from 0.1
    baseGrowth: 0.045, // Increased from 0.04
    phaseRequired: 1,
    unlockCost: {
      research: 400, // Reduced from 500
      reputation: 8 // Reduced from 10
    }
  },
  {
    id: SectorType.AI_RESEARCH,
    name: 'AI Systems',
    description: 'Artificial intelligence for space applications',
    basePrice: 25,
    baseVolatility: 0.11, // Reduced from 0.12
    baseGrowth: 0.055, // Increased from 0.05
    phaseRequired: 1,
    unlockCost: {
      research: 650, // Reduced from 800
      reputation: 12 // Reduced from 15
    }
  },
  {
    id: SectorType.SOLAR_ENERGY,
    name: 'Solar Harvesting',
    description: 'Advanced solar energy collection systems',
    basePrice: 30,
    baseVolatility: 0.07, // Reduced from 0.08
    baseGrowth: 0.045, // Increased from 0.04
    phaseRequired: 1,
    unlockCost: {
      research: 800, // Reduced from 1000
      reputation: 16 // Reduced from 20
    }
  },
  {
    id: SectorType.LUNAR_OPERATIONS,
    name: 'Lunar Industries',
    description: 'Mining and manufacturing on Luna',
    basePrice: 40,
    baseVolatility: 0.08, // Reduced from 0.09
    baseGrowth: 0.055, // Increased from 0.05
    phaseRequired: 2,
    unlockCost: {
      research: 1600, // Reduced from 2000
      reputation: 25 // Reduced from 30
    }
  },
  {
    id: SectorType.MARTIAN_COLONIES,
    name: 'Mars Colonization',
    description: 'Developing settlements on Mars',
    basePrice: 50,
    baseVolatility: 0.10, // Reduced from 0.11
    baseGrowth: 0.065, // Increased from 0.06
    phaseRequired: 2,
    unlockCost: {
      research: 2400, // Reduced from 3000
      reputation: 35 // Reduced from 40
    }
  },
  {
    id: SectorType.GAS_HARVESTING,
    name: 'Gas Giant Resources',
    description: 'Harvesting resources from gas giants',
    basePrice: 60,
    baseVolatility: 0.12, // Reduced from 0.13
    baseGrowth: 0.075, // Increased from 0.07
    phaseRequired: 2,
    unlockCost: {
      research: 3200, // Reduced from 4000
      reputation: 45 // Reduced from 50
    }
  },
  {
    id: SectorType.QUANTUM_COMPUTING,
    name: 'Quantum Systems',
    description: 'Quantum computing for deep space applications',
    basePrice: 80,
    baseVolatility: 0.14, // Reduced from 0.15
    baseGrowth: 0.085, // Increased from 0.08
    phaseRequired: 2,
    unlockCost: {
      research: 4000, // Reduced from 5000
      reputation: 50 // Reduced from 60
    }
  },
  {
    id: SectorType.INTERSTELLAR_TRANSPORT,
    name: 'Interstellar Shipping',
    description: 'Transportation between star systems',
    basePrice: 100,
    baseVolatility: 0.16, // Reduced from 0.17
    baseGrowth: 0.095, // Increased from 0.09
    phaseRequired: 3,
    unlockCost: {
      research: 8000, // Reduced from 10000
      reputation: 80 // Reduced from 100
    }
  },
  {
    id: SectorType.EXOPLANET_DEVELOPMENT,
    name: 'Exoplanet Terraforming',
    description: 'Making exoplanets habitable for humans',
    basePrice: 150,
    baseVolatility: 0.18, // Reduced from 0.2
    baseGrowth: 0.11, // Increased from 0.1
    phaseRequired: 3,
    unlockCost: {
      research: 12000, // Reduced from 15000
      reputation: 120 // Reduced from 150
    }
  },
  {
    id: SectorType.DYSON_STRUCTURES,
    name: 'Dyson Engineering',
    description: 'Building megastructures around stars',
    basePrice: 200,
    baseVolatility: 0.22, // Reduced from 0.25
    baseGrowth: 0.13, // Increased from 0.12
    phaseRequired: 3,
    unlockCost: {
      research: 16000, // Reduced from 20000
      reputation: 160 // Reduced from 200
    }
  },
  {
    id: SectorType.WORMHOLE_RESEARCH,
    name: 'Wormhole Technologies',
    description: 'Researching wormhole creation and stabilization',
    basePrice: 300,
    baseVolatility: 0.26, // Reduced from 0.3
    baseGrowth: 0.16, // Increased from 0.15
    phaseRequired: 3,
    unlockCost: {
      research: 24000, // Reduced from 30000
      reputation: 250 // Reduced from 300
    }
  },
  {
    id: SectorType.COSMIC_MANIPULATION,
    name: 'Cosmic Manipulation',
    description: 'Technologies to manipulate the fabric of spacetime',
    basePrice: 500,
    baseVolatility: 0.35, // Reduced from 0.4
    baseGrowth: 0.22, // Increased from 0.2
    phaseRequired: 4,
    unlockCost: {
      research: 40000, // Reduced from 50000
      reputation: 400 // Reduced from 500
    }
  }
];

// Initialize investment sectors
export const initialSectors: { [key: string]: InvestmentSector } = Object.fromEntries(
  sectorData.map(sector => [
    sector.id,
    {
      id: sector.id,
      name: sector.name,
      description: sector.description,
      price: sector.basePrice,
      shares: 0,
      volatility: sector.baseVolatility,
      growth: sector.baseGrowth,
      visible: sector.phaseRequired === 1,
      unlocked: sector.id === SectorType.ASTEROID_MINING, // Only first sector is unlocked initially
      trend: 'stable',
      history: [sector.basePrice]
    }
  ])
);

// Function to unlock a new sector - adjusted unlock costs
export const unlockSector = (
  sectors: { [key: string]: InvestmentSector }, 
  sectorId: string, 
  research: number, 
  reputation: number
): { 
  sectors: { [key: string]: InvestmentSector }, 
  success: boolean, 
  researchCost: number, 
  reputationCost: number 
} => {
  const sector = sectors[sectorId];
  if (!sector || sector.unlocked) {
    return { sectors, success: false, researchCost: 0, reputationCost: 0 };
  }
  
  const sectorInfo = sectorData.find(s => s.id === sectorId);
  if (!sectorInfo) {
    return { sectors, success: false, researchCost: 0, reputationCost: 0 };
  }
  
  const { research: researchCost, reputation: reputationCost } = sectorInfo.unlockCost;
  
  if (research < researchCost || reputation < reputationCost) {
    return { 
      sectors, 
      success: false, 
      researchCost: researchCost, 
      reputationCost: reputationCost 
    };
  }
  
  // Create a copy to avoid mutating the original
  const updatedSectors = { ...sectors };
  updatedSectors[sectorId] = {
    ...sector,
    unlocked: true
  };
  
  return { 
    sectors: updatedSectors, 
    success: true, 
    researchCost: researchCost, 
    reputationCost: reputationCost 
  };
};

// Calculate the current market trend (overall bull/bear)
export const calculateMarketTrend = (
  sectors: { [key: string]: InvestmentSector }
): 'bull' | 'bear' | 'neutral' => {
  const unlockedSectors = Object.values(sectors).filter(sector => sector.unlocked);
  
  if (unlockedSectors.length === 0) {
    return 'neutral';
  }
  
  const positiveCount = unlockedSectors.filter(sector => sector.trend === 'up').length;
  const negativeCount = unlockedSectors.filter(sector => sector.trend === 'down').length;
  
  if (positiveCount > negativeCount) {
    return 'bull';
  } else if (negativeCount > positiveCount) {
    return 'bear';
  } else {
    return 'neutral';
  }
};

// Get the list of visible sectors based on game phase - modified for better progression
export const getVisibleSectors = (
  sectors: { [key: string]: InvestmentSector }, 
  gamePhase: number
): InvestmentSector[] => {
  // Show one additional phase ahead to let players plan
  const visiblePhase = Math.min(4, gamePhase + 1);
  
  return Object.values(sectors)
    .filter(sector => {
      const phaseRequired = sectorData.find(s => s.id === sector.id)?.phaseRequired || 1;
      // Modified to show sectors for the next phase as "coming soon"
      return phaseRequired <= visiblePhase;
    })
    .sort((a, b) => {
      const phaseA = sectorData.find(s => s.id === a.id)?.phaseRequired || 1;
      const phaseB = sectorData.find(s => s.id === b.id)?.phaseRequired || 1;
      
      if (phaseA !== phaseB) {
        return phaseA - phaseB;
      }
      
      const baseA = sectorData.find(s => s.id === a.id)?.basePrice || 0;
      const baseB = sectorData.find(s => s.id === b.id)?.basePrice || 0;
      return baseA - baseB;
    });
};