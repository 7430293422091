import React, { useState } from 'react';
import { GameState, InvestmentSector } from '../types/GameTypes';
import { getVisibleSectors, calculateMarketTrend } from '../game/InvestmentSystem';
import { formatNumber, formatPercent, getValueColorClass } from '../utils/formatters';
import CollapsibleSection from './CollapsibleSection';

interface InvestmentPanelProps {
  gameState: GameState;
  onTransaction: (sectorId: string, shares: number, type: 'buy' | 'sell') => void;
  onConvertCash: (amount: number) => void;
  isCompact?: boolean;
}

const InvestmentPanel: React.FC<InvestmentPanelProps> = ({ 
  gameState, 
  onTransaction, 
  onConvertCash,
  isCompact = false
}) => {
  const [selectedSector, setSelectedSector] = useState<string | null>(null);
  const [transactionAmount, setTransactionAmount] = useState<number>(1);
  const [convertAmount, setConvertAmount] = useState<number>(100);
  
  // Get visible sectors based on game phase
  const phaseNumber = ['ASTEROID_MINING', 'SOLAR_SYSTEM', 'INTERSTELLAR', 'GALACTIC_TRANSCENDENCE']
    .indexOf(gameState.phase) + 1;
  
  const visibleSectors = getVisibleSectors(gameState.investments.sectors, phaseNumber);
  
  // Get the selected sector
  const sector = selectedSector ? gameState.investments.sectors[selectedSector] : null;
  
  // Calculate market trend
  const marketTrend = calculateMarketTrend(gameState.investments.sectors);
  
  // Calculate total transaction cost
  const transactionCost = sector ? sector.price * transactionAmount : 0;
  
  // Calculate portfolio total value
  const portfolioValue = gameState.investments.portfolioValue;
  const totalValue = gameState.investments.cash + portfolioValue;
  
  // Handle share input change
  const handleShareChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      setTransactionAmount(value);
    }
  };
  
  // Handle convert amount change
  const handleConvertChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      setConvertAmount(value);
    }
  };
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 border-b border-cyan-800 pb-1">Investments</h2>
      
      <div className="mb-3">
        {/* More compact portfolio summary for compact mode */}
        <div className="flex justify-between">
          <div>Cash: <span className="font-bold">{formatNumber(gameState.investments.cash)}</span></div>
          <div>Portfolio: <span className="font-bold">{formatNumber(portfolioValue)}</span></div>
        </div>
        <div className="flex justify-between">
          <div>Total: <span className="font-bold">{formatNumber(totalValue)}</span></div>
          <div>
            Market: 
            <span className={`ml-1 font-bold ${
              marketTrend === 'bull' ? 'text-green-400' : 
              marketTrend === 'bear' ? 'text-red-400' : 'text-gray-400'
            }`}>
              {marketTrend.toUpperCase()}
            </span>
          </div>
        </div>
      </div>
      
      {/* Available sectors section */}
      <div className="mb-3">
        <h3 className="font-bold mb-2">Available Sectors</h3>
        {/* Grid layout that's more compact on mobile */}
        <div className={`grid ${isCompact ? 'grid-cols-2 gap-1' : 'grid-cols-2 gap-2'}`}>
          {visibleSectors.map(sector => (
            <button
              key={sector.id}
              className={`p-2 rounded text-sm text-left ${
                sector.unlocked 
                  ? selectedSector === sector.id 
                    ? 'bg-cyan-800 border border-cyan-600' 
                    : 'bg-gray-700 hover:bg-gray-600'
                  : 'bg-gray-900 text-gray-600 cursor-not-allowed'
              }`}
              onClick={() => sector.unlocked && setSelectedSector(sector.id)}
              disabled={!sector.unlocked}
            >
              <div className="font-bold truncate">{sector.name}</div>
              {sector.unlocked ? (
                <div className="flex justify-between">
                  <span>{formatNumber(sector.price)}</span>
                  <span className={getValueColorClass(
                    sector.trend === 'up' ? 1 : sector.trend === 'down' ? -1 : 0
                  )}>
                    {sector.trend === 'up' ? '▲' : sector.trend === 'down' ? '▼' : '■'}
                  </span>
                </div>
              ) : (
                <div className="text-xs">Locked</div>
              )}
            </button>
          ))}
        </div>
      </div>
      
      {/* Selected sector details - simplified in compact mode */}
      {sector && (
        <div className="mb-3 p-3 bg-gray-700 rounded">
          <h3 className="font-bold">{sector.name}</h3>
          
          {/* Only show description in non-compact mode */}
          {!isCompact && (
            <p className="text-sm text-gray-300">{sector.description}</p>
          )}
          
          <div className={`grid grid-cols-2 gap-1 ${isCompact ? 'mt-1' : 'mt-2'}`}>
            <div>Price: <span className="font-bold">{formatNumber(sector.price)}</span></div>
            <div>Shares: <span className="font-bold">{sector.shares}</span></div>
            <div>Value: <span className="font-bold">{formatNumber(sector.shares * sector.price)}</span></div>
            <div>
              Trend: 
              <span className={`ml-1 font-bold ${
                sector.trend === 'up' ? 'text-green-400' : 
                sector.trend === 'down' ? 'text-red-400' : 'text-gray-400'
              }`}>
                {sector.trend === 'up' ? '▲' : sector.trend === 'down' ? '▼' : '■'}
              </span>
            </div>
          </div>
          
          {/* Transaction controls */}
          <div className="flex mt-3">
            <input
              type="number"
              min="1"
              value={transactionAmount}
              onChange={handleShareChange}
              className="w-20 px-2 py-1 bg-gray-800 border border-gray-600 rounded mr-2 text-white"
            />
            
            <button
              className="flex-1 bg-green-700 hover:bg-green-600 py-1 rounded mr-1 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => onTransaction(sector.id, transactionAmount, 'buy')}
              disabled={gameState.investments.cash < transactionCost}
            >
              Buy {isCompact ? '' : `(${formatNumber(transactionCost)})`}
            </button>
            
            <button
              className="flex-1 bg-red-700 hover:bg-red-600 py-1 rounded disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => onTransaction(sector.id, transactionAmount, 'sell')}
              disabled={sector.shares < transactionAmount}
            >
              Sell {isCompact ? '' : `(${formatNumber(transactionCost)})`}
            </button>
          </div>
          
          {/* Price history - simplified in compact mode */}
          {!isCompact && (
            <div className="mt-3 text-xs">
              <div className="mb-1">Price History:</div>
              <div className="h-12 flex items-end">
                {sector.history.map((price, i) => {
                  const maxPrice = Math.max(...sector.history);
                  const height = (price / maxPrice) * 100;
                  const color = i < sector.history.length - 1 
                    ? price < sector.history[i + 1] ? 'bg-red-500' : 'bg-green-500'
                    : 'bg-blue-500';
                  
                  return (
                    <div 
                      key={i} 
                      className={`flex-1 ${color}`} 
                      style={{ height: `${height}%` }}
                      title={`${formatNumber(price)}`}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
      
      {/* Convert cash section - keep same but add to collapsible in compact mode */}
      {isCompact ? (
        <CollapsibleSection title="Convert Cash to Reputation" isOpen={false}>
          <div className="flex mt-2">
            <input
              type="number"
              min="1"
              value={convertAmount}
              onChange={handleConvertChange}
              className="w-24 px-2 py-1 bg-gray-800 border border-gray-600 rounded mr-2 text-white"
            />
            
            <button
              className="flex-1 bg-purple-700 hover:bg-purple-600 py-1 rounded disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => onConvertCash(convertAmount)}
              disabled={gameState.investments.cash < convertAmount}
            >
              Convert to {formatNumber(convertAmount * 0.1)} Rep
            </button>
          </div>
        </CollapsibleSection>
      ) : (
        <div className="mt-3">
          <h3 className="font-bold mb-2">Convert Cash to Reputation</h3>
          <div className="flex">
            <input
              type="number"
              min="1"
              value={convertAmount}
              onChange={handleConvertChange}
              className="w-24 px-2 py-1 bg-gray-800 border border-gray-600 rounded mr-2 text-white"
            />
            
            <button
              className="flex-1 bg-purple-700 hover:bg-purple-600 py-1 rounded disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => onConvertCash(convertAmount)}
              disabled={gameState.investments.cash < convertAmount}
            >
              Convert to {formatNumber(convertAmount * 0.1)} Reputation
            </button>
          </div>
          <div className="text-xs text-gray-400 mt-1">
            Conversion rate: 10% (10 cash = 1 reputation)
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestmentPanel;