import { ResourceType } from './ResourceTypes';
import { GamePhase, ResourceCost } from './GameTypes';

export enum ProducerType {
  // Phase 1: Asteroid Mining
  MINERAL_MINER = 'mineralMiner',
  SOLAR_PANEL = 'solarPanel',
  RESEARCH_STATION = 'researchStation',
  
  // Phase 2: Solar System
  ORBITAL_MINER = 'orbitalMiner',
  GAS_HARVESTER = 'gasHarvester',
  FUSION_PLANT = 'fusionPlant',
  RARE_METAL_EXTRACTOR = 'rareMetalExtractor',
  
  // Phase 3: Interstellar
  STELLAR_EXTRACTOR = 'stellarExtractor',
  DYSON_NODE = 'dysonNode',
  ANTIMATTER_COLLECTOR = 'antimatterCollector',
  
  // Phase 4: Galactic Transcendence
  DARK_MATTER_HARVESTER = 'darkMatterHarvester',
  COSMIC_MANIPULATOR = 'cosmicManipulator'
}

export interface Producer {
  type: ProducerType;
  name: string;
  description: string;
  baseCost: ResourceCost;
  baseProduction: Record<ResourceType, number>;
  costMultiplier: number;
  count: number;
  unlocked: boolean;
  visible: boolean;
  requiredPhase: GamePhase;
  requiredProducers?: Partial<Record<ProducerType, number>>;
  automates?: ResourceType[];
}

export interface ProducerState {
  [ProducerType.MINERAL_MINER]: Producer;
  [ProducerType.SOLAR_PANEL]: Producer;
  [ProducerType.RESEARCH_STATION]: Producer;
  [ProducerType.ORBITAL_MINER]: Producer;
  [ProducerType.GAS_HARVESTER]: Producer;
  [ProducerType.FUSION_PLANT]: Producer;
  [ProducerType.RARE_METAL_EXTRACTOR]: Producer;
  [ProducerType.STELLAR_EXTRACTOR]: Producer;
  [ProducerType.DYSON_NODE]: Producer;
  [ProducerType.ANTIMATTER_COLLECTOR]: Producer;
  [ProducerType.DARK_MATTER_HARVESTER]: Producer;
  [ProducerType.COSMIC_MANIPULATOR]: Producer;
}