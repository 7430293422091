import React, { useState } from 'react';

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
  isOpen?: boolean;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({ 
  title, 
  children, 
  isOpen = false,
  className = '',
  titleClassName = '',
  contentClassName = ''
}) => {
  const [expanded, setExpanded] = useState(isOpen);

  return (
    <div className={`border border-gray-700 rounded ${className}`}>
      <div 
        className={`flex justify-between items-center p-2 cursor-pointer bg-gray-700 rounded-t ${titleClassName}`}
        onClick={() => setExpanded(!expanded)}
      >
        <h3 className="font-bold text-sm text-cyan-300">{title}</h3>
        <span>{expanded ? '▼' : '►'}</span>
      </div>
      
      {expanded && (
        <div className={`p-2 bg-gray-800 rounded-b ${contentClassName}`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default CollapsibleSection;