import React from 'react';

interface CompactModeToggleProps {
  isCompact: boolean;
  onToggle: () => void;
}

const CompactModeToggle: React.FC<CompactModeToggleProps> = ({ isCompact, onToggle }) => {
  return (
    <button
      className="fixed bottom-4 right-4 z-50 px-3 py-2 bg-gray-800 text-cyan-400 rounded border border-cyan-800 text-sm"
      onClick={onToggle}
    >
      {isCompact ? (
        <>
          <span className="hidden sm:inline">Detailed View</span>
          <span className="sm:hidden">Detailed</span>
        </>
      ) : (
        <>
          <span className="hidden sm:inline">Compact View</span>
          <span className="sm:hidden">Compact</span>
        </>
      )}
    </button>
  );
};

export default CompactModeToggle;