// src/components/RealityManipulationPanel.tsx
import React, { useState, useEffect } from 'react';
import { GameState } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber, formatPercent } from '../utils/formatters';
import CollapsibleSection from './CollapsibleSection';

interface RealityManipulationPanelProps {
  gameState: GameState;
  onManipulateReality: (
    constantId: string, 
    direction: 'increase' | 'decrease', 
    amount: number
  ) => void;
  isCompact?: boolean;
}

// Interface for fundamental constants
interface FundamentalConstant {
  id: string;
  name: string;
  description: string;
  currentValue: number;
  baseValue: number;
  minValue: number;
  maxValue: number;
  effects: {
    description: string;
    resource?: string;
    type: 'production' | 'consumption' | 'cost' | 'efficiency' | 'time' | 'global';
    factor: number;
  }[];
  cost: (amount: number) => Record<string, number>;
  unlockRequirements: {
    research?: Record<string, number>;
    cosmicInfluence: number;
  };
}

// Interface for dimensional anomalies
interface DimensionalAnomaly {
  id: string;
  name: string;
  description: string;
  chance: number; // Chance of appearing each tick
  duration: number; // How long it lasts
  effects: {
    description: string;
    resource?: string;
    multiplier: number;
  }[];
  mitigation: {
    resource: string;
    cost: number;
    reduction: number;
  };
  active: boolean;
  timeRemaining?: number;
}

const RealityManipulationPanel: React.FC<RealityManipulationPanelProps> = ({
  gameState,
  onManipulateReality,
  isCompact = false
}) => {
  const [selectedConstant, setSelectedConstant] = useState<string | null>(null);
  const [manipulationAmount, setManipulationAmount] = useState<number>(0.01);
  const [manipulationDirection, setManipulationDirection] = useState<'increase' | 'decrease'>('increase');
  const [showingAnomalies, setShowingAnomalies] = useState<boolean>(false);

  // Define the fundamental constants
  const fundamentalConstants: FundamentalConstant[] = [
    {
      id: 'planck-constant',
      name: 'Planck Constant',
      description: 'The quantum of electromagnetic action that relates a photon\'s energy to its frequency.',
      currentValue: 6.626e-34,
      baseValue: 6.626e-34,
      minValue: 6.626e-35,
      maxValue: 6.626e-33,
      effects: [
        {
          description: 'Energy production efficiency',
          resource: ResourceType.ENERGY,
          type: 'production',
          factor: 1.5
        },
        {
          description: 'Antimatter production rate',
          resource: ResourceType.ANTIMATTER,
          type: 'production',
          factor: 2.0
        }
      ],
      cost: (amount: number) => ({
        [ResourceType.ENERGY]: 5000 * amount * 100,
        [ResourceType.ANTIMATTER]: 10 * amount * 100,
        [ResourceType.COSMIC_INFLUENCE]: 1 * amount * 100
      }),
      unlockRequirements: {
        cosmicInfluence: 10
      }
    },
    {
      id: 'gravitational-constant',
      name: 'Gravitational Constant',
      description: 'Determines the force of attraction between masses in the universe.',
      currentValue: 6.674e-11,
      baseValue: 6.674e-11,
      minValue: 6.674e-12,
      maxValue: 6.674e-10,
      effects: [
        {
          description: 'Mineral production efficiency',
          resource: ResourceType.MINERALS,
          type: 'production',
          factor: 2.0
        },
        {
          description: 'Dark Matter production rate',
          resource: ResourceType.DARK_MATTER,
          type: 'production',
          factor: 2.5
        },
        {
          description: 'Building costs',
          type: 'cost',
          factor: 0.8
        }
      ],
      cost: (amount: number) => ({
        [ResourceType.ENERGY]: 10000 * amount * 100,
        [ResourceType.DARK_MATTER]: 15 * amount * 100,
        [ResourceType.COSMIC_INFLUENCE]: 2 * amount * 100
      }),
      unlockRequirements: {
        cosmicInfluence: 15
      }
    },
    {
      id: 'speed-of-light',
      name: 'Speed of Light',
      description: 'The speed at which light travels in a vacuum, and the upper limit for all matter and information.',
      currentValue: 299792458,
      baseValue: 299792458,
      minValue: 29979245.8,
      maxValue: 2997924580,
      effects: [
        {
          description: 'Research production rate',
          resource: ResourceType.RESEARCH,
          type: 'production',
          factor: 3.0
        },
        {
          description: 'Exploration time',
          type: 'time',
          factor: 0.5
        },
        {
          description: 'Energy consumption',
          resource: ResourceType.ENERGY,
          type: 'consumption',
          factor: 1.5
        }
      ],
      cost: (amount: number) => ({
        [ResourceType.ENERGY]: 20000 * amount * 100,
        [ResourceType.ANTIMATTER]: 30 * amount * 100,
        [ResourceType.DARK_MATTER]: 20 * amount * 100,
        [ResourceType.COSMIC_INFLUENCE]: 5 * amount * 100
      }),
      unlockRequirements: {
        cosmicInfluence: 25
      }
    },
    {
      id: 'strong-force',
      name: 'Strong Nuclear Force',
      description: 'The force that binds protons and neutrons together in atomic nuclei.',
      currentValue: 1.0,
      baseValue: 1.0,
      minValue: 0.5,
      maxValue: 2.0,
      effects: [
        {
          description: 'Fusion efficiency',
          resource: ResourceType.HYDROGEN,
          type: 'consumption',
          factor: 0.6
        },
        {
          description: 'Helium production',
          resource: ResourceType.HELIUM,
          type: 'production',
          factor: 2.5
        },
        {
          description: 'Energy production',
          resource: ResourceType.ENERGY,
          type: 'production',
          factor: 2.0
        }
      ],
      cost: (amount: number) => ({
        [ResourceType.ENERGY]: 30000 * amount * 100,
        [ResourceType.ANTIMATTER]: 50 * amount * 100,
        [ResourceType.COSMIC_INFLUENCE]: 8 * amount * 100
      }),
      unlockRequirements: {
        cosmicInfluence: 35
      }
    },
    {
      id: 'vacuum-energy-density',
      name: 'Vacuum Energy Density',
      description: 'The energy density of empty space, related to dark energy and the expansion of the universe.',
      currentValue: 5.39e-10,
      baseValue: 5.39e-10,
      minValue: 5.39e-11,
      maxValue: 5.39e-9,
      effects: [
        {
          description: 'Cosmic Influence production',
          resource: ResourceType.COSMIC_INFLUENCE,
          type: 'production',
          factor: 3.0
        },
        {
          description: 'All resource production',
          type: 'global',
          factor: 1.5
        },
        {
          description: 'Anomaly frequency',
          type: 'time',
          factor: 2.0
        }
      ],
      cost: (amount: number) => ({
        [ResourceType.ENERGY]: 50000 * amount * 100,
        [ResourceType.DARK_MATTER]: 100 * amount * 100,
        [ResourceType.COSMIC_INFLUENCE]: 15 * amount * 100
      }),
      unlockRequirements: {
        cosmicInfluence: 50
      }
    }
  ];

  // Define dimensional anomalies
  const dimensionalAnomalies: DimensionalAnomaly[] = [
    {
      id: 'temporal-distortion',
      name: 'Temporal Distortion',
      description: 'A warping of space-time causing unpredictable time flow.',
      chance: 0.02, // 2% chance per tick
      duration: 50, // Lasts 50 ticks
      effects: [
        {
          description: 'Production speed fluctuations',
          multiplier: 0.5 // Production at 50%
        }
      ],
      mitigation: {
        resource: ResourceType.DARK_MATTER,
        cost: 20,
        reduction: 0.5 // Reduces effect by 50%
      },
      active: false
    },
    {
      id: 'quantum-cascade',
      name: 'Quantum Cascade',
      description: 'A chain reaction of quantum fluctuations affecting energy stability.',
      chance: 0.015, // 1.5% chance per tick
      duration: 40,
      effects: [
        {
          description: 'Energy production unstable',
          resource: ResourceType.ENERGY,
          multiplier: 0.3 // 30% of normal
        }
      ],
      mitigation: {
        resource: ResourceType.ANTIMATTER,
        cost: 10,
        reduction: 0.7 // Reduces effect by 70%
      },
      active: true,
      timeRemaining: 25
    },
    {
      id: 'dimensional-rift',
      name: 'Dimensional Rift',
      description: 'A tear in reality connecting to other dimensions or timelines.',
      chance: 0.01, // 1% chance per tick
      duration: 60,
      effects: [
        {
          description: 'Random resource gains and losses',
          multiplier: -0.2 // Lose 20% of some resources
        },
        {
          description: 'Cosmic Influence bonus',
          resource: ResourceType.COSMIC_INFLUENCE,
          multiplier: 2.0 // Double production
        }
      ],
      mitigation: {
        resource: ResourceType.COSMIC_INFLUENCE,
        cost: 5,
        reduction: 0.8 // Reduces negative effect by 80%
      },
      active: false
    },
    {
      id: 'antimatter-cascade',
      name: 'Antimatter Cascade',
      description: 'Uncontrolled antimatter reactions causing destructive energy waves.',
      chance: 0.005, // 0.5% chance per tick
      duration: 30,
      effects: [
        {
          description: 'Antimatter loss',
          resource: ResourceType.ANTIMATTER,
          multiplier: -0.5 // Lose 50% of antimatter
        },
        {
          description: 'Energy surge',
          resource: ResourceType.ENERGY,
          multiplier: 3.0 // Triple energy production
        }
      ],
      mitigation: {
        resource: ResourceType.RESEARCH,
        cost: 5000,
        reduction: 0.9 // Reduces negative effect by 90%
      },
      active: false
    },
    {
      id: 'reality-fluctuation',
      name: 'Reality Fluctuation',
      description: 'Fundamental constants temporarily destabilize on their own.',
      chance: 0.003, // 0.3% chance per tick
      duration: 100,
      effects: [
        {
          description: 'All production erratic',
          multiplier: 0.7 // 70% effectiveness
        },
        {
          description: 'Cosmic Influence bonus',
          resource: ResourceType.COSMIC_INFLUENCE,
          multiplier: 5.0 // 5x production
        }
      ],
      mitigation: {
        resource: ResourceType.COSMIC_INFLUENCE,
        cost: 20,
        reduction: 0.95 // Almost completely mitigates
      },
      active: false
    }
  ];

  // Find the selected constant
  const selectedConstantData = fundamentalConstants.find(constant => constant.id === selectedConstant);

  // Calculate if player can afford to manipulate reality
  const canAffordManipulation = (constant: FundamentalConstant, amount: number): boolean => {
    const manipulationCost = constant.cost(amount);
    return Object.entries(manipulationCost).every(
      ([resource, cost]) => (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= cost
    );
  };

  // Check if constant is unlocked
  const isConstantUnlocked = (constant: FundamentalConstant): boolean => {
    return (gameState.resources.cosmicInfluence || 0) >= constant.unlockRequirements.cosmicInfluence;
  };

  // Handle manipulating a constant
  const handleManipulateConstant = () => {
    if (selectedConstant && selectedConstantData) {
      if (canAffordManipulation(selectedConstantData, manipulationAmount)) {
        onManipulateReality(selectedConstant, manipulationDirection, manipulationAmount);
      }
    }
  };

  // Handle mitigating an anomaly
  const handleMitigateAnomaly = (anomalyId: string) => {
    const anomaly = dimensionalAnomalies.find(a => a.id === anomalyId);
    if (anomaly && anomaly.active) {
      const resourceKey = anomaly.mitigation.resource as keyof typeof gameState.resources;
      if ((gameState.resources[resourceKey] || 0) >= anomaly.mitigation.cost) {
        // This would call a function passed from the parent to handle mitigation
        onManipulateReality('mitigate-anomaly', 'decrease', 0);
      }
    }
  };

  // Format a constant value for display
  const formatConstantValue = (value: number): string => {
    if (value === 0) return "0";
    
    // Use scientific notation for very large or small numbers
    const absValue = Math.abs(value);
    if (absValue < 0.001 || absValue > 100000) {
      return value.toExponential(3);
    }
    
    // Otherwise use fixed notation with appropriate precision
    return value.toFixed(4);
  };

  // Calculate percentage change from base value
  const calculatePercentChange = (current: number, base: number): string => {
    const percentChange = ((current - base) / base) * 100;
    return percentChange >= 0 ? `+${percentChange.toFixed(2)}%` : `${percentChange.toFixed(2)}%`;
  };

  // Get color class based on effect type
  const getEffectColorClass = (effectType: string): string => {
    switch (effectType) {
      case 'production': return 'text-green-400';
      case 'consumption': return 'text-blue-400';
      case 'cost': return 'text-yellow-400';
      case 'efficiency': return 'text-cyan-400';
      case 'time': return 'text-purple-400';
      case 'global': return 'text-pink-400';
      default: return 'text-gray-300';
    }
  };

  // Get active anomalies
  const activeAnomalies = dimensionalAnomalies.filter(anomaly => anomaly.active);

  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 border-b border-cyan-800 pb-1">Reality Manipulation Matrix</h2>
      
      {/* Warning messages for realism */}
      <div className="mb-3 text-yellow-400 text-sm">
        Warning: Manipulating fundamental constants has unpredictable consequences and may trigger dimensional anomalies.
      </div>
      
      {/* Active anomalies - always visible */}
      {activeAnomalies.length > 0 && (
        <div className="mb-3 p-2 bg-red-900 bg-opacity-30 rounded border border-red-700">
          <div className="flex justify-between items-center mb-1">
            <h3 className="font-bold text-red-400">Active Anomalies ({activeAnomalies.length})</h3>
            <button
              className="px-2 py-0.5 bg-gray-700 hover:bg-gray-600 rounded text-xs"
              onClick={() => setShowingAnomalies(!showingAnomalies)}
            >
              {showingAnomalies ? 'Hide' : 'Show'}
            </button>
          </div>
          
          {showingAnomalies && (
            <div className="space-y-2">
              {activeAnomalies.map(anomaly => (
                <div key={anomaly.id} className="p-2 bg-gray-800 bg-opacity-70 rounded">
                  <div className="flex justify-between items-start">
                    <div className="font-bold text-sm text-red-400">{anomaly.name}</div>
                    <div className="text-xs text-gray-300">
                      {anomaly.timeRemaining} ticks remaining
                    </div>
                  </div>
                  {!isCompact && (
                    <p className="text-xs text-gray-300 mb-1">{anomaly.description}</p>
                  )}
                  <div className="text-xs space-y-1">
                    {anomaly.effects.map((effect, index) => (
                      <div key={index} className="text-yellow-400">
                        {effect.description}: {effect.multiplier > 0 ? 'x' : ''}{effect.multiplier.toFixed(1)}
                      </div>
                    ))}
                  </div>
                  <div className="mt-2 flex justify-between items-center">
                    <span className="text-xs">
                      Mitigation: {anomaly.mitigation.cost} {anomaly.mitigation.resource}
                    </span>
                    <button
                      className="px-2 py-1 bg-red-700 hover:bg-red-600 disabled:bg-gray-600 disabled:opacity-50 rounded text-xs"
                      onClick={() => handleMitigateAnomaly(anomaly.id)}
                      disabled={(gameState.resources[anomaly.mitigation.resource as keyof typeof gameState.resources] || 0) < anomaly.mitigation.cost}
                    >
                      Mitigate
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      
      {/* Constants selection */}
      <div className="mb-3">
        <h3 className="font-bold mb-2">Fundamental Constants</h3>
        <div className={`grid ${isCompact ? 'grid-cols-1 gap-1' : 'grid-cols-2 gap-2'}`}>
          {fundamentalConstants.map(constant => (
            <button
              key={constant.id}
              className={`p-2 rounded text-sm text-left ${
                !isConstantUnlocked(constant)
                  ? 'bg-gray-900 opacity-70 cursor-not-allowed'
                  : selectedConstant === constant.id 
                    ? 'bg-purple-800 border border-purple-600' 
                    : 'bg-gray-700 hover:bg-gray-600'
              }`}
              onClick={() => isConstantUnlocked(constant) && setSelectedConstant(constant.id)}
              disabled={!isConstantUnlocked(constant)}
            >
              <div className="font-bold">{constant.name}</div>
              {!isConstantUnlocked(constant) ? (
                <div className="text-xs text-purple-400">
                  Requires {constant.unlockRequirements.cosmicInfluence} Cosmic Influence
                </div>
              ) : (
                <div className="flex justify-between text-xs">
                  <span>{formatConstantValue(constant.currentValue)}</span>
                  <span className={`${
                    constant.currentValue > constant.baseValue 
                      ? 'text-green-400' 
                      : constant.currentValue < constant.baseValue 
                        ? 'text-red-400' 
                        : 'text-gray-400'
                  }`}>
                    {calculatePercentChange(constant.currentValue, constant.baseValue)}
                  </span>
                </div>
              )}
            </button>
          ))}
        </div>
      </div>
      
      {/* Selected constant details */}
      {selectedConstantData && (
        <div className="p-3 bg-gray-700 rounded mb-3">
          <h3 className="font-bold">{selectedConstantData.name}</h3>
          
          {/* Only show description in non-compact mode */}
          {!isCompact && (
            <p className="text-sm text-gray-300 mb-2">{selectedConstantData.description}</p>
          )}
          
          {/* Constant values */}
          <div className="grid grid-cols-2 gap-2 mb-2">
            <div>
              <div className="text-xs font-bold">Current Value:</div>
              <div className="text-purple-300">{formatConstantValue(selectedConstantData.currentValue)}</div>
            </div>
            <div>
              <div className="text-xs font-bold">Base Value:</div>
              <div className="text-gray-300">{formatConstantValue(selectedConstantData.baseValue)}</div>
            </div>
            <div>
              <div className="text-xs font-bold">Change:</div>
              <div className={`${
                selectedConstantData.currentValue > selectedConstantData.baseValue 
                  ? 'text-green-400' 
                  : selectedConstantData.currentValue < selectedConstantData.baseValue 
                    ? 'text-red-400' 
                    : 'text-gray-400'
              }`}>
                {calculatePercentChange(selectedConstantData.currentValue, selectedConstantData.baseValue)}
              </div>
            </div>
            <div>
              <div className="text-xs font-bold">Range:</div>
              <div className="text-gray-300">
                {formatConstantValue(selectedConstantData.minValue)} - {formatConstantValue(selectedConstantData.maxValue)}
              </div>
            </div>
          </div>
          
          {/* Effects of manipulating this constant */}
          <div className="mb-3">
            <div className="text-xs font-bold mb-1">Effects:</div>
            <div className={`${isCompact ? 'space-y-1' : 'grid grid-cols-2 gap-1'}`}>
              {selectedConstantData.effects.map((effect, index) => (
                <div key={index} className={`text-xs ${getEffectColorClass(effect.type)}`}>
                  {effect.description}: {effect.factor > 1 ? 'x' : '÷'}{Math.abs(effect.factor).toFixed(1)}
                </div>
              ))}
            </div>
          </div>
          
          {/* Manipulation interface */}
          <div className="mb-3">
            <div className="text-xs font-bold mb-1">Manipulation Controls:</div>
            <div className="grid grid-cols-3 gap-2 mb-2">
              <select
                className="bg-gray-800 border border-gray-600 rounded px-2 py-1 text-xs"
                value={manipulationDirection}
                onChange={(e) => setManipulationDirection(e.target.value as 'increase' | 'decrease')}
              >
                <option value="increase">Increase</option>
                <option value="decrease">Decrease</option>
              </select>
              <select
                className="bg-gray-800 border border-gray-600 rounded px-2 py-1 text-xs"
                value={manipulationAmount}
                onChange={(e) => setManipulationAmount(parseFloat(e.target.value))}
              >
                <option value="0.01">1%</option>
                <option value="0.05">5%</option>
                <option value="0.1">10%</option>
                <option value="0.25">25%</option>
                <option value="0.5">50%</option>
                <option value="1">100%</option>
              </select>
              <button
                className="px-2 py-1 bg-purple-700 hover:bg-purple-600 rounded text-xs"
                onClick={handleManipulateConstant}
                disabled={!canAffordManipulation(selectedConstantData, manipulationAmount)}
              >
                Apply
              </button>
            </div>
          </div>
          
          {/* Manipulation cost */}
          <div>
            <div className="text-xs font-bold mb-1">Manipulation Cost:</div>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-1 text-xs">
              {Object.entries(selectedConstantData.cost(manipulationAmount)).map(([resource, amount]) => (
                <div 
                  key={resource}
                  className={`${
                    (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
                      ? 'text-green-400'
                      : 'text-red-400'
                  }`}
                >
                  {resource}: {formatNumber(amount)}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      
      {/* Stability and risk - in collapsible section in compact mode */}
      {isCompact ? (
        <CollapsibleSection title="Reality Stability and Risk" isOpen={false}>
          <div className="mb-2">
            <div className="text-xs mb-1">Current Reality Stability:</div>
            <div className="h-2 bg-gray-900 rounded overflow-hidden">
              <div 
                className="h-full bg-gradient-to-r from-red-500 via-yellow-500 to-green-500"
                style={{ width: '75%' }}
              />
            </div>
            <div className="flex justify-between text-xs mt-1">
              <span className="text-red-400">Unstable</span>
              <span className="text-yellow-400">75%</span>
              <span className="text-green-400">Stable</span>
            </div>
          </div>
          <div className="text-xs">
            <div>Anomaly Chance: <span className="text-yellow-400">3.8%</span> per tick</div>
          </div>
        </CollapsibleSection>
      ) : (
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-bold mb-2">Reality Stability</h3>
          <div className="mb-3">
            <div className="flex justify-between text-sm mb-1">
              <span>Current Stability:</span>
              <span className="text-yellow-400">75%</span>
            </div>
            <div className="h-3 bg-gray-900 rounded overflow-hidden">
              <div 
                className="h-full bg-gradient-to-r from-red-500 via-yellow-500 to-green-500"
                style={{ width: '75%' }}
              />
            </div>
            <div className="flex justify-between text-xs mt-1">
              <span className="text-red-400">Unstable</span>
              <span className="text-green-400">Stable</span>
            </div>
          </div>
          
          <div className="grid grid-cols-2 gap-2 text-sm">
            <div>Anomaly Chance: <span className="text-yellow-400">3.8%</span> per tick</div>
            <div>Constants Modified: <span className="text-purple-400">2/5</span></div>
            <div>Current Risks: <span className="text-orange-400">Moderate</span></div>
            <div>Anomalies Resolved: <span className="text-green-400">7</span></div>
          </div>
          
          <div className="mt-3 text-xs text-gray-300">
            <p>Reality becomes less stable as you deviate further from natural constants. Stability can be maintained by balancing manipulations and resolving anomalies quickly.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RealityManipulationPanel;