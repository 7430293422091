import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GameState, GamePhase, ResourceState } from './types/GameTypes';
import { ProducerType } from './types/ProducerTypes';
import { initializeGame, buyUpgrade, toggleUpgrade, collectResource, investmentTransaction, cashToReputation, buyProducer } from './game/GameState';
import { GameLoop } from './utils/gameLoop';
import { saveGame, deleteSave } from './utils/localStorage';
import Header from './components/Header';
import ResourceDisplay from './components/ResourceDisplay';
import UpgradePanel from './components/UpgradePanel';
import ProducerPanel from './components/ProducerPanel';
import InvestmentPanel from './components/InvestmentPanel';
import PhaseDisplay from './components/PhaseDisplay';
import EventLog from './components/EventLog';
import AsteroidMining from './phases/AsteroidMining';
import SolarSystem from './phases/SolarSystem';
import Interstellar from './phases/Interstellar';
import GalacticTranscendence from './phases/GalacticTranscendence';
import CompactModeToggle from './components/CompactModeToggle';
import './styles.css';
import { formatNumber } from './utils/formatters';
import { getExplorationDestination, canAffordExpedition } from './game/ExplorationSystem';

// Import debugging tools if needed
import DevTools from './components/DevTools';
import DebugConsole from './components/DebugConsole';
import PerformanceMonitor from './components/PerformanceMonitor';
import ResearchLabPanel from './components/ResearchLabelPanel';
import ExplorationPanel from './components/ExplorationPanel';
import SpacefleetPanel from './components/SpacefleetPanel';
import ColonyManagementPanel from './components/ColonyManagementPanel';
import DiplomacyPanel from './components/DiplomacyPanel';
import RealityManipulationPanel from './components/RealityManipulationPanel';

const App: React.FC = () => {
  const [gameState, setGameState] = useState<GameState>(initializeGame());
  const [gameLoop, setGameLoop] = useState<GameLoop | null>(null);
  const [isCompactMode, setIsCompactMode] = useState<boolean>(window.innerWidth < 640);
  const resourceTimestamps = useRef<{[key: string]: number}>({});

  const [resourceCollections, setResourceCollections] = useState<{
    resource: string;
    amount: number;
    id: number;
    x: number;
    y: number;
  }[]>([]);
  const collectionIdRef = useRef(0);

  // Initialize game loop
  useEffect(() => {
    const loop = new GameLoop(gameState, (state: GameState) => {
      setGameState(prevState => ({ ...state }));
    });
    
    setGameLoop(loop);
    loop.start();
    
    return () => {
      loop.stop();
    };
  }, []);

  // Clean up touch handling to fix scrolling
  useEffect(() => {
    // No need to prevent multi-touch for our click buttons anymore
    const handleTouchStart = (e: TouchEvent) => {
      // Only prevent default on specific elements to avoid interference
      // with game controls and allow natural scrolling
      const target = e.target as HTMLElement;
      const isButton = target.tagName === 'BUTTON' || 
                       target.closest('.click-button') ||
                       target.closest('button');
                       
      // Allow default behavior for most elements, especially scrollable areas
      if (isButton) {
        // For buttons, we let individual components handle their own touch events
        // so we don't interfere with the CollectButton's touch handling
      }
    };
    
    // Disable context menu for long press
    const disableContextMenu = (e: Event) => {
      e.preventDefault();
      return false;
    };
    
    // Add passive touch listeners for better performance
    document.addEventListener('touchstart', handleTouchStart, { passive: true });
    window.addEventListener('contextmenu', disableContextMenu);
    
    // Add specific styling to improve touch response
    (document.documentElement.style as any).webkitTapHighlightColor = 'transparent';
    (document.documentElement.style as any).webkitTouchCallout = 'none';
    
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('contextmenu', disableContextMenu);
    };
  }, []);
  
  // Update game loop when state changes
  useEffect(() => {
    if (gameLoop) {
      gameLoop.updateState(gameState);
    }
  }, [gameLoop, gameState]);

  // Save game when window is closed or tab is changed
  useEffect(() => {
    const handleBeforeUnload = () => {
      saveGame({
        gameState,
        version: '0.1.0',
        timestamp: Date.now()
      });
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [gameState]);

  // Handle buying upgrades
  const handleBuyUpgrade = useCallback((upgradeId: string) => {
    setGameState(prevState => buyUpgrade(prevState, upgradeId));
  }, []);

  // Handle toggling upgrades
  const handleToggleUpgrade = useCallback((upgradeId: string) => {
    setGameState(prevState => toggleUpgrade(prevState, upgradeId));
  }, []);

  const handleCollectResource = useCallback((
    resource: string, 
    amount: number, 
    event?: React.MouseEvent | React.TouchEvent
  ) => {
    // Super aggressive debouncing (250ms)
    const now = Date.now();
    const lastTime = resourceTimestamps.current[resource] || 0;
    
    if (now - lastTime < 250) {
      // Skip this collection - it's too soon after the last one
      return;
    }
    
    // Update the timestamp
    resourceTimestamps.current[resource] = now;
    
    // Add collection indicator if event is provided (from manual clicks)
    if (event) {
      let clientX: number;
      let clientY: number;
      
      // Extract coordinates differently based on event type
      if ('touches' in event) {
        // Touch event
        const touch = event.touches[0] || event.changedTouches[0];
        clientX = touch.clientX;
        clientY = touch.clientY;
      } else {
        // Mouse event
        clientX = event.clientX;
        clientY = event.clientY;
      }
      
      const newId = collectionIdRef.current++;
      setResourceCollections(prev => [
        ...prev, 
        { 
          resource, 
          amount, 
          id: newId, 
          x: clientX, 
          y: clientY 
        }
      ]);
      
      // Remove the indicator after animation completes
      setTimeout(() => {
        setResourceCollections(prev => prev.filter(item => item.id !== newId));
      }, 1500);
    }
    
    // Update game state using a function to avoid race conditions
    setGameState(prevState => {
      // Create a new state to ensure no mutations
      const newState = { ...prevState };
      
      // Add resources directly (no hidden multipliers)
      newState.resources = {
        ...newState.resources,
        [resource]: (newState.resources[resource as keyof ResourceState] || 0) + amount
      };
      
      return newState;
    });
  }, []);
  
  // Handle investment transactions
  const handleInvestmentTransaction = useCallback((sectorId: string, shares: number, type: 'buy' | 'sell') => {
    setGameState(prevState => investmentTransaction(prevState, sectorId, shares, type));
  }, []);

  // Handle cash to reputation conversion
  const handleCashToReputation = useCallback((amount: number) => {
    setGameState(prevState => cashToReputation(prevState, amount));
  }, []);
  
  // Handle buying producers
  const handleBuyProducer = useCallback((producerType: ProducerType) => {
    setGameState(prevState => buyProducer(prevState, producerType));
  }, []);

  const handleAllocateResearch = useCallback((areaId: string, amount: number) => {
    setGameState(prevState => {
      const newState = { ...prevState };
      
      // Create research object if it doesn't exist
      if (!newState.research) {
        newState.research = {};
      }
      
      // Calculate cost
      const currentLevel = newState.research[areaId] || 0;
      const researchCost = 50 * (currentLevel + 1) * amount;
      
      // Check if player can afford it
      if (newState.resources.research < researchCost) {
        return prevState;
      }
      
      // Deduct research points
      newState.resources.research -= researchCost;
      
      // Increment the research level
      newState.research[areaId] = (newState.research[areaId] || 0) + amount;
      
      // Log the action
      newState.eventLog.push(`Advanced ${areaId} research to level ${newState.research[areaId]}`);
      
      return newState;
    });
  }, []);
  
  // Exploration handlers
  const handleStartExpedition = useCallback((destinationId: string) => {
    setGameState(prevState => {
      const newState = { ...prevState };
      
      // Find the destination data (you'll need to implement this)
      const destination = getExplorationDestination(destinationId);
      if (!destination) return prevState;
      
      // Check if player can afford the expedition
      if (!canAffordExpedition(newState, destination)) {
        return prevState;
      }
      
      // Create exploration object if it doesn't exist
      if (!newState.exploration) {
        newState.exploration = {
          activeExpeditions: [],
          completedExpeditions: [],
          discoveredLocations: []
        };
      }
      
      // Deduct resources
      Object.entries(destination.resourceCosts).forEach(([resource, amount]) => {
        // @ts-ignore
        newState.resources[resource] -= amount;
      });
      
      // Create new expedition
      const expeditionDuration = destination.expeditionDuration * 1000; // convert to ms
      const newExpedition = {
        destinationId: destination.id,
        startTime: Date.now(),
        endTime: Date.now() + expeditionDuration
      };
      
      // Add to active expeditions
      newState.exploration.activeExpeditions.push(newExpedition);
      
      // Log the action
      newState.eventLog.push(`Started expedition to ${destination.name}`);
      
      return newState;
    });
  }, []);
  
  const handleCollectExpeditionRewards = useCallback((rewards: Record<string, number>) => {
    setGameState(prevState => {
      const newState = { ...prevState };
      
      // Add rewards to resources
      Object.entries(rewards).forEach(([resource, amount]) => {
        // @ts-ignore
        newState.resources[resource] = (newState.resources[resource] || 0) + amount;
      });
      
      // Log the action
      newState.eventLog.push(`Collected expedition rewards`);
      
      return newState;
    });
  }, []);
  
  // Colony Management handler
  const handleManageColony = useCallback((colonyId: string, action: string, parameter?: any) => {
    setGameState(prevState => {
      const newState = { ...prevState };
      
      // Create colonies array if it doesn't exist
      if (!newState.colonies) {
        newState.colonies = [];
      }
      
      // Handle different colony actions
      switch (action) {
        case 'establishColony': {
          // Logic for establishing a new colony
          // This will need to be implemented based on your colony system
          newState.eventLog.push(`Established new colony`);
          break;
        }
        
        case 'constructBuilding': {
          // Logic for constructing a building in a colony
          newState.eventLog.push(`Constructed building in colony`);
          break;
        }
        
        case 'sendColonists': {
          // Logic for sending colonists to a colony
          newState.eventLog.push(`Sent ${parameter} colonists to colony`);
          break;
        }
        
        case 'setPopulationPolicy': {
          // Logic for setting population policy
          newState.eventLog.push(`Set colony population policy to ${parameter}`);
          break;
        }
      }
      
      return newState;
    });
  }, []);
  
  // Spacefleet handlers
  const handleBuildShip = useCallback((shipType: string, count: number) => {
    setGameState(prevState => {
      const newState = { ...prevState };
      
      // Create ships object if it doesn't exist
      if (!newState.ships) {
        newState.ships = {};
      }
      
      // Find ship data and check if player can afford it
      // This will need to be implemented based on your ship system
      
      // Add ships to the fleet
      newState.ships[shipType] = (newState.ships[shipType] || 0) + count;
      
      // Log the action
      newState.eventLog.push(`Built ${count} ${shipType} ships`);
      
      return newState;
    });
  }, []);
  
  const handleAssignFleet = useCallback((fleetId: string, assignment: string) => {
    setGameState(prevState => {
      const newState = { ...prevState };
      
      // Create fleets array if it doesn't exist
      if (!newState.fleets) {
        newState.fleets = [];
      }
      
      // Find the fleet and update its assignment
      // This will need to be implemented based on your fleet system
      
      // Log the action
      newState.eventLog.push(`Assigned fleet to ${assignment}`);
      
      return newState;
    });
  }, []);
  
  // Diplomacy handler
  const handleDiplomaticAction = useCallback((civilizationId: string, action: string, parameters?: any) => {
    setGameState(prevState => {
      const newState = { ...prevState };
      
      // Create diplomacy object if it doesn't exist
      if (!newState.diplomacy) {
        newState.diplomacy = {
          civilizations: [],
          relations: {},
          tradeAgreements: []
        };
      }
      
      // Handle different diplomatic actions
      switch (action) {
        case 'trade': {
          // Logic for establishing a trade agreement
          newState.eventLog.push(`Established trade agreement with ${civilizationId}`);
          break;
        }
        
        case 'resourceTrade': {
          // Logic for trading resources
          const { resource, amount } = parameters;
          newState.eventLog.push(`Traded ${amount} ${resource} with ${civilizationId}`);
          break;
        }
        
        default: {
          // Logic for other diplomatic actions
          newState.eventLog.push(`Performed diplomatic action: ${action} with ${civilizationId}`);
          break;
        }
      }
      
      return newState;
    });
  }, []);
  
  // Reality Manipulation handler
  const handleManipulateReality = useCallback((constantId: string, direction: 'increase' | 'decrease', amount: number) => {
    setGameState(prevState => {
      const newState = { ...prevState };
      
      // Create reality manipulation object if it doesn't exist
      if (!newState.realityManipulation) {
        newState.realityManipulation = {
          constants: {},
          stability: 1.0,
          anomalies: []
        };
      }
      
      // Special case for mitigating anomalies
      if (constantId === 'mitigate-anomaly') {
        // Logic for mitigating anomalies
        newState.eventLog.push(`Mitigated dimensional anomaly`);
        return newState;
      }
      
      // Find constant data and calculate costs
      // This will need to be implemented based on your constants system
      
      // Manipulate the constant
      // if (!newState.realityManipulation.constants[constantId]) {
      //   newState.realityManipulation.constants[constantId] = baseValue;
      // }
      
      // const currentValue = newState.realityManipulation.constants[constantId];
      // const newValue = direction === 'increase' 
      //   ? currentValue * (1 + amount)
      //   : currentValue * (1 - amount);
      
      // newState.realityManipulation.constants[constantId] = newValue;
      
      // Log the action
      newState.eventLog.push(`Manipulated ${constantId} constant`);
      
      return newState;
    });
  }, []);

  // Handle game reset
  const handleReset = useCallback(() => {
    if (gameLoop) {
      gameLoop.stop();
    }
    deleteSave();
    const newState = initializeGame();
    setGameState(newState);
    
    // Create and start a new game loop
    const loop = new GameLoop(newState, (state: GameState) => {
      setGameState(prevState => ({ ...state }));
    });
    setGameLoop(loop);
    loop.start();
  }, [gameLoop]);

  // Render the correct phase component
  const renderPhaseComponent = () => {
    switch (gameState.phase) {
      case GamePhase.ASTEROID_MINING:
        return (
          <AsteroidMining 
            gameState={gameState} 
            onCollectResource={handleCollectResource}
            isCompact={isCompactMode}
          />
        );
      case GamePhase.SOLAR_SYSTEM:
        return (
          <SolarSystem 
            gameState={gameState} 
            onCollectResource={handleCollectResource}
            isCompact={isCompactMode}
          />
        );
      case GamePhase.INTERSTELLAR:
        return (
          <Interstellar 
            gameState={gameState} 
            onCollectResource={handleCollectResource}
            isCompact={isCompactMode}
          />
        );
      case GamePhase.GALACTIC_TRANSCENDENCE:
        return (
          <GalacticTranscendence 
            gameState={gameState} 
            onCollectResource={handleCollectResource}
            isCompact={isCompactMode}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-cyan-300 font-mono">
      <div className="container mx-auto p-2 sm:p-4">
        <Header 
          gameState={gameState} 
          onReset={handleReset}
        />
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
          <div className="md:col-span-2">
            <PhaseDisplay phase={gameState.phase} isCompact={isCompactMode} />
            
            <div className="mt-3">
              {renderPhaseComponent()}
            </div>
            
            <div className="mt-3">
              <ResourceDisplay gameState={gameState} />
            </div>
            
            {/* New Research Lab Panel - unlocks early in Phase 1 */}
            {gameState.unlocks.researchLabUnlocked && (
              <div className="mt-3">
                <ResearchLabPanel 
                  gameState={gameState}
                  onAllocateResearch={handleAllocateResearch}
                  isCompact={isCompactMode}
                />
              </div>
            )}
            
            {/* New Exploration Panel - unlocks in Phase 2 */}
            {gameState.unlocks.explorationUnlocked && (
              <div className="mt-3">
                <ExplorationPanel 
                  gameState={gameState}
                  onStartExpedition={handleStartExpedition}
                  onCollectRewards={handleCollectExpeditionRewards}
                  isCompact={isCompactMode}
                />
              </div>
            )}
            
            {/* New Colony Management Panel - unlocks in Phase 2 */}
            {gameState.unlocks.colonyManagementUnlocked && (
              <div className="mt-3">
                <ColonyManagementPanel 
                  gameState={gameState}
                  onManageColony={handleManageColony}
                  isCompact={isCompactMode}
                />
              </div>
            )}
            
            {/* New Spacefleet Panel - unlocks in Phase 3 */}
            {gameState.unlocks.spacefleetUnlocked && (
              <div className="mt-3">
                <SpacefleetPanel 
                  gameState={gameState}
                  onBuildShip={handleBuildShip}
                  onAssignFleet={handleAssignFleet}
                  isCompact={isCompactMode}
                />
              </div>
            )}
            
            {/* New Diplomacy Panel - unlocks in Phase 3 or 4 */}
            {gameState.unlocks.diplomacyUnlocked && (
              <div className="mt-3">
                <DiplomacyPanel 
                  gameState={gameState}
                  onDiplomaticAction={handleDiplomaticAction}
                  isCompact={isCompactMode}
                />
              </div>
            )}
            
            {/* New Reality Manipulation Panel - unlocks in Phase 4 */}
            {gameState.unlocks.realityManipulationUnlocked && (
              <div className="mt-3">
                <RealityManipulationPanel 
                  gameState={gameState}
                  onManipulateReality={handleManipulateReality}
                  isCompact={isCompactMode}
                />
              </div>
            )}
          </div>
          
          <div className="space-y-3">
            <ProducerPanel
              gameState={gameState}
              onBuyProducer={handleBuyProducer}
              isCompact={isCompactMode}
            />
            
            <UpgradePanel 
              gameState={gameState} 
              onBuyUpgrade={handleBuyUpgrade} 
              onToggleUpgrade={handleToggleUpgrade}
              isCompact={isCompactMode}
            />
            
            {gameState.unlocks.investmentsUnlocked && (
              <InvestmentPanel 
                gameState={gameState} 
                onTransaction={handleInvestmentTransaction} 
                onConvertCash={handleCashToReputation}
                isCompact={isCompactMode}
              />
            )}
            
            <EventLog events={gameState.eventLog} />
          </div>
        </div>
      </div>
      
      {/* Resource collection floating animations */}
      {resourceCollections.map(item => (
        <div
          key={item.id}
          className="fixed pointer-events-none z-50"
          style={{
            left: item.x,
            top: item.y,
            transform: 'translate(-50%, -50%)',
            animation: "float-up 1.5s forwards"
          }}
        >
          <div className={`text-${
            item.resource === 'minerals' ? 'gray-300' :
            item.resource === 'energy' ? 'yellow-300' :
            item.resource === 'research' ? 'blue-300' :
            item.resource === 'reputation' ? 'purple-300' :
            item.resource === 'hydrogen' ? 'green-300' :
            item.resource === 'helium' ? 'green-400' :
            item.resource === 'rareMetals' ? 'orange-300' :
            item.resource === 'antimatter' ? 'pink-300' :
            item.resource === 'darkMatter' ? 'indigo-300' :
            'teal-300' // cosmic influence and default
          } font-bold text-shadow`}>
            +{formatNumber(item.amount)}
          </div>
        </div>
      ))}
  
      {/* Compact mode toggle */}
      <CompactModeToggle 
        isCompact={isCompactMode} 
        onToggle={() => setIsCompactMode(!isCompactMode)} 
      />
    </div>
  );
};

export default App;