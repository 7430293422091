import React, { useState } from 'react';
import { GameState } from '../types/GameTypes';
import { exportSave, importSave, deleteSave } from '../utils/localStorage';

interface HeaderProps {
  gameState: GameState;
  onReset: () => void;
}

const Header: React.FC<HeaderProps> = ({ gameState, onReset }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [importError, setImportError] = useState<string | null>(null);

  const handleExportSave = () => {
    exportSave();
  };

  const handleImportSave = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    importSave(file)
      .then(() => {
        setImportError(null);
        window.location.reload();
      })
      .catch(error => {
        setImportError('Failed to import save: ' + error.message);
      });
  };

  const handleResetGame = () => {
    if (window.confirm('Are you sure you want to reset your game? This cannot be undone.')) {
      onReset();
    }
  };

  return (
    <header className="py-4 border-b border-cyan-800">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-cyan-400">Stellar Expansion</h1>
        
        <div className="relative">
          <button 
            className="px-4 py-2 bg-cyan-900 hover:bg-cyan-800 rounded"
            onClick={() => setShowOptions(!showOptions)}
          >
            Options
          </button>
          
          {showOptions && (
            <div className="absolute right-0 mt-2 w-48 bg-gray-800 border border-cyan-800 rounded shadow-lg z-10">
              <ul>
                <li>
                  <button 
                    className="w-full text-left px-4 py-2 hover:bg-gray-700"
                    onClick={handleExportSave}
                  >
                    Export Save
                  </button>
                </li>
                <li>
                  <label className="w-full text-left px-4 py-2 hover:bg-gray-700 cursor-pointer block">
                    Import Save
                    <input 
                      type="file" 
                      accept=".json" 
                      className="hidden" 
                      onChange={handleImportSave} 
                    />
                  </label>
                </li>
                <li>
                  <button 
                    className="w-full text-left px-4 py-2 hover:bg-gray-700 text-red-400"
                    onClick={handleResetGame}
                  >
                    Reset Game
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      
      {importError && (
        <div className="mt-2 p-2 bg-red-900 text-white rounded">
          {importError}
        </div>
      )}
      
      <div className="mt-1 text-sm text-gray-400">
        Phase: {gameState.phase.replace('_', ' ')} | Tick: {gameState.tick}
      </div>
    </header>
  );
};

export default Header;