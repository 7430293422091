// src/components/DiplomacyPanel.tsx
import React, { useState } from 'react';
import { GameState } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber } from '../utils/formatters';
import CollapsibleSection from './CollapsibleSection';

interface DiplomacyPanelProps {
  gameState: GameState;
  onDiplomaticAction: (
    civilizationId: string, 
    action: string, 
    parameters?: any
  ) => void;
  isCompact?: boolean;
}

// Interface for civilization data
interface Civilization {
  id: string;
  name: string;
  description: string;
  attitude: number; // -100 to 100
  relationshipStatus: 'hostile' | 'neutral' | 'friendly' | 'allied';
  tradeStatus: 'none' | 'basic' | 'advanced' | 'comprehensive';
  specialties: string[];
  demands: string[];
  tradedResources: Record<string, number>;
  resources: string[];
  technology: number; // 1-5
  militaryPower: number; // 1-10
  lastContact: number; 
  traits: string[];
}

// Interface for diplomatic actions
interface DiplomaticAction {
  id: string;
  name: string;
  description: string;
  cost: Record<string, number>;
  attitudeEffect: number;
  requiredAttitude: number;
  cooldown: number;
  unlockRequirements?: {
    research?: Record<string, number>;
    resources?: Record<string, number>;
    relationshipStatus?: string[];
  };
}

// Interface for trade agreements
interface TradeAgreement {
  id: string;
  name: string;
  description: string;
  give: Record<string, number>;
  receive: Record<string, number>;
  duration: number; // in ticks
  value: number; // net value for player
}

const DiplomacyPanel: React.FC<DiplomacyPanelProps> = ({
  gameState,
  onDiplomaticAction,
  isCompact = false
}) => {
  const [selectedCivilization, setSelectedCivilization] = useState<string | null>(null);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [selectedTrade, setSelectedTrade] = useState<string | null>(null);
  const [tradeResource, setTradeResource] = useState<string>(ResourceType.MINERALS);
  const [tradeAmount, setTradeAmount] = useState<number>(100);
  const [showContacts, setShowContacts] = useState<boolean>(true);

  // Define known civilizations (would come from game state)
  const civilizations: Civilization[] = [
    {
      id: 'zenari',
      name: 'Zenari Collective',
      description: 'A peaceful, highly advanced civilization focused on scientific research and technological development.',
      attitude: 65,
      relationshipStatus: 'friendly',
      tradeStatus: 'basic',
      specialties: ['research', 'technology'],
      demands: ['rare metals', 'energy'],
      tradedResources: {
        [ResourceType.RESEARCH]: 50,
        [ResourceType.RARE_METALS]: -25
      },
      resources: [ResourceType.RESEARCH, ResourceType.ENERGY, ResourceType.ANTIMATTER],
      technology: 5,
      militaryPower: 3,
      lastContact: Date.now() - 1000 * 60 * 60 * 2, // 2 hours ago
      traits: ['logical', 'peaceful', 'curious']
    },
    {
      id: 'voidborn',
      name: 'Voidborn Empire',
      description: 'A militaristic, expansionist civilization that values strength and resources.',
      attitude: -30,
      relationshipStatus: 'hostile',
      tradeStatus: 'none',
      specialties: ['military', 'minerals'],
      demands: ['territory', 'technology'],
      tradedResources: {},
      resources: [ResourceType.MINERALS, ResourceType.HYDROGEN, ResourceType.HELIUM],
      technology: 3,
      militaryPower: 8,
      lastContact: Date.now() - 1000 * 60 * 60 * 24, // 1 day ago
      traits: ['aggressive', 'expansionist', 'honorable']
    },
    {
      id: 'harmony',
      name: 'Harmony Conclave',
      description: 'A spiritually-focused civilization that values balance and cosmic harmony.',
      attitude: 20,
      relationshipStatus: 'neutral',
      tradeStatus: 'basic',
      specialties: ['reputation', 'cosmic influence'],
      demands: ['peace', 'knowledge sharing'],
      tradedResources: {
        [ResourceType.REPUTATION]: 25,
        [ResourceType.ENERGY]: -20
      },
      resources: [ResourceType.REPUTATION, ResourceType.COSMIC_INFLUENCE],
      technology: 4,
      militaryPower: 2,
      lastContact: Date.now() - 1000 * 60 * 30, // 30 minutes ago
      traits: ['spiritual', 'diplomatic', 'mysterious']
    }
  ];

  // Define diplomatic actions
  const diplomaticActions: DiplomaticAction[] = [
    {
      id: 'establish-embassy',
      name: 'Establish Embassy',
      description: 'Establish a permanent diplomatic presence, improving relations and enabling more actions.',
      cost: {
        [ResourceType.REPUTATION]: 20,
        [ResourceType.MINERALS]: 1000,
        [ResourceType.ENERGY]: 500
      },
      attitudeEffect: 15,
      requiredAttitude: -10,
      cooldown: 100
    },
    {
      id: 'share-research',
      name: 'Share Research Data',
      description: 'Share scientific discoveries to improve relations. May receive research in return.',
      cost: {
        [ResourceType.RESEARCH]: 100
      },
      attitudeEffect: 10,
      requiredAttitude: 0,
      cooldown: 50
    },
    {
      id: 'cultural-exchange',
      name: 'Cultural Exchange Program',
      description: 'Exchange cultural knowledge and practices, deepening mutual understanding.',
      cost: {
        [ResourceType.REPUTATION]: 10,
        [ResourceType.ENERGY]: 200
      },
      attitudeEffect: 5,
      requiredAttitude: 10,
      cooldown: 30
    },
    {
      id: 'military-exercise',
      name: 'Joint Military Exercise',
      description: 'Conduct joint military exercises to build trust and learn tactics.',
      cost: {
        [ResourceType.ENERGY]: 300,
        [ResourceType.HYDROGEN]: 50
      },
      attitudeEffect: 8,
      requiredAttitude: 20,
      cooldown: 60
    },
    {
      id: 'technology-transfer',
      name: 'Technology Transfer',
      description: 'Transfer advanced technology in exchange for resources or other technologies.',
      cost: {
        [ResourceType.RESEARCH]: 200,
        [ResourceType.RARE_METALS]: 50
      },
      attitudeEffect: 12,
      requiredAttitude: 25,
      cooldown: 80
    },
    {
      id: 'issue-demands',
      name: 'Issue Demands',
      description: 'Demand resources or concessions, potentially damaging relations.',
      cost: {
        [ResourceType.REPUTATION]: 15
      },
      attitudeEffect: -20,
      requiredAttitude: -100, // Can always make demands
      cooldown: 40
    },
    {
      id: 'send-aid',
      name: 'Send Humanitarian Aid',
      description: 'Provide resources to assist during crises, improving relations significantly.',
      cost: {
        [ResourceType.MINERALS]: 500,
        [ResourceType.ENERGY]: 300,
        [ResourceType.HYDROGEN]: 50
      },
      attitudeEffect: 25,
      requiredAttitude: -20,
      cooldown: 70
    }
  ];

  // Generate trade agreements based on each civilization's needs and resources
  const generateTradeAgreements = (civilization: Civilization): TradeAgreement[] => {
    const agreements: TradeAgreement[] = [];
    
    // Basic trades based on civilization's specialties and demands
    civilization.specialties.forEach(specialty => {
      let resource: string;
      switch (specialty) {
        case 'research':
          resource = ResourceType.RESEARCH;
          break;
        case 'minerals':
          resource = ResourceType.MINERALS;
          break;
        case 'reputation':
          resource = ResourceType.REPUTATION;
          break;
        case 'military':
          resource = ResourceType.RARE_METALS;
          break;
        case 'cosmic influence':
          resource = ResourceType.COSMIC_INFLUENCE;
          break;
        case 'technology':
          resource = ResourceType.RESEARCH;
          break;
        default:
          resource = ResourceType.ENERGY;
      }
      
      agreements.push({
        id: `basic-${specialty}`,
        name: `Basic ${specialty.charAt(0).toUpperCase() + specialty.slice(1)} Trade`,
        description: `A simple trade agreement acquiring their ${specialty}.`,
        give: {
          [civilization.demands[0] === 'rare metals' ? ResourceType.RARE_METALS : ResourceType.ENERGY]: 20
        },
        receive: {
          [resource]: 30
        },
        duration: 100,
        value: 1.5 // They give 1.5x what they receive
      });
    });
    
    // Add more complex trade if friendly or allied
    if (civilization.relationshipStatus === 'friendly' || civilization.relationshipStatus === 'allied') {
      agreements.push({
        id: 'comprehensive-deal',
        name: 'Comprehensive Trade Deal',
        description: 'A broad trade agreement covering multiple resources with favorable terms.',
        give: {
          [ResourceType.MINERALS]: 100,
          [ResourceType.ENERGY]: 50
        },
        receive: {
          [civilization.resources[0]]: 80,
          [civilization.resources[1]]: 50,
          [ResourceType.REPUTATION]: 5
        },
        duration: 200,
        value: 2 // Very good value
      });
    }
    
    // Add resource-specific trade if their attitude is positive
    if (civilization.attitude > 0) {
      const mainResource = civilization.resources[0];
      agreements.push({
        id: `${mainResource}-exclusive`,
        name: `${mainResource.charAt(0).toUpperCase() + mainResource.slice(1)} Exclusive Deal`,
        description: `A specialized trade focused on acquiring ${mainResource}.`,
        give: {
          [ResourceType.REPUTATION]: 10,
          [civilization.demands[0] === 'rare metals' ? ResourceType.RARE_METALS : ResourceType.MINERALS]: 50
        },
        receive: {
          [mainResource]: 100
        },
        duration: 150,
        value: 1.2
      });
    }
    
    return agreements;
  };

  // Find the selected civilization
  const selectedCivilizationData = civilizations.find(civ => civ.id === selectedCivilization);
  
  // Generate trade agreements for selected civilization
  const availableTradeAgreements = selectedCivilizationData 
    ? generateTradeAgreements(selectedCivilizationData) 
    : [];
  
  // Find selected trade agreement
  const selectedTradeAgreement = availableTradeAgreements.find(agreement => agreement.id === selectedTrade);
  
  // Find selected diplomatic action
  const selectedActionData = diplomaticActions.find(action => action.id === selectedAction);
  
  // Check if player can afford an action
  const canAffordAction = (action: DiplomaticAction): boolean => {
    return Object.entries(action.cost).every(
      ([resource, amount]) => (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
    );
  };
  
  // Check if player meets attitude requirement for an action
  const meetsAttitudeRequirement = (action: DiplomaticAction, civilization: Civilization): boolean => {
    return civilization.attitude >= action.requiredAttitude;
  };
  
  // Handle diplomatic action execution
  const handleExecuteAction = () => {
    if (selectedCivilization && selectedAction && selectedCivilizationData) {
      const action = diplomaticActions.find(a => a.id === selectedAction);
      if (action && canAffordAction(action) && meetsAttitudeRequirement(action, selectedCivilizationData)) {
        onDiplomaticAction(selectedCivilization, selectedAction);
      }
    }
  };
  
  // Handle trade agreement acceptance
  const handleAcceptTrade = () => {
    if (selectedCivilization && selectedTrade && selectedTradeAgreement) {
      onDiplomaticAction(selectedCivilization, 'trade', selectedTrade);
    }
  };
  
  // Handle custom resource trade
  const handleResourceTrade = () => {
    if (selectedCivilization && tradeResource && tradeAmount > 0) {
      onDiplomaticAction(selectedCivilization, 'resourceTrade', { resource: tradeResource, amount: tradeAmount });
    }
  };
  
  // Determine relationship color based on status
  const getRelationshipColor = (status: string): string => {
    switch (status) {
      case 'hostile': return 'text-red-400';
      case 'neutral': return 'text-yellow-400';
      case 'friendly': return 'text-green-400';
      case 'allied': return 'text-blue-400';
      default: return 'text-gray-400';
    }
  };
  
  // Calculates time difference in a readable format
  const getTimeSinceContact = (timestamp: number): string => {
    const now = Date.now();
    const diffInSeconds = Math.floor((now - timestamp) / 1000);
    
    if (diffInSeconds < 60) return `${diffInSeconds}s ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
    return `${Math.floor(diffInSeconds / 86400)}d ago`;
  };

  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 border-b border-cyan-800 pb-1">Diplomacy Network</h2>
      
      {/* Civilization selection - toggle between grid and list */}
      <div className="mb-3">
        <div className="flex justify-between items-center mb-2">
          <h3 className="font-bold">Known Civilizations</h3>
          <button
            className="px-2 py-1 bg-gray-700 hover:bg-gray-600 rounded text-xs"
            onClick={() => setShowContacts(!showContacts)}
          >
            {showContacts ? 'Hide' : 'Show'} Contacts
          </button>
        </div>
        
        {showContacts && (
          <div className={`grid ${isCompact ? 'grid-cols-1 gap-1' : 'grid-cols-2 gap-2'}`}>
            {civilizations.map(civilization => (
              <button
                key={civilization.id}
                className={`p-2 rounded text-sm text-left ${
                  selectedCivilization === civilization.id 
                    ? 'bg-indigo-800 border border-indigo-600' 
                    : 'bg-gray-700 hover:bg-gray-600'
                }`}
                onClick={() => setSelectedCivilization(civilization.id)}
              >
                <div className="font-bold">{civilization.name}</div>
                <div className="flex justify-between">
                  <span className={getRelationshipColor(civilization.relationshipStatus)}>
                    {civilization.relationshipStatus.charAt(0).toUpperCase() + civilization.relationshipStatus.slice(1)}
                  </span>
                  <span className="text-gray-400">
                    {getTimeSinceContact(civilization.lastContact)}
                  </span>
                </div>
                <div className="text-xs text-gray-300">
                  <span>Tech: {civilization.technology}</span> | 
                  <span> Power: {civilization.militaryPower}</span>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
      
      {/* Selected civilization details */}
      {selectedCivilizationData && (
        <div className="p-3 bg-gray-700 rounded mb-3">
          <h3 className="font-bold">{selectedCivilizationData.name}</h3>
          
          {/* Only show description in non-compact mode */}
          {!isCompact && (
            <p className="text-sm text-gray-300 mb-2">{selectedCivilizationData.description}</p>
          )}
          
          {/* Relationship stats grid */}
          <div className="grid grid-cols-2 gap-2 mb-2">
            <div>
              <div className="text-xs font-bold">Relationship:</div>
              <div className={getRelationshipColor(selectedCivilizationData.relationshipStatus)}>
                {selectedCivilizationData.relationshipStatus.charAt(0).toUpperCase() + 
                 selectedCivilizationData.relationshipStatus.slice(1)}
              </div>
            </div>
            <div>
              <div className="text-xs font-bold">Attitude:</div>
              <div className={`${
                selectedCivilizationData.attitude > 50 ? 'text-green-400' : 
                selectedCivilizationData.attitude > 0 ? 'text-yellow-400' : 
                selectedCivilizationData.attitude > -50 ? 'text-orange-400' : 
                'text-red-400'
              }`}>
                {selectedCivilizationData.attitude > 0 ? '+' : ''}{selectedCivilizationData.attitude}
              </div>
            </div>
            <div>
              <div className="text-xs font-bold">Trade Status:</div>
              <div className="text-blue-300">
                {selectedCivilizationData.tradeStatus.charAt(0).toUpperCase() + 
                 selectedCivilizationData.tradeStatus.slice(1)}
              </div>
            </div>
            <div>
              <div className="text-xs font-bold">Technology:</div>
              <div className="text-purple-300">Level {selectedCivilizationData.technology}</div>
            </div>
          </div>
          
          {/* Compact characteristics */}
          <div className="mb-3">
            <div className="text-xs font-bold mb-1">Traits:</div>
            <div className="flex flex-wrap gap-1">
              {selectedCivilizationData.traits.map(trait => (
                <span 
                  key={trait} 
                  className="px-2 py-0.5 bg-gray-800 rounded-full text-xs"
                >
                  {trait}
                </span>
              ))}
            </div>
          </div>
          
          <div className="mb-3">
            <div className="text-xs font-bold mb-1">Specializes in:</div>
            <div className="flex flex-wrap gap-1">
              {selectedCivilizationData.specialties.map(specialty => (
                <span 
                  key={specialty} 
                  className="px-2 py-0.5 bg-gray-800 rounded-full text-xs text-green-400"
                >
                  {specialty}
                </span>
              ))}
            </div>
          </div>
          
          <div className="mb-3">
            <div className="text-xs font-bold mb-1">Demands:</div>
            <div className="flex flex-wrap gap-1">
              {selectedCivilizationData.demands.map(demand => (
                <span 
                  key={demand} 
                  className="px-2 py-0.5 bg-gray-800 rounded-full text-xs text-yellow-400"
                >
                  {demand}
                </span>
              ))}
            </div>
          </div>
          
          {/* Trade data - in collapsible section in compact mode */}
          {isCompact ? (
            <CollapsibleSection title="Current Trade" isOpen={false}>
              {Object.keys(selectedCivilizationData.tradedResources).length > 0 ? (
                <div className="grid grid-cols-2 gap-1 text-xs">
                  {Object.entries(selectedCivilizationData.tradedResources).map(([resource, amount]) => (
                    <div key={resource}>
                      {resource}: <span className={`${amount > 0 ? 'text-green-400' : 'text-red-400'}`}>
                        {amount > 0 ? '+' : ''}{formatNumber(amount)}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-xs text-yellow-400">No active trade agreements</div>
              )}
            </CollapsibleSection>
          ) : (
            <div className="mb-3">
              <div className="text-xs font-bold mb-1">Current Trade:</div>
              {Object.keys(selectedCivilizationData.tradedResources).length > 0 ? (
                <div className="grid grid-cols-3 gap-2 text-xs">
                  {Object.entries(selectedCivilizationData.tradedResources).map(([resource, amount]) => (
                    <div key={resource}>
                      {resource}: <span className={`${amount > 0 ? 'text-green-400' : 'text-red-400'}`}>
                        {amount > 0 ? '+' : ''}{formatNumber(amount)}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-yellow-400">No active trade agreements</div>
              )}
            </div>
          )}
          
          {/* Tabs for Actions and Trade */}
          <div className="flex border-b border-gray-600 mb-2">
            <button
              className="px-3 py-1 text-sm border-b-2 border-indigo-500"
              onClick={() => setSelectedAction(null)}
            >
              Actions
            </button>
            <button
              className="px-3 py-1 text-sm"
              onClick={() => setSelectedTrade(null)}
            >
              Trade
            </button>
          </div>
          
          {/* Diplomatic Actions */}
          {selectedAction === null && (
            <div className="space-y-2">
              {diplomaticActions.map(action => (
                <button
                  key={action.id}
                  className={`p-2 w-full text-left rounded text-sm ${
                    !meetsAttitudeRequirement(action, selectedCivilizationData)
                      ? 'bg-gray-800 opacity-50 cursor-not-allowed'
                      : canAffordAction(action)
                        ? 'bg-gray-800 hover:bg-gray-700'
                        : 'bg-gray-800 opacity-70 cursor-not-allowed'
                  }`}
                  onClick={() => {
                    if (meetsAttitudeRequirement(action, selectedCivilizationData) && canAffordAction(action)) {
                      setSelectedAction(action.id);
                    }
                  }}
                  disabled={!meetsAttitudeRequirement(action, selectedCivilizationData) || !canAffordAction(action)}
                >
                  <div className="font-bold">{action.name}</div>
                  <div className="text-xs flex justify-between">
                    <span className={`${action.attitudeEffect > 0 ? 'text-green-400' : 'text-red-400'}`}>
                      Attitude: {action.attitudeEffect > 0 ? '+' : ''}{action.attitudeEffect}
                    </span>
                    <span className="text-gray-400">
                      Req: {action.requiredAttitude}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          )}
          
          {/* Selected Action Details */}
          {selectedActionData && (
            <div className="p-2 bg-gray-800 rounded">
              <h4 className="font-bold">{selectedActionData.name}</h4>
              <p className="text-xs text-gray-300 mb-2">{selectedActionData.description}</p>
              
              <div className="grid grid-cols-2 gap-1 mb-2 text-xs">
                <div>
                  <div className="font-bold">Cost:</div>
                  {Object.entries(selectedActionData.cost).map(([resource, amount]) => (
                    <div 
                      key={resource}
                      className={`${
                        (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
                          ? 'text-green-400'
                          : 'text-red-400'
                      }`}
                    >
                      {resource}: {formatNumber(amount)}
                    </div>
                  ))}
                </div>
                <div>
                  <div className="font-bold">Effects:</div>
                  <div className={`${selectedActionData.attitudeEffect > 0 ? 'text-green-400' : 'text-red-400'}`}>
                    Attitude: {selectedActionData.attitudeEffect > 0 ? '+' : ''}{selectedActionData.attitudeEffect}
                  </div>
                  <div className="text-blue-300">
                    Cooldown: {selectedActionData.cooldown} ticks
                  </div>
                </div>
              </div>
              
              <div className="flex space-x-2">
                <button
                  className="flex-1 px-2 py-1 bg-indigo-700 hover:bg-indigo-600 disabled:bg-gray-600 disabled:opacity-50 rounded text-sm"
                  onClick={handleExecuteAction}
                  disabled={!canAffordAction(selectedActionData)}
                >
                  Execute Action
                </button>
                <button
                  className="px-2 py-1 bg-gray-700 hover:bg-gray-600 rounded text-sm"
                  onClick={() => setSelectedAction(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          
          {/* Trade Agreements */}
          {selectedTrade === null && selectedAction === null && (
            <div>
              <h4 className="font-bold mb-1">Trade Agreements</h4>
              {selectedCivilizationData.tradeStatus !== 'none' ? (
                <div className="space-y-2">
                  {availableTradeAgreements.map(agreement => (
                    <button
                      key={agreement.id}
                      className="p-2 w-full text-left rounded text-sm bg-gray-800 hover:bg-gray-700"
                      onClick={() => setSelectedTrade(agreement.id)}
                    >
                      <div className="font-bold">{agreement.name}</div>
                      <div className="text-xs flex justify-between">
                        <span>Duration: {agreement.duration} ticks</span>
                        <span className="text-green-400">Value: {agreement.value}x</span>
                      </div>
                    </button>
                  ))}
                  
                  {/* Custom resource trade option */}
                  <div className="p-2 bg-gray-800 rounded mt-2">
                    <h4 className="font-bold text-xs mb-1">Custom Resource Trade</h4>
                    <div className="flex space-x-2 mb-2">
                      <select
                        className="flex-1 bg-gray-700 border border-gray-600 rounded px-2 py-1 text-xs"
                        value={tradeResource}
                        onChange={(e) => setTradeResource(e.target.value)}
                      >
                        {Object.values(ResourceType).map(resource => (
                          <option key={resource} value={resource}>
                            {resource}
                          </option>
                        ))}
                      </select>
                      <input
                        type="number"
                        className="w-20 bg-gray-700 border border-gray-600 rounded px-2 py-1 text-xs"
                        value={tradeAmount}
                        onChange={(e) => setTradeAmount(Math.max(1, parseInt(e.target.value) || 1))}
                        min="1"
                      />
                    </div>
                    <button
                      className="w-full px-2 py-1 bg-blue-700 hover:bg-blue-600 rounded text-xs"
                      onClick={handleResourceTrade}
                    >
                      Offer Trade
                    </button>
                  </div>
                </div>
              ) : (
                <div className="text-red-400 text-sm">
                  This civilization refuses to trade with you at this time.
                </div>
              )}
            </div>
          )}
          
          {/* Selected Trade Agreement Details */}
          {selectedTradeAgreement && (
            <div className="p-2 bg-gray-800 rounded">
              <h4 className="font-bold">{selectedTradeAgreement.name}</h4>
              <p className="text-xs text-gray-300 mb-2">{selectedTradeAgreement.description}</p>
              
              <div className="grid grid-cols-2 gap-1 mb-2 text-xs">
                <div>
                  <div className="font-bold">You Give:</div>
                  {Object.entries(selectedTradeAgreement.give).map(([resource, amount]) => (
                    <div 
                      key={resource}
                      className={`${
                        (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
                          ? 'text-yellow-400'
                          : 'text-red-400'
                      }`}
                    >
                      {resource}: {formatNumber(amount)}
                    </div>
                  ))}
                </div>
                <div>
                  <div className="font-bold">You Receive:</div>
                  {Object.entries(selectedTradeAgreement.receive).map(([resource, amount]) => (
                    <div key={resource} className="text-green-400">
                      {resource}: {formatNumber(amount)}
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="flex justify-between text-xs mb-2">
                <span>Duration: {selectedTradeAgreement.duration} ticks</span>
                <span className="text-green-400">Value: {selectedTradeAgreement.value}x</span>
              </div>
              
              <div className="flex space-x-2">
                <button
                  className="flex-1 px-2 py-1 bg-blue-700 hover:bg-blue-600 rounded text-sm"
                  onClick={handleAcceptTrade}
                >
                  Accept Trade
                </button>
                <button
                  className="px-2 py-1 bg-gray-700 hover:bg-gray-600 rounded text-sm"
                  onClick={() => setSelectedTrade(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      
      {/* Diplomacy tips - only in non-compact mode */}
      {!isCompact && (
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-bold mb-1">Diplomacy Tips</h3>
          <div className="text-sm text-gray-300">
            <p>Effective diplomacy is crucial for interstellar prosperity:</p>
            <ul className="list-disc list-inside mt-1 text-xs">
              <li>Different civilizations have unique traits and preferences</li>
              <li>Maintain positive relations to access better trade agreements</li>
              <li>Match your diplomatic actions to each civilization's culture</li>
              <li>Trade agreements provide ongoing benefits that outweigh costs</li>
              <li>Aggressive actions may yield short-term gains but long-term consequences</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiplomacyPanel;