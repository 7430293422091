import React, { useState } from 'react';
import { GameState } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber } from '../utils/formatters';
import { PHASE_REQUIREMENTS } from '../game/GameState';
import CollectButton from '../components/CollectButton';
import CollapsibleSection from '../components/CollapsibleSection';

interface InterstellarProps {
  gameState: GameState;
  onCollectResource: (resource: string, amount: number, event?: React.MouseEvent | React.TouchEvent) => void;
  isCompact?: boolean;
}

interface StarSystem {
  id: string;
  name: string;
  description: string;
  distance: number; // Light years
  specialResource: ResourceType;
  amountPerClick: number;
  secondaryResources: { resource: ResourceType; amount: number }[];
  image: string;
  importance: 'critical' | 'major' | 'minor';
}

const Interstellar: React.FC<InterstellarProps> = ({ 
  gameState, 
  onCollectResource,
  isCompact = false
}) => {
  const [selectedSystem, setSelectedSystem] = useState<string>('proxima-centauri');
  
  // Define star systems
  const starSystems: StarSystem[] = [
    {
      id: 'proxima-centauri',
      name: 'Proxima Centauri',
      description: 'The closest star to the solar system. A red dwarf with a rocky exoplanet.',
      distance: 4.2,
      specialResource: ResourceType.RARE_METALS,
      amountPerClick: 20,
      secondaryResources: [
        { resource: ResourceType.MINERALS, amount: 50 },
        { resource: ResourceType.ENERGY, amount: 25 }
      ],
      image: 'proxima-centauri',
      importance: 'minor'
    },
    {
      id: 'barnards-star',
      name: "Barnard's Star",
      description: 'A very low-mass red dwarf star, rich in hydrogen and helium.',
      distance: 6.0,
      specialResource: ResourceType.HYDROGEN,
      amountPerClick: 50,
      secondaryResources: [
        { resource: ResourceType.HELIUM, amount: 20 },
        { resource: ResourceType.ENERGY, amount: 30 }
      ],
      image: 'barnards-star',
      importance: 'major'
    },
    {
      id: 'sirius',
      name: 'Sirius System',
      description: 'The brightest star in the night sky. A binary system with abundant energy.',
      distance: 8.6,
      specialResource: ResourceType.ENERGY,
      amountPerClick: 100,
      secondaryResources: [
        { resource: ResourceType.HELIUM, amount: 15 },
        { resource: ResourceType.RESEARCH, amount: 10 }
      ],
      image: 'sirius',
      importance: 'major'
    },
    {
      id: 'tau-ceti',
      name: 'Tau Ceti',
      description: 'A Sun-like star with potential habitable planets. Good for research.',
      distance: 12,
      specialResource: ResourceType.RESEARCH,
      amountPerClick: 30,
      secondaryResources: [
        { resource: ResourceType.MINERALS, amount: 40 },
        { resource: ResourceType.REPUTATION, amount: 2 }
      ],
      image: 'tau-ceti',
      importance: 'major'
    },
    {
      id: 'neutron-star',
      name: 'PSR B1257+12',
      description: 'A neutron star with traces of antimatter in its vicinity.',
      distance: 980,
      specialResource: ResourceType.ANTIMATTER,
      amountPerClick: 6,
      secondaryResources: [
        { resource: ResourceType.ENERGY, amount: 80 },
        { resource: ResourceType.RESEARCH, amount: 15 }
      ],
      image: 'neutron-star',
      importance: 'critical'
    }
  ];
  
  // Find the selected star system
  const selectedSystemData = starSystems.find(system => system.id === selectedSystem);
  
  // Requirements to progress to next phase
  const nextPhaseRequirements = PHASE_REQUIREMENTS.GALACTIC_TRANSCENDENCE;
  
  // Calculate progress percentages
  const antimatterProgress = Math.min(100, (gameState.resources.antimatter / nextPhaseRequirements.antimatter) * 100);
  const researchProgress = Math.min(100, (gameState.resources.research / nextPhaseRequirements.research) * 100);
  
  // Calculate overall progress
  const overallProgress = (antimatterProgress + researchProgress) / 2;
  
  // Get the critical system for current progress
  const getCriticalSystem = (): string => {
    // If antimatter is less than 50% complete, focus on the neutron star
    if (antimatterProgress < 50) {
      return 'neutron-star';
    }
    // If research is less than 70% complete, focus on Tau Ceti
    if (researchProgress < 70) {
      return 'tau-ceti';
    }
    // Otherwise, focus on energy production
    return 'sirius';
  };
  
  const criticalSystem = getCriticalSystem();
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2">Interstellar Operations</h2>
      
      {/* Description - collapsible in compact mode */}
      {isCompact ? (
        <CollapsibleSection title="About This Phase" isOpen={false}>
          <p className="text-gray-300 mb-2">
            Your civilization now spans multiple star systems. Interstellar travel has opened up access 
            to exotic resources and phenomena not available in your home system.
          </p>
        </CollapsibleSection>
      ) : (
        <p className="text-gray-300 mb-4">
          Your civilization now spans multiple star systems. Interstellar travel has opened up access 
          to exotic resources and phenomena not available in your home system.
        </p>
      )}
      
      {/* Star system selection - keep visible but compact */}
      <div className={`grid grid-cols-3 md:grid-cols-5 gap-2 mb-3`}>
        {starSystems.map(system => (
          <button
            key={system.id}
            className={`p-2 rounded text-sm text-center ${
              system.id === selectedSystem 
                ? 'bg-cyan-800 border border-cyan-600' 
                : system.id === criticalSystem
                  ? 'bg-purple-800 hover:bg-purple-700' // Highlight critical system
                  : 'bg-gray-700 hover:bg-gray-600'
            }`}
            onClick={() => setSelectedSystem(system.id)}
          >
            {system.name}
            {!isCompact && (
              <div className="text-xs text-gray-400">{system.distance} LY</div>
            )}
            {system.id === criticalSystem && (
              <div className="text-xs text-purple-300 mt-1">Priority</div>
            )}
          </button>
        ))}
      </div>
      
      {/* Selected system details - simplify in compact mode */}
      {selectedSystemData && (
        <div className="p-3 bg-gray-700 rounded">
          <h3 className="font-bold text-lg">{selectedSystemData.name}</h3>
          
          {/* Description - hide in compact mode */}
          {!isCompact && (
            <>
              <p className="text-sm text-gray-300 mb-1">{selectedSystemData.description}</p>
              <p className="text-xs text-gray-400 mb-3">Distance: {selectedSystemData.distance} light years</p>
            </>
          )}

          <CollectButton
            resource={selectedSystemData.specialResource}
            amount={selectedSystemData.amountPerClick}
            onClick={onCollectResource}
            className="px-4 py-2 bg-cyan-800 hover:bg-cyan-700 rounded transition-colors w-full min-h-[44px] mb-2"
          >
            Harvest {selectedSystemData.specialResource} (+{selectedSystemData.amountPerClick})
          </CollectButton>
          
          <div className="mt-2 text-xs text-gray-300">
            {!isCompact && (
              <div className="font-bold mb-1">Other Resources Available:</div>
            )}
            <div className="grid grid-cols-2 gap-1">
              {selectedSystemData.secondaryResources.map(secondary => (
                <CollectButton
                  key={secondary.resource}
                  resource={secondary.resource}
                  amount={secondary.amount}
                  onClick={onCollectResource}
                  className="px-3 py-2 bg-cyan-800 hover:bg-cyan-700 rounded transition-colors w-full min-h-[40px]"
                >
                  {secondary.resource} (+{secondary.amount})
                </CollectButton>
              ))}
            </div>
          </div>
          
          {/* Display strategic importance only in non-compact mode */}
          {!isCompact && (
            <div className="mt-3 text-xs">
              <span className="font-bold">Strategic Importance: </span>
              <span className={
                selectedSystemData.importance === 'critical' ? 'text-red-400' :
                selectedSystemData.importance === 'major' ? 'text-yellow-400' :
                'text-green-400'
              }>
                {selectedSystemData.importance.toUpperCase()}
              </span>
              
              {/* System-specific tips */}
              {selectedSystemData.id === 'neutron-star' && (
                <div className="mt-1 text-purple-300">
                  Tip: Antimatter is crucial for reaching the final phase. Focus on building Antimatter Collectors.
                </div>
              )}
              {selectedSystemData.id === 'tau-ceti' && (
                <div className="mt-1 text-blue-300">
                  Tip: Research from this system accelerates technological progress significantly.
                </div>
              )}
            </div>
          )}
        </div>
      )}
      
      <div className="mt-4">
        <h3 className="font-bold text-cyan-300 mb-2">Progress to Galactic Transcendence</h3>
        
        {/* Overall progress bar */}
        <div className="mb-3">
          <div className="flex justify-between text-sm mb-1">
            <span>Overall Progress</span>
            <span>{Math.floor(overallProgress)}%</span>
          </div>
          <div className="w-full h-3 bg-gray-700 rounded-full overflow-hidden">
            <div 
              className="h-full bg-gradient-to-r from-purple-600 to-pink-500" 
              style={{ width: `${overallProgress}%` }}
            />
          </div>
        </div>
        
        <div className="space-y-2">
          <div>
            <div className="flex justify-between text-sm mb-1">
              <span>Antimatter</span>
              <span>
                {formatNumber(gameState.resources.antimatter)} / {formatNumber(nextPhaseRequirements.antimatter)}
              </span>
            </div>
            <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
              <div 
                className="h-full bg-pink-600" 
                style={{ width: `${antimatterProgress}%` }}
              />
            </div>
          </div>
          
          <div>
            <div className="flex justify-between text-sm mb-1">
              <span>Research</span>
              <span>
                {formatNumber(gameState.resources.research)} / {formatNumber(nextPhaseRequirements.research)}
              </span>
            </div>
            <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
              <div 
                className="h-full bg-blue-600" 
                style={{ width: `${researchProgress}%` }}
              />
            </div>
          </div>
        </div>
      </div>
      
      {/* Strategy guide - collapsible in compact mode */}
      {isCompact ? (
        <div className="mt-3">
          <CollapsibleSection title="Strategy Guide" isOpen={false}>
            <ul className="list-disc list-inside text-xs text-gray-300 mt-1">
              <li>Build Antimatter Collectors to reach the Antimatter threshold</li>
              <li>Focus on Research upgrades to maximize Research Station output</li>
              <li>Use the PSR B1257+12 system to collect Antimatter regularly</li>
            </ul>
          </CollapsibleSection>
        </div>
      ) : (
        <div className="mt-4">
          <div className="bg-gray-700 p-3 rounded">
            <h4 className="text-sm font-bold text-cyan-300">Phase 3 Strategy Guide</h4>
            <ul className="list-disc list-inside text-xs text-gray-300 mt-1">
              <li>Build 5-8 Antimatter Collectors to reach the Antimatter threshold</li>
              <li>Focus on Research upgrades to maximize Research Station output</li>
              <li>Use the PSR B1257+12 system to manually collect Antimatter regularly</li>
              <li>Upgrade Dyson Nodes to increase energy production efficiency</li>
              <li>Invest market profits in Antimatter technology research</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Interstellar;