import React from 'react';
import { GameState } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber } from '../utils/formatters';
import { PHASE_REQUIREMENTS } from '../game/GameState';
import CollectButton from '../components/CollectButton';
import CollapsibleSection from '../components/CollapsibleSection';

interface AsteroidMiningProps {
  gameState: GameState;
  onCollectResource: (resource: string, amount: number, event?: React.MouseEvent | React.TouchEvent) => void;
  isCompact?: boolean;
}

const AsteroidMining: React.FC<AsteroidMiningProps> = ({ 
  gameState, 
  onCollectResource,
  isCompact = false
}) => {
  // Check if automation is unlocked
  const automationUnlocked = gameState.unlocks.automationUnlocked;
  
  // Requirements to progress to next phase (imported from GameState)
  const nextPhaseRequirements = {
    minerals: PHASE_REQUIREMENTS.SOLAR_SYSTEM.minerals,
    energy: PHASE_REQUIREMENTS.SOLAR_SYSTEM.energy,
    research: PHASE_REQUIREMENTS.SOLAR_SYSTEM.research
  };
  
  // Calculate progress percentages
  const mineralProgress = Math.min(100, (gameState.resources.minerals / nextPhaseRequirements.minerals) * 100);
  const energyProgress = Math.min(100, (gameState.resources.energy / nextPhaseRequirements.energy) * 100);
  const researchProgress = Math.min(100, (gameState.resources.research / nextPhaseRequirements.research) * 100);

  // Calculate the combined progress percentage
  const overallProgress = (mineralProgress + energyProgress + researchProgress) / 3;
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-3">Asteroid Mining Operations</h2>
      
      {/* Description - collapsible on mobile */}
      {isCompact ? (
        <CollapsibleSection title="About This Phase" isOpen={false}>
          <p className="text-gray-300 mb-2">
            Your mining outpost extracts valuable minerals from this asteroid. Develop technology, 
            expand your operations, and prepare for solar system exploration.
          </p>
        </CollapsibleSection>
      ) : (
        <div className="mb-4">
          <p className="text-gray-300 mb-2">
            Your mining outpost extracts valuable minerals from this asteroid. Develop technology, 
            expand your operations, and prepare for solar system exploration.
          </p>
        </div>
      )}
      
      {!automationUnlocked && (
        <div className="space-y-2 mt-3">
          <CollectButton
            resource={ResourceType.MINERALS}
            amount={2}
            onClick={onCollectResource}
            className="px-4 py-2 bg-cyan-800 hover:bg-cyan-700 rounded transition-colors w-full min-h-[44px]"
          >
            Mine Minerals Manually (+2)
          </CollectButton>
          
          {/* Hide tip on mobile compact mode */}
          {!isCompact && (
            <div className="text-sm text-gray-400">
              Tip: Purchase the Basic Mineral Drill upgrade to unlock Mineral Miners.
            </div>
          )}
        </div>
      )}

      
      <div className="mt-4">
        <h3 className="font-bold text-cyan-300 mb-2">Progress to Solar System Expansion</h3>
        
        {/* Overall progress bar */}
        <div className="mb-3">
          <div className="flex justify-between text-sm mb-1">
            <span>Overall Progress</span>
            <span>{Math.floor(overallProgress)}%</span>
          </div>
          <div className="w-full h-3 bg-gray-700 rounded-full overflow-hidden">
            <div 
              className="h-full bg-gradient-to-r from-cyan-600 to-cyan-400" 
              style={{ width: `${overallProgress}%` }}
            />
          </div>
        </div>
        
        <div className="space-y-3">
          <div>
            <div className="flex justify-between text-sm mb-1">
              <span>Minerals</span>
              <span>
                {formatNumber(gameState.resources.minerals)} / {formatNumber(nextPhaseRequirements.minerals)}
              </span>
            </div>
            <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
              <div 
                className="h-full bg-cyan-600" 
                style={{ width: `${mineralProgress}%` }}
              />
            </div>
          </div>
            
          <div>
            <div className="flex justify-between text-sm mb-1">
              <span>Energy</span>
              <span>
                {formatNumber(gameState.resources.energy)} / {formatNumber(nextPhaseRequirements.energy)}
              </span>
            </div>
            <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
              <div 
                className="h-full bg-yellow-600" 
                style={{ width: `${energyProgress}%` }}
              />
            </div>
          </div>
            
          <div>
            <div className="flex justify-between text-sm mb-1">
              <span>Research</span>
              <span>
                {formatNumber(gameState.resources.research)} / {formatNumber(nextPhaseRequirements.research)}
              </span>
            </div>
            <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
              <div 
                className="h-full bg-blue-600" 
                style={{ width: `${researchProgress}%` }}
              />
            </div>
          </div>
        </div>
      </div>
      
      {/* Status message - always visible */}
      <div className="mt-4 text-sm text-gray-400">
        {automationUnlocked ? (
          <div className="text-green-400">
            <span className="font-bold">Automation Active:</span> Your systems are automatically mining resources.
          </div>
        ) : (
          <div>
            <span className="font-bold">Tip:</span> Purchase the "Automated Mining System" upgrade to enable automation.
          </div>
        )}
      </div>
      
      {/* Getting Started Guide - collapsible in compact mode */}
      {isCompact ? (
        <div className="mt-3">
          <CollapsibleSection title="Getting Started Guide" isOpen={false}>
            <ul className="list-disc list-inside text-sm text-gray-300 mt-1">
              <li>First, buy the <span className="text-cyan-300">Basic Mineral Drill</span> upgrade</li>
              <li>Then purchase the <span className="text-cyan-300">Solar Collection</span> upgrade</li>
              <li>Once you have energy, get the <span className="text-cyan-300">Scientific Method</span> upgrade</li>
              <li>Continue buying upgrades to boost your production</li>
            </ul>
          </CollapsibleSection>
        </div>
      ) : (
        <div className="mt-2 p-3 bg-gray-700 rounded">
          <h3 className="font-bold">Getting Started Guide</h3>
          <ul className="list-disc list-inside text-sm text-gray-300 mt-1">
            <li>First, buy the <span className="text-cyan-300">Basic Mineral Drill</span> upgrade to unlock Mineral Miners</li>
            <li>Then purchase the <span className="text-cyan-300">Solar Collection</span> upgrade to unlock Solar Panels</li>
            <li>Once you have energy, get the <span className="text-cyan-300">Scientific Method</span> upgrade to unlock Research Stations</li>
            <li>Continue buying upgrades to boost your production and unlock more capabilities</li>
            <li>Aim to meet the requirements for Solar System Expansion shown above</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default AsteroidMining;