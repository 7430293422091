import React, { useState } from 'react';
import { GameState } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber, formatRate } from '../utils/formatters';
import { getResourceProduction } from '../game/GameState';

interface ResourceDisplayProps {
  gameState: GameState;
}

// Resource metadata
const resourceMeta: Record<string, { name: string, color: string, showInPhase: number, icon: string, shortName: string }> = {
  [ResourceType.MINERALS]: { name: 'Minerals', shortName: 'Min', color: 'text-gray-300', showInPhase: 1, icon: '💎' },
  [ResourceType.ENERGY]: { name: 'Energy', shortName: 'Egy', color: 'text-yellow-300', showInPhase: 1, icon: '⚡' },
  [ResourceType.RESEARCH]: { name: 'Research', shortName: 'Rsh', color: 'text-blue-300', showInPhase: 1, icon: '🔬' },
  [ResourceType.REPUTATION]: { name: 'Reputation', shortName: 'Rep', color: 'text-purple-300', showInPhase: 1, icon: '⭐' },
  [ResourceType.HYDROGEN]: { name: 'Hydrogen', shortName: 'H2', color: 'text-green-300', showInPhase: 2, icon: '🧪' },
  [ResourceType.HELIUM]: { name: 'Helium', shortName: 'He', color: 'text-green-400', showInPhase: 2, icon: '💨' },
  [ResourceType.RARE_METALS]: { name: 'Rare Metals', shortName: 'RMtl', color: 'text-orange-300', showInPhase: 2, icon: '🔶' },
  [ResourceType.ANTIMATTER]: { name: 'Antimatter', shortName: 'AM', color: 'text-pink-300', showInPhase: 3, icon: '⚛️' },
  [ResourceType.DARK_MATTER]: { name: 'Dark Matter', shortName: 'DM', color: 'text-indigo-300', showInPhase: 4, icon: '🌑' },
  [ResourceType.COSMIC_INFLUENCE]: { name: 'Cosmic Influence', shortName: 'CI', color: 'text-teal-300', showInPhase: 4, icon: '🌌' }
};

// Map phase string to number for comparison
const getPhaseNumber = (phase: string): number => {
  switch (phase) {
    case 'ASTEROID_MINING': return 1;
    case 'SOLAR_SYSTEM': return 2;
    case 'INTERSTELLAR': return 3;
    case 'GALACTIC_TRANSCENDENCE': return 4;
    default: return 1;
  }
};

// Group resources by phase
const getResourcesByPhase = (visibleResources: string[], phaseNumber: number) => {
  const groups: Record<number, string[]> = {};
  
  visibleResources.forEach(resourceKey => {
    const phase = resourceMeta[resourceKey].showInPhase;
    if (!groups[phase]) {
      groups[phase] = [];
    }
    groups[phase].push(resourceKey);
  });
  
  return groups;
};

const ResourceDisplay: React.FC<ResourceDisplayProps> = ({ gameState }) => {
  // State to track which phases are expanded (for mobile view)
  const [expandedPhases, setExpandedPhases] = useState<Record<number, boolean>>({
    1: true, // Always show Phase 1 resources
    2: true, 
    3: true,
    4: true
  });
  
  // Get production rates
  const production = getResourceProduction(gameState);
  
  // Get current phase number
  const phaseNumber = getPhaseNumber(gameState.phase);
  
  // Filter resources to show based on current phase
  const visibleResources = Object.entries(resourceMeta)
    .filter(([_, meta]) => meta.showInPhase <= phaseNumber)
    .map(([key]) => key);
  
  // Group resources by phase
  const resourcesByPhase = getResourcesByPhase(visibleResources, phaseNumber);
  
  // Toggle expansion of a resource group
  const togglePhaseExpansion = (phase: number) => {
    setExpandedPhases(prev => ({
      ...prev,
      [phase]: !prev[phase]
    }));
  };
  
  // Function to render a resource row - optimized for mobile
  const renderResourceRow = (resourceKey: string) => {
    const meta = resourceMeta[resourceKey];
    const value = gameState.resources[resourceKey as keyof typeof gameState.resources] as number;
    const rate = production[resourceKey];
    
    return (
      <div key={resourceKey} className="flex justify-between items-center py-1 border-b border-gray-700 last:border-0">
        <div className={`flex items-center ${meta.color}`}>
          <span className="mr-1">{meta.icon}</span>
          <span className="hidden sm:inline">{meta.name}</span>
          <span className="sm:hidden">{meta.shortName}</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="font-bold">{formatNumber(value)}</span>
          {rate > 0 && (
            <span className="text-sm text-green-400 whitespace-nowrap">
              (+{formatRate(rate)})
            </span>
          )}
        </div>
      </div>
    );
  };
  
  // Function to get phase name
  const getPhaseName = (phase: number) => {
    switch (phase) {
      case 1: return "Basic";
      case 2: return "Solar System";
      case 3: return "Interstellar";
      case 4: return "Galactic";
      default: return `Phase ${phase}`;
    }
  };
  
  // Main resources should always be visible
  const mainResources = ['minerals', 'energy', 'research'];
  const otherResources = visibleResources.filter(r => !mainResources.includes(r));
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 border-b border-cyan-800 pb-2">Resources</h2>
      
      {/* Always visible main resources */}
      <div className="mb-3">
        {mainResources.map(resourceKey => renderResourceRow(resourceKey))}
      </div>
      
      {/* Collapsible resource groups for other resources */}
      {Object.entries(resourcesByPhase).map(([phaseKey, resources]) => {
        // Skip if it only contains main resources that are already shown
        const phaseResources = resources.filter(r => !mainResources.includes(r));
        if (phaseResources.length === 0) return null;
        
        const phase = parseInt(phaseKey);
        return (
          <div key={phaseKey} className="mb-1">
            {/* Collapsible header for phase group */}
            <div 
              className="flex justify-between items-center py-1 px-2 bg-gray-700 rounded cursor-pointer"
              onClick={() => togglePhaseExpansion(phase)}
            >
              <span className="text-xs font-bold text-cyan-300">{getPhaseName(phase)} Resources</span>
              <span>{expandedPhases[phase] ? '▼' : '►'}</span>
            </div>
            
            {/* Collapsible content */}
            {expandedPhases[phase] && (
              <div className="mt-1 pl-2">
                {phaseResources.map(resourceKey => renderResourceRow(resourceKey))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ResourceDisplay;