// src/game/ExplorationSystem.ts

import { GameState } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { GamePhase } from '../types/GameTypes';

// Define the exploration destination interface
export interface ExplorationDestination {
  id: string;
  name: string;
  description: string;
  requiredPhase: GamePhase;
  expeditionDuration: number; // in seconds
  resourceCosts: Record<string, number>;
  rewards: {
    guaranteed: Record<string, number>;
    chance: {
      resource: string;
      amount: number;
      probability: number;
    }[];
  };
  discoveryChance: number; // Chance to discover new locations
}

// Define exploration destinations
export const explorationDestinations: ExplorationDestination[] = [
  {
    id: 'asteroid-belt',
    name: 'Asteroid Belt Survey',
    description: 'Explore the asteroid belt for valuable minerals and research opportunities.',
    requiredPhase: GamePhase.ASTEROID_MINING,
    expeditionDuration: 60, // 1 minute
    resourceCosts: {
      [ResourceType.ENERGY]: 50,
    },
    rewards: {
      guaranteed: {
        [ResourceType.MINERALS]: 150,
        [ResourceType.RESEARCH]: 20,
      },
      chance: [
        {
          resource: ResourceType.RARE_METALS,
          amount: 5,
          probability: 0.3
        }
      ]
    },
    discoveryChance: 0.1
  },
  {
    id: 'inner-planets',
    name: 'Inner Planets Expedition',
    description: 'Send a scientific expedition to the inner planets of the solar system.',
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    expeditionDuration: 180, // 3 minutes
    resourceCosts: {
      [ResourceType.ENERGY]: 200,
      [ResourceType.MINERALS]: 100,
    },
    rewards: {
      guaranteed: {
        [ResourceType.RESEARCH]: 120,
        [ResourceType.RARE_METALS]: 30,
      },
      chance: [
        {
          resource: ResourceType.REPUTATION,
          amount: 10,
          probability: 0.4
        }
      ]
    },
    discoveryChance: 0.2
  },
  {
    id: 'gas-giants',
    name: 'Gas Giant Survey',
    description: 'Explore the massive gas giants of the outer solar system.',
    requiredPhase: GamePhase.SOLAR_SYSTEM,
    expeditionDuration: 300, // 5 minutes
    resourceCosts: {
      [ResourceType.ENERGY]: 350,
      [ResourceType.MINERALS]: 150,
    },
    rewards: {
      guaranteed: {
        [ResourceType.HYDROGEN]: 200,
        [ResourceType.HELIUM]: 80,
        [ResourceType.RESEARCH]: 150,
      },
      chance: [
        {
          resource: ResourceType.RARE_METALS,
          amount: 50,
          probability: 0.25
        }
      ]
    },
    discoveryChance: 0.3
  },
  {
    id: 'nearby-star',
    name: 'Nearby Star Exploration',
    description: 'Send an expedition to the nearest star system outside our own.',
    requiredPhase: GamePhase.INTERSTELLAR,
    expeditionDuration: 600, // 10 minutes
    resourceCosts: {
      [ResourceType.ENERGY]: 1000,
      [ResourceType.HYDROGEN]: 300,
      [ResourceType.RARE_METALS]: 100,
    },
    rewards: {
      guaranteed: {
        [ResourceType.RESEARCH]: 500,
        [ResourceType.REPUTATION]: 50,
      },
      chance: [
        {
          resource: ResourceType.ANTIMATTER,
          amount: 15,
          probability: 0.5
        }
      ]
    },
    discoveryChance: 0.6
  },
  {
    id: 'cosmic-anomaly',
    name: 'Cosmic Anomaly Investigation',
    description: 'Investigate a strange cosmic anomaly detected at the edge of known space.',
    requiredPhase: GamePhase.GALACTIC_TRANSCENDENCE,
    expeditionDuration: 900, // 15 minutes
    resourceCosts: {
      [ResourceType.ENERGY]: 5000,
      [ResourceType.ANTIMATTER]: 50,
    },
    rewards: {
      guaranteed: {
        [ResourceType.RESEARCH]: 2000,
        [ResourceType.DARK_MATTER]: 30,
      },
      chance: [
        {
          resource: ResourceType.COSMIC_INFLUENCE,
          amount: 5,
          probability: 0.3
        }
      ]
    },
    discoveryChance: 0.8
  }
];

/**
 * Get exploration destination by ID
 * @param destinationId The ID of the destination to retrieve
 * @returns The exploration destination or undefined if not found
 */
export const getExplorationDestination = (destinationId: string): ExplorationDestination | undefined => {
  return explorationDestinations.find(destination => destination.id === destinationId);
};

/**
 * Check if player can afford an expedition
 * @param gameState Current game state
 * @param destination The expedition destination
 * @returns True if the player can afford the expedition, false otherwise
 */
export const canAffordExpedition = (gameState: GameState, destination: ExplorationDestination): boolean => {
  return Object.entries(destination.resourceCosts).every(
    ([resource, amount]) => (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
  );
};