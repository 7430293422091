import React, { useState } from 'react';
import { GameState } from '../types/GameTypes';
import { Producer, ProducerType } from '../types/ProducerTypes';
import { ResourceType } from '../types/ResourceTypes';
import { getVisibleProducers, getNextProducerCost, canAffordProducer } from '../game/ProducerManager';
import { formatNumber } from '../utils/formatters';
import CollapsibleSection from './CollapsibleSection';

interface ProducerPanelProps {
  gameState: GameState;
  onBuyProducer: (producerType: ProducerType) => void;
  isCompact?: boolean;
}

const ProducerPanel: React.FC<ProducerPanelProps> = ({ 
  gameState, 
  onBuyProducer,
  isCompact = false
}) => {
  const [activeTab, setActiveTab] = useState<string>('PHASE_1');
  
  // Get visible producers
  const visibleProducers = getVisibleProducers(gameState.producers);
  
  // Group producers by phase
  const phaseGroups = {
    PHASE_1: visibleProducers.filter(p => p.requiredPhase === 'ASTEROID_MINING'),
    PHASE_2: visibleProducers.filter(p => p.requiredPhase === 'SOLAR_SYSTEM'),
    PHASE_3: visibleProducers.filter(p => p.requiredPhase === 'INTERSTELLAR'),
    PHASE_4: visibleProducers.filter(p => p.requiredPhase === 'GALACTIC_TRANSCENDENCE')
  };
  
  // Get phase number
  const currentPhaseNumber = ['ASTEROID_MINING', 'SOLAR_SYSTEM', 'INTERSTELLAR', 'GALACTIC_TRANSCENDENCE']
    .indexOf(gameState.phase) + 1;
  
  // Format the cost of a producer
  const formatCost = (producer: Producer): React.ReactNode => {
    const nextCost = getNextProducerCost(producer);
    
    return (
      <div className="text-xs">
        {Object.entries(nextCost).map(([resource, amount]) => {
          // @ts-ignore
          const canAfford = gameState.resources[resource] >= amount;
          
          return (
            <div key={resource} className={canAfford ? 'text-green-400' : 'text-red-400'}>
              {resource}: {formatNumber(amount)}
            </div>
          );
        })}
      </div>
    );
  };
  
  // Format the production of a producer - more compact in compact mode
  const formatProduction = (producer: Producer): React.ReactNode => {
    const productionEntries = Object.entries(producer.baseProduction)
      .filter(([_, amount]) => amount !== 0);
    
    if (productionEntries.length === 0) return null;
    
    return (
      <div className="text-xs text-cyan-300">
        {productionEntries.map(([resource, amount]) => (
          <div key={resource} className={`inline-block mr-2 ${amount > 0 ? 'text-green-400' : 'text-red-400'}`}>
            {isCompact ? resource : `${resource}:`} {formatNumber(amount)}/s
          </div>
        ))}
      </div>
    );
  };
  
  // Get tabs that should be visible based on current phase
  const visibleTabs = Object.keys(phaseGroups)
    .filter(tabKey => {
      const tabPhase = parseInt(tabKey.split('_')[1]);
      return tabPhase <= currentPhaseNumber;
    });
  
  // If the active tab is not visible, reset to the first visible tab
  if (!visibleTabs.includes(activeTab)) {
    setActiveTab(visibleTabs[0]);
  }
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 border-b border-cyan-800 pb-1">Producers</h2>
      
      {/* Tabs for different phases - keep visible but more compact */}
      <div className="flex flex-wrap mb-2">
        {visibleTabs.map(tabKey => {
          const phaseNumber = tabKey.split('_')[1];
          return (
            <button
              key={tabKey}
              className={`mr-1 mb-1 px-2 py-1 text-xs rounded ${activeTab === tabKey ? 'bg-cyan-700' : 'bg-gray-700'}`}
              onClick={() => setActiveTab(tabKey)}
            >
              {isCompact ? `P${phaseNumber}` : `Phase ${phaseNumber}`}
            </button>
          );
        })}
      </div>
      
      <div className="space-y-1 max-h-96 overflow-y-auto pr-1">
        {phaseGroups[activeTab as keyof typeof phaseGroups].length === 0 ? (
          <div className="text-gray-400 italic text-sm">No producers available in this phase yet</div>
        ) : (
          phaseGroups[activeTab as keyof typeof phaseGroups].map(producer => (
            <div 
              key={producer.type} 
              className={`p-2 rounded border ${
                !producer.unlocked 
                  ? 'border-gray-700 bg-gray-800 opacity-75' 
                  : canAffordProducer(gameState.resources, producer)
                    ? 'border-cyan-800 bg-gray-800 hover:bg-gray-700 cursor-pointer'
                    : 'border-gray-800 bg-gray-800 opacity-75'
              }`}
              onClick={() => {
                if (producer.unlocked && canAffordProducer(gameState.resources, producer)) {
                  onBuyProducer(producer.type);
                }
              }}
            >
              <div className="sm:flex justify-between items-start">
                <div className="w-full sm:w-3/5">
                  <div className="font-bold text-sm flex items-center">
                    {producer.name} 
                    <span className="text-cyan-400 ml-1">x{producer.count}</span>
                  </div>
                  
                  {/* Hide description in compact mode */}
                  {!isCompact && (
                    <div className="text-xs text-gray-400 h-8 overflow-hidden">{producer.description}</div>
                  )}
                  
                  {formatProduction(producer)}
                </div>
                
                <div className="w-full mt-1 sm:mt-0 sm:w-2/5 sm:text-right">
                  {producer.unlocked ? (
                    formatCost(producer)
                  ) : (
                    <div className="text-xs text-red-400">
                      Locked
                    </div>
                  )}
                </div>
              </div>
              
              {/* Production summary - hide in compact mode unless producer has a count */}
              {producer.unlocked && producer.count > 0 && (
                <div className="mt-1 text-xs">
                  <span className="text-cyan-400">Total:</span>
                  <div className="flex flex-wrap">
                    {Object.entries(producer.baseProduction)
                      .filter(([_, rate]) => rate > 0)
                      .map(([resource, rate]) => (
                        <span key={resource} className="mr-2 text-green-400">
                          {resource}: {formatNumber(rate * producer.count)}/s
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ProducerPanel;