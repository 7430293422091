// src/components/SpacefleetPanel.tsx
import React, { useState } from 'react';
import { GameState } from '../types/GameTypes';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber } from '../utils/formatters';
import CollapsibleSection from './CollapsibleSection';

interface SpacefleetPanelProps {
  gameState: GameState;
  onBuildShip: (shipType: string, count: number) => void;
  onAssignFleet: (fleetId: string, assignment: string) => void;
  isCompact?: boolean;
}

// Interfaces for fleet-related data
interface ShipType {
  id: string;
  name: string;
  description: string;
  cost: Record<string, number>;
  powerRating: number;
  capacity: number;
  maintenance: Record<string, number>;
  requiredResearch: Record<string, number>;
  category: 'mining' | 'military' | 'science' | 'transport';
}

interface Fleet {
  id: string;
  name: string;
  ships: Record<string, number>;
  currentAssignment?: string;
  powerRating: number;
  location: string;
}

const SpacefleetPanel: React.FC<SpacefleetPanelProps> = ({ 
  gameState, 
  onBuildShip,
  onAssignFleet,
  isCompact = false
}) => {
  const [selectedShipType, setSelectedShipType] = useState<string>('mining-frigate');
  const [shipBuildCount, setShipBuildCount] = useState(1);
  const [activeTab, setActiveTab] = useState<'ships' | 'fleets'>('ships');
  const [selectedFleet, setSelectedFleet] = useState<string | null>(null);
  const [selectedAssignment, setSelectedAssignment] = useState<string | null>(null);
  
  // Define ship types
  const shipTypes: ShipType[] = [
    {
      id: 'mining-frigate',
      name: 'Mining Frigate',
      description: 'A small vessel designed for resource extraction operations.',
      cost: {
        [ResourceType.MINERALS]: 1000,
        [ResourceType.ENERGY]: 500,
        [ResourceType.RARE_METALS]: 50
      },
      powerRating: 5,
      capacity: 1000,
      maintenance: {
        [ResourceType.ENERGY]: 2
      },
      requiredResearch: {
        propulsion: 1
      },
      category: 'mining'
    },
    {
      id: 'science-vessel',
      name: 'Science Vessel',
      description: 'Specialized ship equipped with advanced sensors and research equipment.',
      cost: {
        [ResourceType.MINERALS]: 1500,
        [ResourceType.ENERGY]: 800,
        [ResourceType.RARE_METALS]: 100
      },
      powerRating: 3,
      capacity: 500,
      maintenance: {
        [ResourceType.ENERGY]: 3,
        [ResourceType.RESEARCH]: 1
      },
      requiredResearch: {
        propulsion: 2,
        astrophysics: 1
      },
      category: 'science'
    },
    {
      id: 'military-cruiser',
      name: 'Military Cruiser',
      description: 'Combat vessel with significant defensive and offensive capabilities.',
      cost: {
        [ResourceType.MINERALS]: 3000,
        [ResourceType.ENERGY]: 1500,
        [ResourceType.RARE_METALS]: 200
      },
      powerRating: 15,
      capacity: 200,
      maintenance: {
        [ResourceType.ENERGY]: 5,
        [ResourceType.HYDROGEN]: 1
      },
      requiredResearch: {
        propulsion: 3
      },
      category: 'military'
    },
    {
      id: 'cargo-hauler',
      name: 'Cargo Hauler',
      description: 'Massive ship designed for transporting large quantities of resources.',
      cost: {
        [ResourceType.MINERALS]: 2000,
        [ResourceType.ENERGY]: 1000,
        [ResourceType.RARE_METALS]: 80
      },
      powerRating: 2,
      capacity: 5000,
      maintenance: {
        [ResourceType.ENERGY]: 3
      },
      requiredResearch: {
        propulsion: 2
      },
      category: 'transport'
    },
    {
      id: 'exploration-corvette',
      name: 'Exploration Corvette',
      description: 'Fast, maneuverable ship designed for scouting new systems and anomalies.',
      cost: {
        [ResourceType.MINERALS]: 1200,
        [ResourceType.ENERGY]: 900,
        [ResourceType.RARE_METALS]: 120
      },
      powerRating: 7,
      capacity: 300,
      maintenance: {
        [ResourceType.ENERGY]: 4,
        [ResourceType.HYDROGEN]: 0.5
      },
      requiredResearch: {
        propulsion: 3,
        astrophysics: 2
      },
      category: 'science'
    },
    {
      id: 'antimatter-harvester',
      name: 'Antimatter Harvester',
      description: 'Specialized vessel for collecting antimatter from cosmic phenomena.',
      cost: {
        [ResourceType.MINERALS]: 5000,
        [ResourceType.ENERGY]: 3000,
        [ResourceType.RARE_METALS]: 300,
        [ResourceType.ANTIMATTER]: 5
      },
      powerRating: 10,
      capacity: 100,
      maintenance: {
        [ResourceType.ENERGY]: 8,
        [ResourceType.HYDROGEN]: 2
      },
      requiredResearch: {
        propulsion: 4,
        quantumMechanics: 2
      },
      category: 'mining'
    }
  ];
  
  // Mock fleets data (would normally come from game state)
  const fleets: Fleet[] = [
    {
      id: 'alpha-fleet',
      name: 'Alpha Fleet',
      ships: {
        'mining-frigate': 3,
        'cargo-hauler': 1
      },
      currentAssignment: 'asteroid-belt-mining',
      powerRating: 17,
      location: 'Asteroid Belt'
    },
    {
      id: 'beta-fleet',
      name: 'Beta Fleet',
      ships: {
        'science-vessel': 2,
        'exploration-corvette': 1
      },
      currentAssignment: 'deep-space-research',
      powerRating: 13,
      location: 'Proxima Centauri'
    }
  ];
  
  // Find the selected ship type
  const selectedShipTypeData = shipTypes.find(ship => ship.id === selectedShipType);
  
  // Calculate the total cost for the current build order
  const calculateTotalCost = () => {
    if (!selectedShipTypeData) return {};
    
    const totalCost: Record<string, number> = {};
    Object.entries(selectedShipTypeData.cost).forEach(([resource, amount]) => {
      totalCost[resource] = amount * shipBuildCount;
    });
    
    return totalCost;
  };
  
  // Check if player can afford to build the selected ships
  const canAffordShips = (): boolean => {
    const totalCost = calculateTotalCost();
    return Object.entries(totalCost).every(
      ([resource, amount]) => (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount
    );
  };
  
  // Find the selected fleet
  const selectedFleetData = fleets.find(fleet => fleet.id === selectedFleet);
  
  // Available assignments for fleets
  const assignments = [
    { id: 'asteroid-belt-mining', name: 'Asteroid Belt Mining', description: 'Mine resources from the asteroid belt.', requiredPower: 10 },
    { id: 'deep-space-research', name: 'Deep Space Research', description: 'Conduct scientific research in deep space.', requiredPower: 10 },
    { id: 'system-patrol', name: 'System Patrol', description: 'Patrol the solar system for security.', requiredPower: 15 },
    { id: 'interstellar-expedition', name: 'Interstellar Expedition', description: 'Explore distant star systems.', requiredPower: 20 },
    { id: 'antimatter-collection', name: 'Antimatter Collection', description: 'Harvest antimatter from cosmic phenomena.', requiredPower: 25 }
  ];
  
  // Find the selected assignment
  const selectedAssignmentData = assignments.find(a => a.id === selectedAssignment);
  
  // Handle building ships
  const handleBuildShips = () => {
    if (selectedShipTypeData && canAffordShips()) {
      onBuildShip(selectedShipType, shipBuildCount);
    }
  };
  
  // Handle assigning a fleet
  const handleAssignFleet = () => {
    if (selectedFleet && selectedAssignment) {
      onAssignFleet(selectedFleet, selectedAssignment);
    }
  };
  
  return (
    <div className="bg-gray-800 p-3 rounded border border-cyan-900">
      <h2 className="text-lg font-bold mb-2 border-b border-cyan-800 pb-1">Spacefleet Command</h2>
      
      {/* Tabs - Ships and Fleets */}
      <div className="flex mb-3">
        <button
          className={`px-4 py-2 ${activeTab === 'ships' ? 'bg-cyan-800 text-white' : 'bg-gray-700 text-gray-300'}`}
          onClick={() => setActiveTab('ships')}
        >
          Ships
        </button>
        <button
          className={`px-4 py-2 ${activeTab === 'fleets' ? 'bg-cyan-800 text-white' : 'bg-gray-700 text-gray-300'}`}
          onClick={() => setActiveTab('fleets')}
        >
          Fleets
        </button>
      </div>
      
      {/* Ships Tab */}
      {activeTab === 'ships' && (
        <div>
          {/* Ship types - grid layout */}
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mb-3">
            {shipTypes.map(ship => (
              <button
                key={ship.id}
                className={`p-2 rounded text-sm text-left ${
                  selectedShipType === ship.id 
                    ? 'bg-blue-800 border border-blue-600' 
                    : 'bg-gray-700 hover:bg-gray-600'
                }`}
                onClick={() => setSelectedShipType(ship.id)}
              >
                <div className="font-bold truncate">{ship.name}</div>
                <div className="text-xs text-gray-400">Power: {ship.powerRating}</div>
              </button>
            ))}
          </div>
          
          {/* Selected ship details */}
          {selectedShipTypeData && (
            <div className="p-3 bg-gray-700 rounded mb-3">
              <h3 className="font-bold">{selectedShipTypeData.name}</h3>
              
              {/* Only show description in non-compact mode */}
              {!isCompact && (
                <p className="text-sm text-gray-300 mb-2">{selectedShipTypeData.description}</p>
              )}
              
              <div className="grid grid-cols-3 gap-2 mb-2">
                <div>
                  <div className="text-xs font-bold">Power:</div>
                  <div className="text-blue-300">{selectedShipTypeData.powerRating}</div>
                </div>
                <div>
                  <div className="text-xs font-bold">Capacity:</div>
                  <div className="text-blue-300">{formatNumber(selectedShipTypeData.capacity)}</div>
                </div>
                <div>
                  <div className="text-xs font-bold">Type:</div>
                  <div className="text-blue-300">{selectedShipTypeData.category}</div>
                </div>
              </div>
              
              <div className="mb-2">
                <div className="text-xs font-bold">Cost per ship:</div>
                <div className="flex flex-wrap gap-2">
                  {Object.entries(selectedShipTypeData.cost).map(([resource, amount]) => (
                    <div 
                      key={resource} 
                      className={`text-xs ${
                        (gameState.resources[resource as keyof typeof gameState.resources] || 0) >= amount * shipBuildCount
                          ? 'text-green-400'
                          : 'text-red-400'
                      }`}>
                      {resource}: {formatNumber(amount)}
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="mb-2">
                <div className="text-xs font-bold">Maintenance:</div>
                <div className="flex flex-wrap gap-2">
                  {Object.entries(selectedShipTypeData.maintenance).map(([resource, amount]) => (
                    <div key={resource} className="text-xs text-yellow-400">
                      {resource}: {formatNumber(amount)}/s
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="flex mt-2">
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={shipBuildCount}
                  onChange={(e) => setShipBuildCount(Math.max(1, parseInt(e.target.value) || 1))}
                  className="w-20 px-2 py-1 bg-gray-800 border border-gray-600 rounded mr-2 text-white"
                />
                
                <button
                  className="flex-1 bg-blue-700 hover:bg-blue-600 py-1 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={handleBuildShips}
                  disabled={!canAffordShips()}
                >
                  Build Ships ({formatNumber(shipBuildCount)})
                </button>
              </div>
            </div>
          )}
          
          {/* Ship stats section - collapsible in compact mode */}
          {isCompact ? (
            <CollapsibleSection title="Ship Statistics" isOpen={false}>
              <div className="text-xs text-gray-300">
                <div className="grid grid-cols-2 gap-1">
                  <div>Mining Frigate: <span className="text-blue-300">5</span></div>
                  <div>Science Vessel: <span className="text-blue-300">2</span></div>
                  <div>Military Cruiser: <span className="text-blue-300">1</span></div>
                  <div>Cargo Hauler: <span className="text-blue-300">3</span></div>
                </div>
              </div>
            </CollapsibleSection>
          ) : (
            <div className="bg-gray-700 p-3 rounded">
              <h3 className="font-bold mb-1">Ship Statistics</h3>
              <div className="grid grid-cols-2 gap-2 text-sm">
                <div>Mining Frigate: <span className="text-blue-300">5</span></div>
                <div>Science Vessel: <span className="text-blue-300">2</span></div>
                <div>Military Cruiser: <span className="text-blue-300">1</span></div>
                <div>Cargo Hauler: <span className="text-blue-300">3</span></div>
                <div>Exploration Corvette: <span className="text-blue-300">1</span></div>
                <div>Antimatter Harvester: <span className="text-blue-300">0</span></div>
              </div>
              <div className="mt-2 text-sm">
                <div>Total Ships: <span className="text-blue-300">12</span></div>
                <div>Total Power Rating: <span className="text-blue-300">59</span></div>
                <div>Maintenance: <span className="text-yellow-400">Energy: 37/s, Hydrogen: 1.5/s</span></div>
              </div>
            </div>
          )}
        </div>
      )}
      
      {/* Fleets Tab */}
      {activeTab === 'fleets' && (
        <div>
          {/* Fleet selection */}
          <div className="mb-3">
            <h3 className="font-bold mb-2">Your Fleets</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {fleets.map(fleet => (
                <button
                  key={fleet.id}
                  className={`p-2 rounded text-sm text-left ${
                    selectedFleet === fleet.id 
                      ? 'bg-blue-800 border border-blue-600' 
                      : 'bg-gray-700 hover:bg-gray-600'
                  }`}
                  onClick={() => setSelectedFleet(fleet.id)}
                >
                  <div className="font-bold">{fleet.name}</div>
                  <div className="text-xs text-gray-400">
                    Location: {fleet.location} | Power: {fleet.powerRating}
                  </div>
                  {fleet.currentAssignment && (
                    <div className="text-xs text-blue-300">
                      Assignment: {assignments.find(a => a.id === fleet.currentAssignment)?.name || fleet.currentAssignment}
                    </div>
                  )}
                </button>
              ))}
            </div>
          </div>
          
          {/* Selected fleet details */}
          {selectedFleetData && (
            <div className="p-3 bg-gray-700 rounded mb-3">
              <h3 className="font-bold">{selectedFleetData.name}</h3>
              
              <div className="grid grid-cols-2 gap-2 mb-2">
                <div>
                  <div className="text-xs font-bold">Power Rating:</div>
                  <div className="text-blue-300">{selectedFleetData.powerRating}</div>
                </div>
                <div>
                  <div className="text-xs font-bold">Location:</div>
                  <div className="text-blue-300">{selectedFleetData.location}</div>
                </div>
              </div>
              
              <div className="mb-2">
                <div className="text-xs font-bold">Ships:</div>
                <div className="grid grid-cols-2 gap-1">
                  {Object.entries(selectedFleetData.ships).map(([shipId, count]) => {
                    const shipData = shipTypes.find(s => s.id === shipId);
                    return (
                      <div key={shipId} className="text-xs text-gray-300">
                        {shipData?.name}: <span className="text-blue-300">{count}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              
              <div className="mb-2">
                <div className="text-xs font-bold">Current Assignment:</div>
                {selectedFleetData.currentAssignment ? (
                  <div className="text-blue-300">
                    {assignments.find(a => a.id === selectedFleetData.currentAssignment)?.name || selectedFleetData.currentAssignment}
                  </div>
                ) : (
                  <div className="text-yellow-400">None (Idle)</div>
                )}
              </div>
              
              {/* Assignment selection */}
              <div className="mb-2">
                <div className="text-xs font-bold">Assign to mission:</div>
                <select
                  className="w-full px-2 py-1 bg-gray-800 border border-gray-600 rounded mt-1 text-white text-sm"
                  value={selectedAssignment || ''}
                  onChange={(e) => setSelectedAssignment(e.target.value)}
                >
                  <option value="">Select assignment...</option>
                  {assignments.map(assignment => (
                    <option 
                      key={assignment.id} 
                      value={assignment.id}
                      disabled={selectedFleetData.powerRating < assignment.requiredPower}
                    >
                      {assignment.name} (Power: {assignment.requiredPower})
                    </option>
                  ))}
                </select>
              </div>
              
              {selectedAssignmentData && (
                <div className="mb-2">
                  <div className="text-xs text-gray-300">{selectedAssignmentData.description}</div>
                  {selectedFleetData.powerRating < selectedAssignmentData.requiredPower && (
                    <div className="text-xs text-red-400 mt-1">
                      Insufficient power! Required: {selectedAssignmentData.requiredPower}
                    </div>
                  )}
                </div>
              )}
              
              <button
                className="w-full mt-2 px-3 py-2 bg-blue-700 hover:bg-blue-600 disabled:bg-gray-600 disabled:opacity-50 rounded"
                onClick={handleAssignFleet}
                disabled={!selectedAssignment || (selectedAssignmentData && selectedFleetData.powerRating < selectedAssignmentData.requiredPower)}
              >
                Assign Fleet
              </button>
            </div>
          )}
          
          {/* Fleet management tips */}
          {!isCompact && (
            <div className="bg-gray-700 p-3 rounded">
              <h3 className="font-bold mb-1">Fleet Management</h3>
              <div className="text-sm text-gray-300">
                <p>Effectively managing your fleets is crucial for your space empire:</p>
                <ul className="list-disc list-inside mt-1 text-xs">
                  <li>Assign mining fleets to resource-rich areas</li>
                  <li>Science fleets can discover new technologies</li>
                  <li>Military fleets provide security and can defend against threats</li>
                  <li>Transport fleets move resources between systems efficiently</li>
                  <li>Balance your fleet composition for optimal performance</li>
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SpacefleetPanel;