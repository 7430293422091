export enum ResourceType {
    MINERALS = 'minerals',
    ENERGY = 'energy',
    RESEARCH = 'research',
    REPUTATION = 'reputation',
    HYDROGEN = 'hydrogen',
    HELIUM = 'helium',
    RARE_METALS = 'rareMetals',
    ANTIMATTER = 'antimatter',
    DARK_MATTER = 'darkMatter',
    COSMIC_INFLUENCE = 'cosmicInfluence'
  }
  
  export interface ResourceData {
    name: string;
    description: string;
    baseProduction: number;
    productionMultiplier: number;
    visible: boolean;
    unlocked: boolean;
    displayColor: string;
    formatPrecision: number;
    displayOrder: number;
    phase: number;
    icon: string;
  }
  
  export interface ResourceProduction {
    base: number;
    multiplier: number;
    perSecond: number;
    total: number;
  }
  
  export interface ResourceProductionState {
    [ResourceType.MINERALS]: ResourceProduction;
    [ResourceType.ENERGY]: ResourceProduction;
    [ResourceType.RESEARCH]: ResourceProduction;
    [ResourceType.REPUTATION]: ResourceProduction;
    [ResourceType.HYDROGEN]: ResourceProduction;
    [ResourceType.HELIUM]: ResourceProduction;
    [ResourceType.RARE_METALS]: ResourceProduction;
    [ResourceType.ANTIMATTER]: ResourceProduction;
    [ResourceType.DARK_MATTER]: ResourceProduction;
    [ResourceType.COSMIC_INFLUENCE]: ResourceProduction;
  }