// src/components/CollectButton.tsx - Complete rewrite with single event handler approach
import React, { useState, useRef } from 'react';
import { ResourceType } from '../types/ResourceTypes';
import { formatNumber } from '../utils/formatters';

interface CollectButtonProps {
  resource: ResourceType | string;
  amount: number;
  onClick: (resource: string, amount: number, event?: React.MouseEvent | React.TouchEvent) => void;
  className?: string;
  children?: React.ReactNode;
}

const CollectButton: React.FC<CollectButtonProps> = ({ 
  resource, 
  amount, 
  onClick, 
  className = '',
  children
}) => {
  const [popups, setPopups] = useState<{ id: number; amount: number; x: number; y: number }[]>([]);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popupIdRef = useRef(0);
  
  // Global click timestamp to prevent double-clicks - has to be static across all buttons
  // Use a static variable outside the component to ensure it's truly global
  if (typeof window !== 'undefined' && !window.lastButtonClickTime) {
    window.lastButtonClickTime = {};
  }

  // Create a popup animation
  const createPopup = (x: number, y: number) => {
    const newId = popupIdRef.current++;
    setPopups(prev => [...prev, { id: newId, amount, x, y }]);
    
    // Clean up popups after animation completes
    setTimeout(() => {
      setPopups(prev => prev.filter(popup => popup.id !== newId));
    }, 800);
  };
  
  // Single unified handler for all interaction types
  const handleInteraction = (e: React.SyntheticEvent) => {
    // Prevent default behavior and stop propagation
    e.preventDefault();
    e.stopPropagation();
    
    // Very aggressive debouncing (300ms)
    const now = Date.now();
    const lastTime = window.lastButtonClickTime[resource] || 0;
    
    if (now - lastTime < 300) {
      return;
    }
    
    // Update the last click time
    window.lastButtonClickTime[resource] = now;
    
    // Get coordinates for animations
    let clientX = 0;
    let clientY = 0;
    
    if (e.nativeEvent instanceof MouseEvent) {
      // It's a MouseEvent
      clientX = e.nativeEvent.clientX;
      clientY = e.nativeEvent.clientY;
    } else if (e.nativeEvent instanceof TouchEvent && e.nativeEvent.touches.length > 0) {
      // It's a TouchEvent
      clientX = e.nativeEvent.touches[0].clientX;
      clientY = e.nativeEvent.touches[0].clientY;
    } else if (e.nativeEvent instanceof TouchEvent && e.nativeEvent.changedTouches.length > 0) {
      // It's the end of a TouchEvent
      clientX = e.nativeEvent.changedTouches[0].clientX;
      clientY = e.nativeEvent.changedTouches[0].clientY;
    }
    
    // Create visual feedback if we have valid coordinates
    if (clientX && clientY) {
      // Create ripple effect
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        const diameter = Math.max(rect.width, rect.height);
        const radius = diameter / 2;
        const rippleX = clientX - rect.left - radius;
        const rippleY = clientY - rect.top - radius;
        
        const ripple = document.createElement('span');
        ripple.style.width = ripple.style.height = `${diameter}px`;
        ripple.style.left = `${rippleX}px`;
        ripple.style.top = `${rippleY}px`;
        ripple.classList.add('ripple');
        
        buttonRef.current.appendChild(ripple);
        
        setTimeout(() => {
          if (buttonRef.current && ripple.parentElement === buttonRef.current) {
            buttonRef.current.removeChild(ripple);
          }
        }, 600);
      }
      
      // Create floating text popup
      createPopup(clientX, clientY);
    }
    
    // Call the onClick handler with resource and amount
    onClick(resource, amount, e as any);
  };

  return (
    <button
      ref={buttonRef}
      className={`relative click-button ${className}`}
      onClick={handleInteraction}
      onTouchStart={(e) => {
        // We only use this to capture the event, but handle it in handleInteraction
        handleInteraction(e);
        // Then prevent any other handlers from firing
        e.stopPropagation();
      }}
      style={{ 
        touchAction: 'manipulation', 
        WebkitTapHighlightColor: 'transparent',
        userSelect: 'none'
      }}
    >
      {children || `Collect ${resource} (+${formatNumber(amount)})`}
      
      {/* Rendered outside the button for floating animations */}
      {popups.map(popup => (
        <div 
          key={popup.id} 
          className="fixed pointer-events-none z-50"
          style={{
            left: popup.x,
            top: popup.y,
            transform: 'translate(-50%, -100%)',
            animation: 'float-up 0.8s forwards'
          }}
        >
          <div className={`text-${
            resource === 'minerals' ? 'gray-300' :
            resource === 'energy' ? 'yellow-300' :
            resource === 'research' ? 'blue-300' :
            resource === 'reputation' ? 'purple-300' :
            resource === 'hydrogen' ? 'green-300' :
            resource === 'helium' ? 'green-400' :
            resource === 'rareMetals' ? 'orange-300' :
            resource === 'antimatter' ? 'pink-300' :
            resource === 'darkMatter' ? 'indigo-300' :
            'teal-300' // cosmic influence and default
          } font-bold text-shadow`}>
            +{formatNumber(popup.amount)}
          </div>
        </div>
      ))}
    </button>
  );
};

// Add the global type for the window object
declare global {
  interface Window {
    lastButtonClickTime: {[key: string]: number};
  }
}

export default CollectButton;